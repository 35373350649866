<template>
  <a-modal
    :bodyStyle="{ maxHeight: '80vh', overflow: 'auto' }"
    :centered="state.centered"
    :title="state.modalTitle"
    :width="1200"
    :open="open"
    :confirmLoading="state.confirmLoading"
    :keyboard="state.keyboard"
    :maskClosable="state.maskClosable"
    @ok="onFormSubmit"
    @cancel="cancelClick"
  >
    <a-spin :spinning="state.loading">
      <div style="padding: 20px 50px; font-size: 20px" v-if="state.isFirst">
        <div>请选择公司主体类型</div>
        <div class="category">
          <div @click="initialTypeChange(1)" :class="formState.main_type == 1 ? 'check' : ''" class="category-item company">公司</div>
          <div @click="initialTypeChange(2)" :class="formState.main_type == 2 ? 'check' : ''" class="category-item individual">个体户</div>
          <div @click="initialTypeChange(3)" :class="formState.main_type == 3 ? 'check' : ''" class="category-item proprietorship">独资企业</div>
          <div @click="initialTypeChange(4)" :class="formState.main_type == 4 ? 'check' : ''" class="category-item company">合伙企业</div>
        </div>
        <div class="d-flex">
          <div class="flex-1 mr-2x">
            <div>请选择平台简称</div>
            <div class="category">
              <a-select
                @change="selectPlatformChange"
                style="width: 100%"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                v-model:value="formState.platform_id"
                placeholder="请选择平台简称"
              >
                <a-select-option class="relative" v-for="(item, index) in state.platformServicesList" :key="index" :value="item.id" :title="item">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="flex-1">
            <div>代理商姓名</div>
            <div class="category">
              <a-input v-model:value="formState.seller" placeholder="请输入代理商姓名"></a-input>
            </div>
          </div>
        </div>
      </div>
      <a-form ref="formRef" :model="formState" :rules="rules" v-bind="formLayout" v-show="!state.isFirst">
        <!-- 基础信息 -->
        <div v-show="!state.isEdit">
          <a-row>
            <a-col :md="8" :sm="24">
              <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="业务类型">
                <a-radio-group v-model:value="formState.business_type" button-style="solid">
                  <a-radio-button :value="1">设立</a-radio-button>
                  <a-radio-button :value="2">同区迁入</a-radio-button>
                  <a-radio-button :value="3">跨区迁入</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="主体类型">
                <a-radio-group v-model:value="formState.main_type" button-style="solid" @change="principalTypeChange">
                  <a-radio-button :value="1">公司</a-radio-button>
                  <a-radio-button :value="2">个体户</a-radio-button>
                  <a-radio-button :value="3">个独</a-radio-button>
                  <a-radio-button :value="4">合伙</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="收费标准">
                <a-radio-group v-model:value="formState.fee_standard" button-style="solid">
                  <a-radio-button :value="1">按年收费</a-radio-button>
                  <a-radio-button :value="2">一次性收费</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <!-- <a-col :md="8" :sm="24">
              <a-form-item label="平台归属">
                <a-radio-group v-model:value="formState.platform_type" button-style="solid">
                  <a-radio-button :value="1">自营</a-radio-button>
                  <a-radio-button :value="2">合作</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col> -->
            <a-col :md="8" :sm="24">
              <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="注册类型">
                <a-radio-group v-model:value="formState.registration_type" button-style="solid" v-if="formState.platform_id == 10">
                  <a-radio-button :value="1">自己注册</a-radio-button>
                  <a-radio-button :value="2" :disabled="state.is_provide_adds == 2">他人注册</a-radio-button>
                </a-radio-group>
                <a-radio-group v-model:value="formState.registration_type" button-style="solid" v-else>
                  <a-radio-button :value="1">包注册</a-radio-button>
                  <a-radio-button :value="2" :disabled="state.is_provide_adds == 2">出裸地址</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :labelCol="{ span: 7 }" name="isneed_tax_authority" :wrapperCol="{ span: 17 }" label="需要税务登记">
                <a-radio-group v-model:value="formState.isneed_tax_authority" button-style="solid">
                  <a-radio-button :value="1">需要</a-radio-button>
                  <a-radio-button :value="2">不需要</a-radio-button>
                  <a-radio-button :value="3">不知道</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item
                name="is_heming"
                label="是否核名"
                :wrapperCol="{
                  xs: { span: 17 },
                  sm: { span: 17 }
                }"
                v-if="(formState.main_type == 1 || formState.main_type == 3) && formState.business_type == 1"
              >
                <a-switch :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.is_heming" checked-children="已核名" un-checked-children="未核名"></a-switch>
              </a-form-item>
              <a-form-item
                name="is_auto"
                label="是否可录单"
                :wrapperCol="{
                  xs: { span: 17 },
                  sm: { span: 17 }
                }"
                v-if="!((formState.main_type == 1 || formState.main_type == 3) && formState.business_type == 1)"
              >
                <a-switch
                  :checkedValue="1"
                  :unCheckedValue="2"
                  v-model:checked="formState.is_auto"
                  checked-children="资料已确定，资料员可以录单了"
                  un-checked-children="还有资料未确定，资料员不要录单"
                ></a-switch>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <a-form-item label="名称核准文号" name="is_heming_num" v-if="formState.is_heming == 1">
          <a-input v-model:value="formState.is_heming_num" placeholder="请输入名称核准文号或申请号"></a-input>名称核准文号填写样例：(国）登记内名预核字[2010]第<span style="color: red">XXXX</span
          >号，请在输入框填写<span style="color: red">XXXX</span>
        </a-form-item>
        <div v-if="!state.isEdit">
          <a-form-item label="平台简称" name="platform_id">
            <a-select @change="selectPlatformChange" :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model:value="formState.platform_id" placeholder="平台简称">
              <a-select-option :title="item" class="relative" v-for="(item, index) in state.platformServicesList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="prefix" :colon="false" label="地址编号前缀" v-if="state.prefixList && state.prefixList.length > 0" v-show="state.prefixList.length != 1">
            <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model:value="formState.prefix" placeholder="地址编号前缀">
              <a-select-option class="relative" v-for="(item, index) in state.prefixList" :key="index" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="postfix" :colon="false" label="地址编号后缀" v-if="state.postfixList && state.postfixList.length > 0" v-show="state.postfixList.length != 1">
            <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model:value="formState.postfix" placeholder="地址编号后缀">
              <a-select-option class="relative" v-for="(item, index) in state.postfixList" :key="index" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <!-- 合作 平台信息 -->
        <div v-if="formState.platform_id == 10">
          <a-form-item label="代办人身份证姓名" name="agent_idcard_name" v-if="formState.registration_type == 1">
            <a-input v-model:value="formState.agent_idcard_name" placeholder="请输入代办人身份证姓名" @blur="handleSearch"></a-input>
          </a-form-item>

          <a-form-item label="平台账号" name="platform_account" v-if="formState.registration_type == 1">
            <a-input v-model:value="formState.platform_account" placeholder="请输入平台账号"></a-input>
          </a-form-item>
          <a-form-item label="平台密码" name="platform_password" v-if="formState.registration_type == 1">
            <a-input v-model:value="formState.platform_password" placeholder="请输入平台密码"></a-input>
          </a-form-item>

          <a-form-item label="平台对接人微信号" name="platform_wechat_account">
            <a-input v-model:value="formState.platform_wechat_account" placeholder="请输入平台对接人微信号"></a-input>
          </a-form-item>
          <a-form-item label="平台对接人手机号" name="platform_person_mobile">
            <a-input v-model:value="formState.platform_person_mobile" placeholder="请输入平台对接人手机号"></a-input>
          </a-form-item>
        </div>
        <div v-if="state.isEdit">
          <a-form-item name="is_auto" label="资料员是否可以录单了：">
            <a-switch
              :checkedValue="1"
              :unCheckedValue="2"
              v-model:checked="formState.is_auto"
              checked-children="资料已确定，资料员可以录单了"
              un-checked-children="还有资料未确定，资料员不要录单"
            ></a-switch>
            <span class="ml-2x"> 如不做单，请关闭此开关！ </span>
          </a-form-item>
          <a-form-item label="主体类型" name="main_type">
            <a-radio-group v-model:value="formState.main_type" button-style="solid" disabled>
              <a-radio-button :value="1">公司</a-radio-button>
              <a-radio-button :value="2">个体户</a-radio-button>
              <a-radio-button :value="3">独资企业</a-radio-button>
              <a-radio-button :value="4">合伙企业</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item name="fee_standard" label="收费标准">
            <a-radio-group v-model:value="formState.fee_standard" button-style="solid" :disabled="formState.first_pay_time">
              <a-radio-button :value="1">按年收费</a-radio-button>
              <a-radio-button :value="2">一次性收费</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="业务类型">
            <a-radio-group v-model:value="formState.business_type" button-style="solid">
              <a-radio-button :value="1">设立</a-radio-button>
              <a-radio-button :value="2">同区迁入</a-radio-button>
              <a-radio-button :value="3">跨区迁入</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item name="registration_type" label="注册类型">
            <a-radio-group v-model:value="formState.registration_type" button-style="solid">
              <a-radio-button :value="1">包注册</a-radio-button>
              <a-radio-button :value="2">出裸地址</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item name="isneed_tax_authority" label="需要税务登记">
            <a-radio-group v-model:value="formState.isneed_tax_authority" button-style="solid">
              <a-radio-button :value="1">需要</a-radio-button>
              <a-radio-button :value="2">不需要</a-radio-button>
              <a-radio-button :value="3">不知道</a-radio-button>
            </a-radio-group>
          </a-form-item>
        </div>
        <a-form-item label="是否办了税务登记" v-if="state.isEdit" name="is_tax_authority">
          <a-radio-group v-model:value="formState.is_tax_authority" button-style="solid">
            <a-radio-button :value="1">是</a-radio-button>
            <a-radio-button :value="2">否</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item name="entityNameToRegister" required label="待注册主体名称" :labelCol="{ span: 7 }" :wrapperCol="{ span: 14 }">
          <principal-name ref="principalName" :principalType="formState.main_type" :isFiliale="formState.is_filiale" v-model:value="formState.entityNameToRegister"> </principal-name>
          <a-button v-if="formState.business_type != 1" @click="onEnteringClick" type="primary">导入</a-button>
          <a-button v-if="formState.business_type == 1" @click="getrandcompanyname" type="primary">获取字号</a-button>
          <div v-if="state.companyName.length > 0" class="mt-1x">
            <a-tag class="mr-1x mb-1x pointer" color="success" @click="selectCompanyNameChange(item)" v-for="(item, index) in state.companyName" :key="index">{{ item }}</a-tag>
          </div>
        </a-form-item>
        <a-form-item label="主营行业" v-if="formState.entityNameToRegister.ondustry_representation_type">
          {{ formState.entityNameToRegister.ondustry_representation_type }}
        </a-form-item>
        <div v-if="formState.business_type != 1">
          <a-form-item name="reason_relocatio" label="迁入理由" required>
            <a-input v-model:value="formState.reason_relocatio" placeholder="请输入迁入理由"> </a-input>
          </a-form-item>
          <a-form-item name="social_credit_code" label="社会信用代码">
            <a-input v-model:value="formState.social_credit_code" placeholder="请输入社会信用代码"> </a-input>
          </a-form-item>
          <a-form-item name="raw_address" label="公司原地址">
            <a-textarea v-model:value="formState.raw_address" placeholder="请输入公司原地址"> </a-textarea>
          </a-form-item>
        </div>
        <a-form-item v-if="formState.platform_type == 2" label="受理机关" name="company_authority">
          <Authority v-model:value="formState.company_authority"> </Authority>
        </a-form-item>
        <a-form-item name="registered_address_list" v-show="formState.platform_type == 2" :required="formState.platform_type == 2" label="注册地址(省市区)">
          <AddressCascader :type="3" v-model:value="formState.registered_address_list" @change="companyPauaChange"> </AddressCascader>
        </a-form-item>
        <a-form-item name="registration_address" v-if="formState.platform_type == 2" label="注册详细地址">
          <a-input placeholder="请输入注册详细地址" v-model:value="formState.registration_address"></a-input>
        </a-form-item>
        <a-form-item
          name="business_scope"
          :label="
            h(
              'span',
              { onClick: selectBusinessScope },
              {
                default: () => [
                  h('span', '经营范围'),
                  h(
                    Tooltip,
                    { title: '点击选取经营范围' },
                    {
                      default: () => [h(InfoCircleOutlined, { onClick: selectBusinessScope, class: ['ml-x'] })]
                    }
                  )
                ]
              }
            )
          "
        >
          <a-textarea :auto-size="{ minRows: 3 }" :value="formState.business_scope" placeholder="请输入经营范围核验码" @change="businessChange" @blur="verificationClick"> </a-textarea>
          <a-button :disabled="state.verificationLoading" :loading="state.verificationLoading" class="mt-x" type="primary" @click="verificationClick('click')"> 核验 </a-button>
        </a-form-item>
        <!-- 核验结果 -->
        <div style="margin-left: 29%" v-if="state.verificationErrorList.length > 0">
          <p>以下经营范围核验失败,请修改之后重试</p>
          <div>
            <span v-for="(item, index) in state.verificationErrorList" :key="index" class="range no-pass">{{ item }}</span>
          </div>
        </div>
        <a-form-item label="工商预留手机号码" name="registration_mobile">
          <a-input
            @change="
              (v) => {
                phoneChange(v, 'registration_mobile')
              }
            "
            :value="formState.registration_mobile"
            placeholder="请输入工商预留手机号码"
          >
            <template #suffix>
              <a-tooltip title="此手机号码将提交给工商局,会有很多广告电话打进来,请谨慎留取">
                <InfoCircleOutlined style="color: rgba(0, 0, 0, 0.45)" />
              </a-tooltip>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item label="关联授权查看者手机号码">
          <AssociateAuthorization
            type="number"
            v-model:value="formState.authorized_viewer_mobile"
            placeholder="请输入关联授权查看者手机号码"
            suffixTitle="回车或失去焦点后可以添加多个关联授权查看手机号码"
            inputType="number"
          >
          </AssociateAuthorization>
        </a-form-item>
        <a-form-item label="续费联系人手机号码" name="renew_first_contact_mobile">
          <a-input
            @change="
              (v) => {
                phoneChange(v, 'renew_first_contact_mobile')
              }
            "
            @blur="contactMobileBlur"
            :value="formState.renew_first_contact_mobile"
            placeholder="请输入续费联系人手机号码"
          >
          </a-input>
          <a-button :disabled="state.mobileBlurLoading" :loading="state.mobileBlurLoading" @click="contactMobileBlur" class="mt-1x" type="primary"
            >查询财务公司({{ state.filingsList.length }})</a-button
          >
          <div class="mt-1x">
            <a-tag v-for="(item, index) in state.filingsList" :key="index" class="mr-1x mb-1x pointer" :color="item.status === 1 ? 'success' : 'error'" @click="selectFilingsListChange(item)">{{
              item.company_name
            }}</a-tag>
          </div>
        </a-form-item>
        <a-form-item label="财务公司名称" name="finance_company_name" required>
          <a-input v-model:value="formState.finance_company_name" placeholder="请输入财务公司名称"></a-input>
        </a-form-item>
        <a-form-item label="财务公司统一社会信用代码" name="finance_social_credit_code" required>
          <a-input v-model:value="formState.finance_social_credit_code" placeholder="请输入财务公司统一社会信用代码"></a-input>
        </a-form-item>
        <a-form-item label="财务公司对接人姓名" name="finance_contact_name" required>
          <a-input v-model:value="formState.finance_contact_name" placeholder="请输入财务公司对接人姓名"></a-input>
        </a-form-item>
        <a-form-item label="财务公司对接人其他号码">
          <AssociateAuthorization
            type="number"
            v-model:value="formState.finance_contact_phone"
            placeholder="请输入财务公司对接人其他号码"
            suffixTitle="回车或失去焦点后可以添加多个财务公司对接人其他号码"
            inputType="number"
          >
          </AssociateAuthorization>
        </a-form-item>
        <a-form-item label="财务公司对接人微信昵称" name="finance_contact_wechat_nickname">
          <a-input v-model:value="formState.finance_contact_wechat_nickname" placeholder="请输入财务公司对接人微信昵称"></a-input>
        </a-form-item>
        <a-form-item label="财务公司对接人微信号" name="finance_contact_wechat_account">
          <a-input v-model:value="formState.finance_contact_wechat_account" placeholder="请输入财务公司对接人微信号"></a-input>
        </a-form-item>
        <a-form-item label="备注" name="remark">
          <a-input type="textarea" :rows="5" v-model:value="formState.remark" placeholder="请输入备注"></a-input>
        </a-form-item>
        <a-form-item label="代理商姓名" name="seller" required>
          <!-- 不可修改 -->
          <a-input v-model:value="formState.seller" placeholder="请输入代理商姓名"></a-input>
        </a-form-item>
        <a-form-item
          label="此单我的销售价为"
          :wrapperCol="{
            xs: { span: 17 },
            sm: { span: 17 }
          }"
          name="business_contract_price"
        >
          <div class="d-flex" style="align-items: center">
            <a-input class="mr-2x" type="number" prefix="￥" suffix="RMB" v-model:value="formState.business_contract_price" style="width: 200px" placeholder="请输入价格"> </a-input>
            此数值仅供您个人记录备注，非从本平台拿货价
          </div>
          <div class="mt-1x">
            <a class="mr-1x" @click="() => (formState.business_contract_price = 200)">200</a>
            <a class="mr-1x" @click="() => (formState.business_contract_price = 250)">250</a>
            <a @click="() => (formState.business_contract_price = 300)">300</a>
          </div>
        </a-form-item>
        <a-form-item
          label="此单我的续费价格"
          :wrapperCol="{
            xs: { span: 17 },
            sm: { span: 17 }
          }"
          name="read_next_year_price"
          required
          v-if="formState.fee_standard === 1"
        >
          <div class="d-flex" style="align-items: center">
            <a-input class="mr-2x" type="number" prefix="￥" suffix="RMB" v-model:value="formState.read_next_year_price" style="width: 200px" placeholder="请输入价格"> </a-input>
            此数值仅供您个人记录备注，非从本平台拿货价
          </div>
        </a-form-item>
        <a-form-item label="当前选择">
          <div class="red" style="font-size: 20px">
            {{ formState.fee_standard === 1 ? '按年收费' : '一次收费' }}
          </div>
        </a-form-item>
        <a-form-item label="拿货价">
          <div class="red" style="font-size: 20px">￥{{ state.platformPrice.purchase_price }}</div>
        </a-form-item>
        <a-form-item label="来年续费价格" v-if="formState.fee_standard === 1">
          <div class="red" style="font-size: 20px">￥{{ state.platformPrice.renew_price }}</div></a-form-item
        >
        <!-- 编辑展示 -->
        <div v-if="state.isEdit">
          <a-form-item label="入驻开始日期" name="enter_starttime">
            <a-date-picker :disabled="state.isEdit" v-model:value="formState.enter_starttime" placeholder="选择入驻开始日期"></a-date-picker>
          </a-form-item>
          <a-form-item label="入驻到期日期" name="enter_endtime">
            <a-date-picker :disabled="state.isEdit" v-model:value="formState.enter_endtime" placeholder="选择入驻到期日期"></a-date-picker>
          </a-form-item>
          <a-form-item label="成立日期" name="founding_time" v-if="state.isEdit">
            <a-date-picker v-model:value="formState.founding_time" placeholder="选择成立日期"></a-date-picker>
          </a-form-item>
          <a-form-item label="核准日期" name="verify_time" v-if="state.isEdit || formState.business_type != 1">
            <a-date-picker v-model:value="formState.verify_time" placeholder="选择核准日期"></a-date-picker>
          </a-form-item>
        </div>

        <!-- 人员表格 -->
        <div class="share-ownership">
          <a-table class="ownership-table" :columns="state.columns" :data-source="state.shareOwnershipTableData" :pagination="false">
            <template #bodyCell="{ column, record, index, text }">
              <template v-if="column.dataIndex === 'fullname'">
                <span class="mr-1x">{{ record.fullname || record.name }}</span>
                <a-tag color="#ff4d4f" v-if="record.investment_money > 0 || record.investment_amount > 0">股东</a-tag>
                <div class="mt-x">
                  {{ record.phone_number }}
                </div>
              </template>

              <template v-if="column.dataIndex === 'id_card_number'">
                <span class="mr-1x">{{ record.id_card_number || record.corporate_credit_code || '-' }}</span>
              </template>

              <template v-if="column.dataIndex === 'position_ids'">
                <div v-if="text?.length > 0">
                  <div class="positionItem" v-for="(item, index) in text" :key="index">
                    <a-tag color="cyan" v-if="positionDic.get(item)">
                      {{ positionDic.get(item) }}
                    </a-tag>
                  </div>
                </div>
                <div v-else>无</div>
              </template>

              <template v-if="column.dataIndex === 'investment_amount'">
                {{
                  record.investment_money && record.investment_money > 0
                    ? `${record.investment_money}
                                万元`
                    : record.is_shareholder == 1
                    ? `${text} 万元`
                    : '不占股'
                }}
              </template>

              <template v-if="column.dataIndex === 'subscribe_endtime'">
                {{ record.investment_money && record.investment_money > 0 ? record.subscribe_endtime.substr(0, 10) : record.is_shareholder == 1 ? text.substr(0, 10) : '无' }}
              </template>

              <template v-if="column.dataIndex === 'options'">
                <div>
                  <a-button @click="editClick(record, index)" type="primary" size="small" class="mr-1x">编辑</a-button>
                  <a-popconfirm title="确定删除?" @confirm="deleteChange(index)">
                    <a-button size="small" type="primary" danger>删除</a-button>
                  </a-popconfirm>
                </div>
              </template>
            </template>
          </a-table>
          <!-- <div v-if="checkFullName"> -->
          <a-button v-if="formState.main_type == 1 || formState.main_type == 4" class="add-button" type="primary" @click="showInformationVisible">添加人员</a-button>
          <a-button v-if="(formState.main_type == 2 || formState.main_type == 3) && state.shareOwnershipTableData.length < 1" class="add-button" type="primary" @click="showInformationVisible"
            >添加负责人</a-button
          >
          <!-- </div> -->
        </div>
        <!-- 公司类型 -->
        <div>
          <a-form-item label="公司类型" name="company_type_id">
            <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model:value="formState.company_type_id" placeholder="请选择公司类型">
              <a-select-option v-for="(item, index) in state.companyTypeList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <!-- 文件信息 -->
        <div v-if="checkFullName">
          <a-form-item name="letter_commitment_url" label="名称承诺书">
            <a-form-item-rest>
              <a-radio-group class="mb-2x" v-model:value="state.isPledgeId" button-style="solid">
                <a-radio-button :value="1">自动上传</a-radio-button>
                <a-radio-button :value="2">手动上传</a-radio-button>
              </a-radio-group>
            </a-form-item-rest>
            <a-form-item-rest>
              <Upload
                v-if="state.isPledgeId == 2"
                :upload_path="`public/uploads/${state.uploadPathObj.platformName}/${state.uploadPathObj.platformCompanyName}/客户资料/${state.uploadPathObj.mainTypeName}/${state.uploadPathObj.date}/${state.uploadPathObj.companyName}/`"
                uploadType="picture-card"
                :limitation="1"
                :customerName="formState.entityNameToRegister.full_name"
                :accept="['png', 'jpg', 'jpeg', 'pdf']"
                v-model:value="formState.letter_commitment_url"
                uploadText="上传名称承诺书"
              >
              </Upload>
            </a-form-item-rest>
          </a-form-item>
          <a-form-item name="address_data_urls" label="地址资料">
            <a-form-item-rest>
              <a-radio-group class="mb-2x" v-model:value="state.isLocalIpAddress" button-style="solid">
                <a-radio-button :value="1">自动上传</a-radio-button>
                <a-radio-button :value="2">手动上传</a-radio-button>
              </a-radio-group>
            </a-form-item-rest>
            <a-form-item-rest>
              <Upload
                :upload_path="`public/uploads/${state.uploadPathObj.platformName}/${state.uploadPathObj.platformCompanyName}/客户资料/${state.uploadPathObj.mainTypeName}/${state.uploadPathObj.date}/${state.uploadPathObj.companyName}/`"
                v-if="state.isLocalIpAddress == 2"
                :accept="['png', 'jpg', 'jpeg', 'pdf']"
                :customerName="formState.entityNameToRegister.full_name"
                uploadType="picture-card"
                :limitation="99"
                v-model:value="formState.address_data_urls"
                uploadText="上传地址资料"
              >
              </Upload>
              <a-button v-if="state.isLocalIpAddress == 2" type="primary" @click="createAddressClick(1)">生成</a-button>
            </a-form-item-rest>
          </a-form-item>
          <a-form-item name="other_information_urls" label="其他资料">
            <a-form-item-rest>
              <a-radio-group class="mb-2x" v-model:value="state.isFileInformation" button-style="solid">
                <a-radio-button :value="1">自动上传</a-radio-button>
                <a-radio-button :value="2">手动上传</a-radio-button>
              </a-radio-group>
            </a-form-item-rest>
            <a-form-item-rest>
              <Upload
                :upload_path="`public/uploads/${state.uploadPathObj.platformName}/${state.uploadPathObj.platformCompanyName}/客户资料/${state.uploadPathObj.mainTypeName}/${state.uploadPathObj.date}/${state.uploadPathObj.companyName}/`"
                v-if="state.isFileInformation == 2"
                :accept="['png', 'jpg', 'jpeg', 'pdf']"
                uploadType="picture-card"
                :limitation="99"
                :customerName="formState.entityNameToRegister.full_name"
                v-model:value="formState.other_information_urls"
                uploadText="上传其他资料"
              >
              </Upload>
              <p style="color: red">如选择手动上传，不搞电信诈骗承诺书，经营范围范围承诺等法律法规需要的材料均需要手动上传</p>
              <a-button v-if="state.isFileInformation == 2" type="primary" @click="createAddressClick(2)">生成</a-button>
            </a-form-item-rest>
          </a-form-item>

          <a-form-item v-if="state.isEdit || formState.business_type != 1" name="business_license_urls" label="营业执照正副本或遗失声明">
            <Upload
              :upload_path="`public/uploads/${state.uploadPathObj.platformName}/${state.uploadPathObj.platformCompanyName}/客户资料/${state.uploadPathObj.mainTypeName}/${state.uploadPathObj.date}/${state.uploadPathObj.companyName}/`"
              :accept="['png', 'jpg', 'jpeg', 'pdf']"
              uploadType="picture-card"
              :limitation="99"
              :customerName="formState.entityNameToRegister.full_name"
              v-model:value="formState.business_license_urls"
              uploadText="上传营业执照正副本或遗失声明"
            >
            </Upload>
          </a-form-item>
        </div>
        <a-form-item label="证章收货人姓名">
          <div class="d-flex">
            <a-input class="mr-1x" placeholder="请输入证章收货人姓名" v-model:value="formState.recipient_name"></a-input>
            <a-button :loading="state.emailLoading" type="primary" @click="emailChange">自动获取邮寄信息</a-button>
          </div>
        </a-form-item>
        <a-form-item label="证章收货人手机号码">
          <a-input
            @change="
              (v) => {
                phoneChange(v, 'recipient_mobile')
              }
            "
            :value="formState.recipient_mobile"
            placeholder="请输入证章收货人手机号码"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="证章收货人省市区">
          <AddressCascader v-model:value="formState.recipient_area_ids" @change="residenceChange"> </AddressCascader>
        </a-form-item>
        <a-form-item label="证章收货人地址">
          <a-input placeholder="请输入证章收货人地址" v-model:value="formState.recipient_address"></a-input>
        </a-form-item>
        <a-form-item label="邮编">
          <a-input placeholder="请输入邮编" v-model:value="formState.zipcode"></a-input>
        </a-form-item>
        <a-form-item label="邮寄类型">
          <a-radio-group v-model:value="formState.mailing_type" button-style="solid">
            <a-radio-button :value="1">手动邮寄</a-radio-button>
            <a-radio-button :value="2">自动邮寄</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="邮寄付款类型">
          <a-radio-group v-model:value="formState.payment_type" button-style="solid">
            <a-radio-button :value="1">寄付</a-radio-button>
            <a-radio-button :value="2">到付</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="营业执照领取方式">
          <a-radio-group v-model:value="formState.licenAcceptType" button-style="solid">
            <a-radio-button :value="3">自助机领取</a-radio-button>
            <a-radio-button :value="2">电子化邮寄</a-radio-button>
            <a-radio-button :value="1">到登记机关窗口领取</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="公司网址">
          <a-input v-model:value="formState.website" placeholder="请输入公司网址"></a-input>
        </a-form-item>
        <a-form-item label="天眼查手机号码">
          <AssociateAuthorization type="number" v-model:value="formState.tyc_tels" placeholder="请输入天眼查手机号码" suffixTitle="回车或失去焦点后可以添加多个关联授权查看手机号码" inputType="number">
          </AssociateAuthorization>
        </a-form-item>
        <a-form-item label="客户邮箱">
          <a-input v-model:value="formState.data_accept_email" placeholder="请输入客户邮箱"></a-input>
        </a-form-item>

        <a-form-item v-if="props.tableColumnsData?.id" label="主管机关" name="company_authority">
          <Institution v-model:value="formState.company_authority"></Institution>
        </a-form-item>
      </a-form>
    </a-spin>
    <!-- 公司添加人员 -->
    <employment-modal
      :business_type="formState.business_type"
      :company_area_name="state.company_area_name"
      :shareOwnershipTableData="state.shareOwnershipTableData"
      v-if="state.employmentVisible && (formState.main_type == 1 || formState.main_type == 4)"
      @cancel="employmentCancel"
      @change="employmentChange"
      :isEdit="state.isEdit"
      :uploadPathObj="state.uploadPathObj"
      :employmentData="state.employmentData"
      :platform_id="formState.platform_id"
      :position="state.positionList"
      :isFiliale="formState.is_filiale"
      :customerName="formState.entityNameToRegister.full_name"
      :main_type="formState.main_type"
    >
    </employment-modal>
    <!-- 个体户添加人员 -->
    <individual-business-modal
      v-if="state.employmentVisible && (formState.main_type == 2 || formState.main_type == 3)"
      :uploadPathObj="state.uploadPathObj"
      @cancel="employmentCancel"
      :isEdit="state.isEdit"
      @change="individualChange"
      :customerName="formState.entityNameToRegister.full_name"
      :platform_id="formState.platform_id"
      :employmentData="state.employmentData"
    >
    </individual-business-modal>
    <a-modal v-model:open="state.submitTipsVisible" title="提示" :confirm-loading="state.confirmLoading" @ok="onFormSubmitReal">
      <p>{{ state.submitTips }}认缴时间有超过5年，您确定提交吗？</p>
    </a-modal>
  </a-modal>
</template>

<script lang="ts" setup>
import baseService from '@/utils/http/axios'
import Authority from './components/Authority.vue'
import { InfoCircleOutlined, UpOutlined, DownOutlined } from '@ant-design/icons-vue'
import EmploymentModal from './components/form/EmploymentModal.vue'
import IndividualBusinessModal from './components/form/IndividualBusinessModal.vue'
import cloneDeep from 'lodash.clonedeep'
import PrincipalName from './components/form/PrincipalName.vue'
import Upload from '@/components/base/Upload.vue'
import Institution from '../platformManagement/components/Institution.vue'
import AddressCascader from '@/components/base/AddressCascader.vue'
import AssociateAuthorization from '@/components/base/AssociateAuthorization.vue'
import { reactive, onMounted, nextTick, h, ref, onBeforeUnmount, watch, computed } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { Tooltip } from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'
import dayjs from 'dayjs'
import { mobileRequiredValidator, mobileValidator } from '@/utils/util'
import ls from '@/utils/Storage'
const UserInfo = ls.get('USER_INFO')
const emit = defineEmits(['ok', 'cancel'])
import { useStore } from 'vuex'
const $store = useStore()
const formRef = ref()
const principalName = ref()
// 防抖器
let timer: any = null
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
// 定时器
let interval
onBeforeUnmount(() => {
  clearInterval(interval)
})
// 股份预览表格
const companyColumns = [
  {
    title: '姓名',
    dataIndex: 'fullname',
    width: 140
  },
  {
    title: '身份证号码/社会信用代码',
    dataIndex: 'id_card_number',
    width: 200
  },
  {
    title: '担任职位',
    dataIndex: 'position_ids',
    width: 250
  },

  {
    title: '出资金额',
    dataIndex: 'investment_amount',
    width: 130
  },
  {
    title: '认缴期限',
    dataIndex: 'subscribe_endtime',
    width: 130
  },
  {
    title: '操作',
    dataIndex: 'options',
    width: 200
  }
]
// 个人表格
const individualityColumns = [
  {
    title: '姓名',
    dataIndex: 'fullname',
    width: 130,
    scopedSlots: { customRender: 'fullname' }
  },
  {
    title: '身份证号码',
    dataIndex: 'id_card_number',
    width: 130,
    scopedSlots: { customRender: 'id_card_number' }
  },
  {
    title: '住所',
    dataIndex: 'address',
    width: 130,
    scopedSlots: { customRender: 'address' }
  },
  {
    title: '电话号码',
    dataIndex: 'phone_number',
    width: 130,
    scopedSlots: { customRender: 'phone_number' }
  },
  {
    title: '投资数额',
    dataIndex: 'investment_amount',
    scopedSlots: { customRender: 'investment_amount' },
    width: 130
  },
  {
    title: '操作',
    dataIndex: 'options',
    scopedSlots: { customRender: 'options' },
    width: 130
  }
]
const positionDic = new Map([
  [1, '法定代表人'],
  [2, '执行董事'],
  [3, '经理'],
  [4, '监事'],
  [5, '财务负责人'],
  [6, '联络员'],
  [7, '负责人'],
  [8, '财务负责人联络员']
])
const checkFullName = computed(() => {
  const value = formState.entityNameToRegister
  if (!value || !value.organizational_form) {
    return false
  }
  if (formState.is_filiale == 1) {
    if (!value.filiale_full_name || !value.name_word_mark) {
      return false
    }
  } else {
    if (!value.name_division || !value.name_word_mark || !value.ondustry_representation) {
      return false
    }
  }
  if (formState.main_type == 1) {
    if (['有限公司', '有限责任公司', '分公司', '集团有限公司', '分店'].includes(formState.entityNameToRegister.organizational_form)) {
      state.uploadPathObj.mainTypeName = '公司'
    } else {
      state.uploadPathObj.mainTypeName = '合伙企业'
    }
  } else {
    state.uploadPathObj.mainTypeName = formState.main_type == 2 ? '个体户' : '个人独资企业'
  }
  state.uploadPathObj.companyName = formState.entityNameToRegister.full_name
  return true
})
// 主体名称自定义判断
const checkEntityNameToRegister = (rule, value: any) => {
  if (!value) {
    return Promise.reject('请填写主体名称')
  }
  if (!value.organizational_form) {
    return Promise.reject('请输入组织形式')
  }
  //   if (formState.main_type == 1 && formState.business_type == 1 && formState.registration_type == 1 && value.name_division?.includes('市')) {
  // let list = value.name_division.split('市')
  // if (list[1].length > 0) {
  //   return Promise.reject('名称区划不正确')
  // }
  //   }
  if (formState.is_filiale == 1) {
    if (!value.filiale_full_name) {
      return Promise.reject('请输入总公司名称')
    }
    if (!value.name_word_mark) {
      return Promise.reject('请输入字号')
    }
  } else {
    if (!value.name_division) {
      return Promise.reject('请输入名称区划')
    }
    if (!value.name_word_mark) {
      return Promise.reject('请输入字号')
    }

    if (!value.ondustry_representation) {
      return Promise.reject('请输入行业表述')
    }
  }
  return Promise.resolve()
}
const letter_commitment_url = (rule, value) => {
  if (state.isPledgeId == 2) {
    if (!value) {
      return Promise.reject('请上传名称承诺书')
    }
  }
  return Promise.resolve()
}
const address_data_urls = (rule, value) => {
  if (state.isLocalIpAddress == 2) {
    if (!value) {
      return Promise.reject('请上传地址资料')
    }
  }
  return Promise.resolve()
}
const other_information_urls = (rule, value) => {
  if (state.isFileInformation == 2) {
    if (!value) {
      return Promise.reject('请上传其他资料')
    }
  }
  return Promise.resolve()
}
const rules: Record<string, Rule[]> = {
  platform_id: [{ required: true, message: '选择平台' }],
  prefix: [{ required: true, message: '请选择地址编号前缀' }],
  postfix: [{ required: true, message: '请选择地址编号后缀' }],
  entityNameToRegister: [{ validator: checkEntityNameToRegister }],
  isneed_tax_authority: [{ required: false, message: '请选择是否需要税务登记' }],
  social_credit_code: [{ required: true, message: '请输入社会信用代码' }],
  registration_address: [{ required: true, message: '请输入注册详细地址' }],
  business_scope: [{ required: true, message: '请填写经营范围' }],
  registration_mobile: [{ required: true, validator: mobileRequiredValidator }],
  recipient_mobile: [{ required: true, validator: mobileValidator }],
  renew_first_contact_mobile: [{ required: true, validator: mobileRequiredValidator }],
  business_contract_price: [{ required: true, message: '请输入工商合同展示价格' }],
  company_type_id: [{ required: true, message: '请选择公司类型' }],
  letter_commitment_url: [{ validator: letter_commitment_url }],
  address_data_urls: [{ validator: address_data_urls }],
  other_information_urls: [{ validator: other_information_urls }],
  platform_account: [{ required: true, message: '请输入平台账号' }],
  platform_password: [{ required: true, message: '请输入平台密码' }],
  platform_wechat_account: [{ required: true, message: '请输入平台对接人微信号' }],
  platform_person_mobile: [{ required: true, message: '请输入平台对接人手机号' }],
  regist_price: [{ required: true, message: '请输入注册价格' }],
  next_year_price: [{ required: true, message: '请输入来年续费价格' }]
}
interface formState {
  main_type: number | undefined
  platform_type: number | null
  registration_type: number
  isneed_tax_authority: number | null
  is_tax_authority: number | null
  business_type: number
  platform_id: number | null
  prefix: string | null
  is_heming_num: string | null
  social_credit_code: string | null
  reason_relocatio: string | null
  postfix: string | null
  entityNameToRegister: any
  registration_mobile: string
  registered_address_list: string | undefined
  registration_address: string | null
  business_scope: string
  renew_first_contact_mobile: string
  authorized_viewer_mobile: string
  remark: string | null
  licenAcceptType: number
  enter_starttime: string | null
  enter_endtime: string | null
  verify_time: any
  founding_time: any
  recipient_name: string | null
  recipient_mobile: string | null
  recipient_area_ids: string | undefined
  recipient_address: string | null
  zipcode: string | null
  mailing_type: number
  payment_type: number
  company_type_id: number | undefined
  letter_commitment_url: string | undefined
  address_data_urls: object[] | undefined
  other_information_urls: object[] | undefined
  website: string | null
  data_accept_email: string | null
  is_auto: number
  business_contract_price: number | null
  fee_standard: number
  seller: string
  platform_account?: string | null
  agent_idcard_name?: string | null
  platform_password?: string | null
  company_authority?: any
  platform_wechat_account?: string | null
  platform_person_mobile?: string | null
  regist_price?: string | null
  next_year_price?: string | null
  finance_company_name: string
  finance_social_credit_code: string
  finance_contact_name: string
  finance_contact_phone: string
  finance_contact_wechat_nickname: string
  finance_contact_wechat_account: string
  person_list: any
  business_license_urls: any[]
  tyc_tels: string
  is_filiale: number
  read_next_year_price: number
  is_voluntarily_qt: number
  is_voluntarily_dz: number
  raw_address: string
  // 是否已经付款
  first_pay_time?: any
  upload_path: string
  isLocalIpAddress: number
  is_heming: number
}
let formState = reactive<formState>({
  upload_path: '',
  person_list: {},
  company_authority: [],
  main_type: undefined,
  platform_type: 1,
  registration_type: 1,
  isneed_tax_authority: null,
  is_tax_authority: null,
  is_heming_num: null,
  is_voluntarily_qt: 2,
  is_voluntarily_dz: 2,
  business_type: 1,
  platform_id: null,
  prefix: null,
  social_credit_code: null,
  reason_relocatio: null,
  postfix: null,
  entityNameToRegister: {
    name_division: ''
  },
  registration_mobile: '',
  registered_address_list: undefined,
  registration_address: null,
  business_scope: '',
  renew_first_contact_mobile: '',
  finance_company_name: '',
  finance_social_credit_code: '',
  finance_contact_name: '',
  finance_contact_phone: '',
  finance_contact_wechat_account: '',
  finance_contact_wechat_nickname: '',
  authorized_viewer_mobile: '',
  remark: null,
  licenAcceptType: 3,
  enter_starttime: null,
  enter_endtime: null,
  founding_time: null,
  verify_time: null,
  recipient_name: null,
  recipient_mobile: null,
  recipient_area_ids: undefined,
  recipient_address: null,
  zipcode: '410000',
  mailing_type: 1,
  payment_type: 1,
  company_type_id: undefined,
  letter_commitment_url: undefined,
  address_data_urls: [],
  other_information_urls: undefined,
  website: null,
  data_accept_email: null,
  is_auto: 1,
  business_contract_price: null,
  fee_standard: 1,
  seller: '',
  business_license_urls: [],
  tyc_tels: '',
  // 是否为分公司 1是 2否
  is_filiale: 2,
  read_next_year_price: 300,
  // 公司地址
  raw_address: '',
  isLocalIpAddress: 1, // 1自动上传 2手动上传
  is_heming: 2
})
const props = defineProps({
  open: {
    type: Boolean
  },
  tableColumnsData: {
    type: Object,
    default: () => null
  }
})
watch(
  () => props.open,
  (val) => {
    if (val) {
      if (!formState.seller) {
        formState.seller = UserInfo.idcard_name
      }
    }
  }
)

watch(
  () => formState.entityNameToRegister,
  (val) => {
    if (val.organizational_form == '分公司' || val.organizational_form == '分店') {
      formState.is_filiale = 1
    } else {
      formState.is_filiale = 2
    }
  }
)
onMounted(() => {
  if (props.tableColumnsData?.id && !props.tableColumnsData.isCopy) {
    state.centered = true
    state.isFirst = false
    state.modalTitle = '编辑客户'
    state.loading = true
    state.isEdit = true
    formState.main_type = 1
  }
  // 初始化
  queryCompanyTypeList()
})
interface platformServicesList {
  id: number
  name: string
  company_area_name: string
  company_city_name: string
  platform_name: string
  company_province_name: string
}
interface companyTypeList {
  name: string
  id: number
}
interface uploadPathObj {
  platformName: string
  platformCompanyName: string
  date: string
  companyName: string
  mainTypeName: string
}
interface state {
  shareOwnershipTableData: any[]
  shareOwnershipTableDeleteData: object[]
  modalTitle: string
  isFirst: boolean
  loading: boolean
  keyboard: boolean
  maskClosable: boolean
  columns: object[]
  positionList: string[]
  employmentData: any
  employmentVisible: boolean
  isFileInformation: number
  isLocalIpAddress: number
  isPledgeId: number
  verificationErrorList: object[]
  isEdit: boolean
  platformServicesList: platformServicesList[]
  companyTypeList: companyTypeList[]
  prefixList: string[]
  postfixList: string[]
  checkPass: boolean
  editIndex: number
  platformDataList: any[]
  filingsList: any[]
  company_area_name: string
  centered: boolean
  mobileBlurLoading: boolean
  emailLoading: boolean
  verificationLoading: boolean
  confirmLoading: boolean
  companyName: string[]
  emailList: any[]

  emailIndex: number
  is_provide_adds: number | null
  uploadPathObj: uploadPathObj
  principalType: number
  platformObj: any
  platformPrice: any
  submitTipsVisible: boolean
  submitTips: string
}
const state = reactive<state>({
  // 选择的平台信息
  platformObj: {},
  platformPrice: {},
  principalType: 1,
  // 图片上传路径
  uploadPathObj: {
    date: dayjs().format('YYYYMM'),
    platformName: '',
    platformCompanyName: '',
    companyName: '',
    mainTypeName: ''
  },
  // 查询财务公司
  mobileBlurLoading: false,
  //   查询代理商
  confirmLoading: false,
  // 查询邮寄信息
  emailLoading: false,
  // 查询邮寄信息List
  emailList: [],
  emailIndex: 0,
  //   核验
  verificationLoading: false,
  platformDataList: [],
  //   财务公司List
  filingsList: [],
  // 占股人员表格
  shareOwnershipTableData: [],
  // 删除 人员信息表 存一份需要给后端
  shareOwnershipTableDeleteData: [],
  modalTitle: '新增客户',
  isFirst: true,
  loading: false,
  // esc是否关闭弹窗
  keyboard: true,
  // 居中布局model
  centered: false,
  // 蒙层是否关闭弹窗
  maskClosable: true,
  // 预览表格columns
  columns: [],
  //职位信息
  positionList: [],
  // 录用人员数据 暂存
  employmentData: {},
  employmentVisible: false,
  // 其他资料是否手动上传
  isFileInformation: 1,
  // 地址资料是否手动上传
  isLocalIpAddress: 1,
  // 名称承诺书是否手动上传
  isPledgeId: 1,
  // 核验失败的范围list
  verificationErrorList: [],
  // 是否为编辑
  isEdit: false,
  // 平台信息
  platformServicesList: [],
  // 公司类型信息
  companyTypeList: [],
  // 前缀list
  prefixList: [],
  // 后缀list
  postfixList: [],
  // 经营范围
  checkPass: false,

  // 编辑的第几个
  editIndex: 0,
  // 选择平台区
  company_area_name: '',
  // 获取字号
  companyName: [],
  //   是否需要裸地址
  is_provide_adds: null,
  submitTipsVisible: false,
  submitTips: ''
})
watch(
  () => [formState.registration_type, formState.fee_standard, formState.main_type, formState.platform_id, formState.business_type, state.isFirst],
  (val) => {
    if (state.isFirst) {
      return
    }
    const registration_type = formState.registration_type,
      fee_standard = formState.fee_standard,
      main_type = formState.main_type,
      platform_id = formState.platform_id,
      business_type = formState.business_type
    if (!platform_id) {
      return
    }
    clearTimeout(timer)
    timer = setTimeout(() => {
      let requestParam = {
        url: `/admin/v1/getPlatformPrice?platform_id=${platform_id}&main_type=${main_type}&registration_type=${registration_type}&fee_standard=${fee_standard}&business_type=${business_type}`,
        method: 'get'
      }
      baseService(requestParam)
        .then((res) => {
          state.platformPrice = res.data
        })
        .catch((res) => {
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('查询失败')
          }
        })
    }, 1000)
  }
)
const queryDetail = () => {
  let requestParam = {
    url: `/admin/v1/customer/${props.tableColumnsData.id}/edit`,
    method: 'get'
  }
  baseService(requestParam)
    .then(async (res) => {
      // 主体类型获取
      initialTypeChange(res.data.main_type)
      state.uploadPathObj.date = dayjs(res.data.created_at, 'YYYY-MM-DD HH:mm:ss').format('YYYYMM')
      if (res.data.letter_commitment_url?.length > 0) state.isPledgeId = 2
      if (res.data.address_data_urls?.length > 0) state.isLocalIpAddress = 2
      if (res.data.other_information_urls?.length > 0) state.isFileInformation = 2
      // 获取人员表
      state.shareOwnershipTableData = [...res.data.person_list.fr, ...res.data.person_list.person]
      if (res.data.person_list.person.length > 0 && res.data.main_type == 1) {
        res.data.person_list.person.forEach((item, index) => {
          state.positionList = [...state.positionList, ...item.position_ids]
        })
      }

      // 获得平台 前缀后缀
      let obj: any = {}
      for (let index = 0; index < state.platformServicesList.length; index++) {
        if (state.platformServicesList[index].id == res.data.platform_id) {
          obj = state.platformServicesList[index]
          state.uploadPathObj.platformName = `${state.platformServicesList[index].company_province_name}${state.platformServicesList[index].company_city_name}${state.platformServicesList[index].company_area_name}`
          state.uploadPathObj.platformCompanyName = state.platformServicesList[index].platform_name
        }
      }
      state.prefixList = res.data.main_type == 2 ? obj.individual_address_number_prefix : obj.company_address_number_prefix
      state.postfixList = res.data.main_type == 2 ? obj.individual_address_number_postfix : obj.company_address_number_postfix
      // 编辑直接核验通过
      state.checkPass = true
      delete res.data.person_list
      nextTick(() => {
        if (res.data.verify_time) res.data.verify_time = dayjs(res.data.verify_time, 'YYYY-MM-DD')
        res.data.enter_endtime = dayjs(res.data.enter_endtime, 'YYYY-MM-DD')
        res.data.founding_time = dayjs(res.data.founding_time, 'YYYY-MM-DD')
        res.data.enter_starttime = dayjs(res.data.enter_starttime, 'YYYY-MM-DD')
        Object.assign(formState, res.data)

        state.platformObj = obj
        state.loading = false
      })
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      }
    })
}
// 公司类型 平台
const queryCompanyTypeList = () => {
  let requestParam = {
    url: '/admin/v1/customer/create',
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      state.companyTypeList = res.data.company_type
      state.platformServicesList = res.data.platform
      if (props.tableColumnsData?.id) {
        if (props.tableColumnsData.isCopy) {
          copyDetail(props.tableColumnsData.id)
        } else {
          state.centered = true
          state.isFirst = false
          state.modalTitle = '编辑客户'
          state.loading = true
          state.isEdit = true
          formState.main_type = 1
          queryDetail()
        }
      }
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      }
    })
}

// 用户名搜索
const handleSearch = (e) => {
  clearTimeout(timer)
  if (formState.agent_idcard_name == '') {
    return
  }
  timer = setTimeout(() => {
    let requestParam = {
      url: '/admin/userCheckShare?idcard_name=' + formState.agent_idcard_name,
      method: 'get'
    }
    baseService(requestParam)
      .then((res) => {
        if (res.data?.length > 0) {
          formState.platform_password = res.data[0].dzh_password
          formState.platform_account = res.data[0].dzh_name
        }
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('查询失败')
        }
      })
  }, 500)
}

// 选择平台之后 获取前缀后缀
const selectPlatformChange = (res, e) => {
  // 公司类型
  let obj: any = null
  if (e?.title) {
    obj = e.title
  } else {
    obj = e
  }
  if (obj.name.includes('独立注册')) {
    formState.platform_type = 2
  } else {
    formState.platform_type = 1
  }
  state.uploadPathObj.platformName = `${obj.company_province_name}${obj.company_city_name}${obj.company_area_name}`
  state.uploadPathObj.platformCompanyName = obj.platform_name
  if (formState.main_type == 2) {
    state.prefixList = obj.individual_address_number_prefix
    state.postfixList = obj.individual_address_number_postfix
  } else {
    state.prefixList = obj.company_address_number_prefix
    state.postfixList = obj.company_address_number_postfix
  }
  nextTick(() => {
    state.platformObj = obj
    state.company_area_name = obj.company_area_name
    // 是否需要裸地址   如果为2则不能选择裸地址
    state.is_provide_adds = obj.is_provide_adds
    if (obj.is_provide_adds == 2) {
      formState.registration_type = 1
    }

    if (state.prefixList.length == 1) {
      formState.prefix = state.prefixList[0]
    } else {
      formState.prefix = null
    }
    formState.postfix = state.postfixList[0] //地址编号后缀默认选中中第一个
    if (formState.main_type == 2) {
      principalName.value.inputChange(
        {
          target: {
            value: obj.company_city_name + obj.company_area_name
          }
        },
        'name_division'
      )
      //   formState.entityNameToRegister.name_division = obj.company_city_name + obj.company_area_name
    }
  })
}
const businessChange = (v) => {
  let str = v.target.value.replaceAll(';', '；')
  str = v.target.value.replaceAll('\n', '')
  formState.business_scope = str
}
// 核验
const verificationClick = (type) => {
  if (state.verificationLoading) {
    return
  }
  if (type != 'click' && (formState.business_type != 1 || formState.registration_type != 1)) {
    return
  }
  let reg = /^\s+$/g
  // 不能为空格 不能为空
  let re = reg.test(formState.business_scope)
  if (!formState.business_scope || re) {
    message.warning('请填写经营范围之后再核验')
    return
  }
  let verificationString = formState.business_scope.replaceAll(';', '；')
  state.verificationLoading = true
  let requestParam = {
    url: '/admin/v1/customerVerify?str=' + verificationString + '&business_type=' + formState.business_type + '&platform_id=' + formState.platform_id + '&main_type=' + formState.main_type,
    method: 'get'
  }
  baseService(requestParam)
    .then((res: any) => {
      state.verificationLoading = false

      if (res.data.error.length == 0) {
        message.success(res.msg)
        state.verificationErrorList = []
        state.checkPass = true
      } else {
        message.error(
          h('span', {
            innerHTML: '以下经营范围不规范：' + res.data.error
          }),
          6
        )
        state.checkPass = false
        state.verificationErrorList = res.data.error
      }
    })
    .catch((res) => {
      state.verificationLoading = false
      if (res.msg) {
        message.error(
          h('span', {
            innerHTML: res.msg
          }),
          6
        )
      }
    })
}
const contactMobileBlur = () => {
  if (state.mobileBlurLoading) {
    return
  }
  if (formState.seller == '朱尚') {
    if (formState.authorized_viewer_mobile) {
      if (!formState.authorized_viewer_mobile.includes('17336596383')) {
        formState.authorized_viewer_mobile = formState.authorized_viewer_mobile + ',17336596383'
      }
    } else {
      formState.authorized_viewer_mobile = '17336596383'
    }
  }
  formRef.value
    .validateFields('renew_first_contact_mobile')
    .then(() => {
      state.mobileBlurLoading = true
      let requestParam = {
        url: '/admin/v1/filingsList',
        method: 'post',
        data: { page: 1, limit: 'all', customer_tel: formState.renew_first_contact_mobile }
      }
      baseService(requestParam)
        .then((res) => {
          state.mobileBlurLoading = false
          if (res?.data?.list?.length === 1) {
            let obj: any = res.data.list[0]
            if (obj.status === 1) {
              formState.finance_contact_name = obj.customer_name
              formState.finance_contact_wechat_account = obj.wechat_id
              formState.finance_contact_wechat_nickname = obj.wechat_name
              formState.finance_company_name = obj.company_name
              formState.finance_social_credit_code = obj.finance_social_credit_code
            } else {
              message.warn('公司暂时未通过审核,请联系管理员')
            }
          } else if (res?.data?.list?.length > 1) {
            state.filingsList = res.data.list.filter((item) => {
              if (item.status == 1) {
                return item
              } else {
                message.warn('公司暂时未通过审核,请联系管理员')
              }
            })
            let obj = res.data.list[0]
            formState.finance_company_name = obj.company_name
            formState.finance_social_credit_code = obj.finance_social_credit_code

            formState.finance_contact_name = obj.customer_name
            formState.finance_contact_wechat_account = obj.wechat_id
            formState.finance_contact_wechat_nickname = obj.wechat_name
          } else {
            message.warn('该续费联系人手机号码没有绑定财务公司,请重新填写手机号码或者手动填写财务公司')
          }
        })
        .catch((res) => {
          state.mobileBlurLoading = false
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('查询失败')
          }
        })
    })
    .catch(() => {})
}
const selectFilingsListChange = (item) => {
  if (item.status === 1) {
    formState.finance_company_name = item.company_name
    formState.finance_social_credit_code = item.finance_social_credit_code
  } else {
    message.warn('公司暂时未通过审核,请联系管理员')
  }
}
const phoneChange = (v, type) => {
  let str = v.target.value.replaceAll(/\D/g, '')
  formState[type] = str
}
// 注册地址省市区
const companyPauaChange = (idList, val: any) => {
  state.uploadPathObj.platformName = `${val[0].name}${val[1].name}${val[2].name}`
  state.uploadPathObj.platformCompanyName = '合作'
}
// 邮寄地址省市区
const residenceChange = (idList, value: any) => {
  let address = `${value[0].name}${value[1].name}${value[2].name}`
  formState.recipient_address = address
}
// 获取字号
const getrandcompanyname = () => {
  let requestParam = {
    url: '/admin/v1/getrandcompanyname?num=26',
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      state.companyName = res.data.msg
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取失败')
      }
    })
}
const copyDetail = (id) => {
  state.isFirst = false
  state.loading = true
  state.shareOwnershipTableData = []
  let requestParam = {
    url: `/admin/v1/customer/${id}/edit`,
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      // 主体类型获取
      initialTypeChange(res.data.main_type)
      // 获取人员表
      state.shareOwnershipTableData = [...res.data.person_list.fr, ...res.data.person_list.person]
      if (res.data.person_list.person.length > 0 && res.data.main_type == 1) {
        res.data.person_list.person.forEach((item, index) => {
          state.positionList = [...state.positionList, ...item.position_ids]
        })
      }
      if (res.data.platform_type == 1) {
        // 获得平台 前缀后缀
        let obj: any = {}
        for (let index = 0; index < state.platformServicesList.length; index++) {
          if (state.platformServicesList[index].id == res.data.platform_id) {
            obj = state.platformServicesList[index]
            state.uploadPathObj.platformName = `${state.platformServicesList[index].company_province_name}${state.platformServicesList[index].company_city_name}${state.platformServicesList[index].company_area_name}`
            state.uploadPathObj.platformCompanyName = state.platformServicesList[index].platform_name
          }
        }
        if (res.data.main_type == 2) {
          state.prefixList = obj.individual_address_number_prefix
          state.postfixList = obj.individual_address_number_postfix
        } else {
          state.prefixList = obj.company_address_number_prefix
          state.postfixList = obj.company_address_number_postfix
        }
        res.data.postfix = state.postfixList[0] //地址编号后缀默认选中中第一个
      } else {
        state.uploadPathObj.platformName = `${res.data.cooperation_province}${res.data.cooperation_city}${res.data.cooperation_area}`
        state.uploadPathObj.platformCompanyName = '合作'
      }
      state.loading = false
      nextTick(() => {
        //res.data.entityNameToRegister.name_word_mark = '' //2024-11-04保留字号
        const newObject = Object.keys(formState).reduce((a, key) => ({ ...a, [key]: res.data[key] }), {})
        Object.assign(formState, newObject)
        //复制的时候自动核验经营范围-设立
        if (formState.business_type == 1) {
          verificationClick('click')
        }
        state.loading = false
      })
    })
    .catch((res) => {
      state.loading = false
      if (res.msg) {
        message.error(res.msg)
      }
    })
}

const selectCompanyNameChange = (val) => {
  principalName.value.inputChange(
    {
      target: {
        value: val
      }
    },
    'name_word_mark'
  )
}
// 录入
const onEnteringClick = () => {
  if (!formState.entityNameToRegister.full_name) {
    message.error('请输入公司主体名称后再去导入')
    return
  }
  clearInterval(interval)
  window.open('https://www.tianyancha.com/search?key=' + formState.entityNameToRegister.full_name)
  interval = setInterval(() => {
    let formData = new FormData()
    formData.append('type', 'get')
    formData.append('companyname', formState.entityNameToRegister.full_name)

    let requestParam = {
      url: `/admin/v1/daorucompany`,
      method: 'post',
      data: formData
    }
    baseService(requestParam)
      .then((res) => {
        state.checkPass = true
        clearInterval(interval)
        let data = JSON.parse(res.data.content)
        data.person_list.fr.forEach((item) => {
          if (item.position_ids) {
            item.position_ids = item.position_ids.filter((x) => !!x == true)
          }
        })
        state.shareOwnershipTableData = data.person_list.fr
        formState.social_credit_code = data.social_credit_code
        formState.business_scope = data.business_scope
        formState.renew_first_contact_mobile = data.renew_first_contact_mobile
        formState.company_type_id = data.company_type_id
        formState.website = data.website
        formState.raw_address = data.address
        formState.verify_time = dayjs(data.verify_time, 'YYYY-MM-DD')
        // formState.phone = data.phone
      })
      .catch((res) => {})
  }, 2000)
}
// 打开录用窗口
const showInformationVisible = () => {
  const value = formState.entityNameToRegister
  if (!value || !value.organizational_form) {
    message.warn('请填写完整的公司主体名称,才能选择人员')
    return
  }
  if (formState.is_filiale == 1) {
    if (!value.filiale_full_name || !value.name_word_mark) {
      message.warn('请填写完整的公司主体名称,才能选择人员')
      return
    }
  } else {
    if (!value.name_division || !value.name_word_mark || !value.ondustry_representation) {
      message.warn('请填写完整的公司主体名称,才能选择人员')
      return
    }
  }
  state.employmentData = {}
  state.employmentVisible = true
}
// 关闭录用窗口
const employmentCancel = () => {
  state.employmentVisible = false
}
// 个体户保存录用信息
const individualChange = (res) => {
  if (res.type == 'add') {
    delete res.type
    state.shareOwnershipTableData.push(res)
  } else {
    delete res.type
    state.shareOwnershipTableData[0] = res
  }
  state.employmentVisible = false
}
// 生成地址资料
const createAddressClick = (type) => {
  let requestParam = {
    url: '/admin/v1/getAddressDataTemplate?platform_id=' + formState.platform_id,
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      if (type == 1) {
        formState.is_voluntarily_qt = 1
      } else {
        formState.is_voluntarily_dz = 1
      }
      let list: any = []
      if (formState.main_type != 2) {
        if (type == 1) {
          res.data.gsdzTemplate.forEach((item) => {
            list.push(item.template_url.url)
          })
        } else {
          res.data.gsqtTemplate.forEach((item) => {
            list.push(item.template_url.url)
          })
        }
      } else {
        if (type == 1) {
          res.data.gtdzTemplate.forEach((item) => {
            list.push(item.template_url.url)
          })
        } else {
          res.data.gtqtTemplate.forEach((item) => {
            list.push(item.template_url.url)
          })
        }
      }
      if (list.length == 0) {
        message.info('平台该资料为空,请手动上传')
      } else {
        message.success('生成成功')
      }
      if (type == 1) {
        formState.address_data_urls = [...list]
      } else {
        formState.other_information_urls = [...list]
      }
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('生成失败')
      }
    })
}
// 保存录用信息
const employmentChange = (res, positionList) => {
  // 判断是否为新增
  if (res.type == 'add') {
    // 判断是否存在已经保存的人员信息
    const isRepeat = state.shareOwnershipTableData.some((item: any) => {
      if (res.stockholder == 2) {
        return item.id_card_number == res.id_card_number
      } else {
        return item.corporate_credit_code == res.corporate_credit_code
      }
    })
    if (isRepeat) {
      message.warning('该人员已经添加,不能重复')
      return
    }
    delete res.type
    delete res.stockholder
    state.shareOwnershipTableData.push(res)
    state.positionList = [...state.positionList, ...res.position_ids]
  } else {
    let stockholder = res.stockholder
    delete res.type
    delete res.stockholder
    if (stockholder == 2) {
      state.positionList = [...positionList, ...res.position_ids]
    }
    state.shareOwnershipTableData[state.editIndex] = res
    // 清空之后的职位表 再次合并
  }
  if (formState.is_filiale == 1) {
    formState.company_type_id = 6
  } else {
    // 公司类型
    let companyType: number | undefined = 1
    // 法人股东数量
    let legalPerson = 0
    let legalPerson_money = 0.0
    // 自然人 股东数量
    let shareholder = 0
    let shareholder_money = 0.0
    state.shareOwnershipTableData.forEach((item: any, index) => {
      //  存在统一社会信用代码则是法人股东
      if (item.corporate_credit_code) {
        legalPerson++
        legalPerson_money += Number(item.investment_money)
      } else {
        // 判断自然人股东是否占股
        if (item.is_shareholder == 1) {
          shareholder++
          shareholder_money += Number(item.investment_amount)
        }
      }
    })

    if (legalPerson == 0) {
      //没有法人股东的
      companyType = shareholder == 1 ? 2 : 1
    } else {
      //有法人股东的
      if (legalPerson == 1 && shareholder == 0) {
        //跟随法人股东的性质来，有限责任公司(自然人投资或控股的法人独资)、有限责任公司（非自然人投资或控股的法人独资）
        companyType = undefined
      } else {
        //谁出钱多，就是哪种成分，如果法人股东出钱多，则是其他有限责任公司，反之
        if (legalPerson_money > shareholder_money) {
          companyType = 5
        } else {
          companyType = 1
        }
      }
    }

    formState.company_type_id = companyType
  }
  state.employmentVisible = false
}
// 编辑人员信息
const editClick = (data, index) => {
  state.editIndex = index
  state.employmentData = cloneDeep(data)
  state.employmentData.type = 'edit'
  state.employmentVisible = true
}
// 删除人员信息
const deleteChange = (index) => {
  if (state.isEdit) {
    // 删除 人员信息表 存一份需要给后端
    state.shareOwnershipTableDeleteData.push({ ...{ edit_code: 'del' }, ...state.shareOwnershipTableData[index] })
  }
  // 删除职位
  if (state.shareOwnershipTableData[index]?.position_ids) {
    state.shareOwnershipTableData[index].position_ids.forEach((val) => {
      state.positionList = state.positionList.filter((item) => item !== val)
    })
  }
  state.shareOwnershipTableData.splice(index, 1)
}
// 初始进入切换公司主体类型
const initialTypeChange = (val) => {
  if (state.confirmLoading) {
    return
  }
  formState.main_type = val
  if (state.isFirst) {
    onFormSubmit()
  }
  principalTypeChange({ target: { value: val } })
}
// 切换公司主体类型
const principalTypeChange = (val) => {
  if (val.target.value == 1) {
    formState.entityNameToRegister.name_division = ''
  }
  if (state.shareOwnershipTableData.length > 0) {
    Modal.confirm({
      title: '有已经填写好的人员,切换后填写的人员将重置,是否继续?',
      onOk: () => {
        state.shareOwnershipTableData = []
        state.positionList = []
        if (val.target.value == 1) {
          state.columns = companyColumns
        } else {
          state.columns = individualityColumns
        }

        //切换主体类型后，重新选一下平台
        if (formState.platform_id) {
          for (let index = 0; index < state.platformServicesList.length; index++) {
            if (state.platformServicesList[index].id == formState.platform_id) {
              selectPlatformChange(formState.platform_id, state.platformServicesList[index])
            }
          }
        }
        state.principalType = val.target.value
      },
      onCancel: () => {
        formState.main_type = state.principalType
      }
    })
  } else {
    if (val.target.value == 1) {
      state.columns = companyColumns
    } else {
      state.columns = individualityColumns
    }
    if (formState.platform_id) {
      for (let index = 0; index < state.platformServicesList.length; index++) {
        if (state.platformServicesList[index].id == formState.platform_id) {
          selectPlatformChange(formState.platform_id, state.platformServicesList[index])
        }
      }
      //selectPlatfrmoChange(formState.platform_id, state.platformObj) //复制的时候state.platformObj为空
    }
    state.principalType = val.target.value
  }
}
// 公司名称字号
const selectBusinessScope = () => {
  window.open(
    'https://hnscjgj.amr.hunan.gov.cn:8022/jyfw/?backUrl=https%3A%2F%2Fconsole.cskaiyela.com%2Fcustomer%2F',
    'newwindow2',
    'height=700, width=1000, top=300, left=300,toolbar=no, menubar=no, scrollbars=no, resizable=no, loca tion=no, status=no'
  )
}
// 关闭
const cancelClick = (val) => {
  if (state.isEdit) {
    emit('cancel')
    return
  }
  if (!state.isFirst) {
    Modal.confirm({
      title: '是否确定关闭弹窗',
      onOk: () => {
        clearInterval(interval)
        emit('cancel')
      },
      okType: 'primary'
    })
  } else {
    emit('cancel')
  }
}
// 提交
const onFormSubmit = () => {
  if (state.isFirst) {
    onFormSubmitReal()
  } else {
    //判断认缴时间不能超过5年
    const now = dayjs() // 计算5年后的时间点
    const fiveYearsAgo = now.add(5, 'year')
    let errname = ''
    for (let index = 0; index < state.shareOwnershipTableData.length; index++) {
      let item = state.shareOwnershipTableData[index]
      if (item.is_shareholder == 1) {
        const checkedTime = dayjs(item.subscribe_endtime)
        if (!checkedTime.isBefore(fiveYearsAgo)) {
          errname += item.name ? item.name : item.fullname + '、'
        }
      }
    }
    if (errname && formState.business_type == 1 && formState.main_type == 1) {
      //只判断设立类型 的 公司
      state.submitTips = errname
      state.submitTipsVisible = true //显示提示
      return
    } else {
      onFormSubmitReal()
    }
  }
}
const onFormSubmitReal = () => {
  state.submitTipsVisible = false
  if (state.isFirst) {
    if (state.confirmLoading) {
      return
    }

    if (!formState.main_type) {
      message.warning('请选择公司主体类型')
      return
    }
    if (formState.main_type == 2) {
      formState.company_type_id = 11
    }
    if (formState.main_type == 3) {
      formState.company_type_id = 12
    }

    if (!formState.platform_id) {
      message.warning('请选择平台简称')
      return
    }
    if (!formState.seller) {
      message.warning('请输入代理商姓名')
      return
    }
    state.confirmLoading = true
    let requeryParam = {
      url: '/admin/v1/checkSeller?seller=' + formState.seller + '&platform_id=' + formState.platform_id,
      method: 'get'
    }
    baseService(requeryParam)
      .then((res: any) => {
        state.keyboard = false
        state.centered = true
        state.maskClosable = false
        state.isFirst = false
        state.confirmLoading = false
      })
      .catch((res) => {
        state.confirmLoading = false
        message.error(res?.msg ? res.msg : '确定失败，请联系管理员')
      })
    return
  }
  formRef.value
    .validate()
    .then(() => {
      //   console.log('客户新增data', formState)
      if (formState.business_type == 1 && formState.registration_type == 1) {
        if (!state.checkPass) {
          message.warn('经营范围核验不通过,请修改重试')
          return
        }
        let positionList: any[] = []
        state.shareOwnershipTableData.forEach((item, index) => {
          if (item.position_ids) {
            positionList = [...positionList, ...item.position_ids]
          }
        })
        if (formState.main_type == 1) {
          if (formState.is_filiale == 1) {
            // 分公司
          } else {
            // 出资金额
            let price = 0
            for (let index = 0; index < state.shareOwnershipTableData.length; index++) {
              if (state.shareOwnershipTableData[index].investment_amount) {
                price = price + Number(state.shareOwnershipTableData[index].investment_amount)
              } else if (state.shareOwnershipTableData[index].investment_money) {
                price = price + Number(state.shareOwnershipTableData[index].investment_money)
              }
            }
            // 判断 公司主体名称为湖南的情况下 出资金额必须大于200W
            if (formState.entityNameToRegister.name_division.substring(0, 2) == '湖南' && price < 200) {
              message.warning('湖南开头市场主体出资金额需不低于200W')
              return
            }
          }
        } else if (formState.main_type != 1 && state.shareOwnershipTableData.length < 1) {
          message.error('请上传负责人信息')
          return
        }
      }
      // 构建人员信息 分为法人和自然人  当类型为个体的时候不存在法人
      formState.person_list = {
        fr: [],
        person: []
      }
      // 公司
      if (formState.main_type == 1) {
        let tableDataList = [...state.shareOwnershipTableData, ...state.shareOwnershipTableDeleteData]
        tableDataList.forEach((item: any, index) => {
          const { name, phone_number, corporate_credit_code, investment_money, subscribe_endtime, business_license_url, edit_code, id, area_ids, address, frdbname, is_bensheng } = item
          // 1为法人 2为自然人
          if (item.name) {
            formState.person_list.fr.push({
              // 法人名称
              name,
              // 法人信用代码
              corporate_credit_code,
              // 联系号码
              phone_number,
              // 法人出资金额
              investment_money,
              // 法人出资截止日期
              subscribe_endtime,
              // 法人营业执照
              business_license_url,
              // 状态
              edit_code,
              id,
              area_ids,
              address,
              frdbname,
              is_bensheng
            })
          } else {
            delete item.name
            delete item.corporate_credit_code
            delete item.investment_money
            delete item.business_license_url
            formState.person_list.person.push(item)
          }
        })
      } else {
        formState.person_list.person = [...state.shareOwnershipTableData, ...state.shareOwnershipTableDeleteData]
      }

      formState.isLocalIpAddress = state.isLocalIpAddress
      state.confirmLoading = true
      formState.upload_path = `public/uploads/${state.uploadPathObj.platformName}/${state.uploadPathObj.platformCompanyName}/客户资料/${state.uploadPathObj.mainTypeName}/${state.uploadPathObj.date}/${state.uploadPathObj.companyName}/`
      if (props.tableColumnsData?.id && !props.tableColumnsData.isCopy) {
        let data = cloneDeep(formState)
        if (data.verify_time) data.verify_time = dayjs(data.verify_time).format('YYYY-MM-DD')
        data.enter_endtime = dayjs(data.enter_endtime).format('YYYY-MM-DD')
        data.founding_time = dayjs(data.founding_time).format('YYYY-MM-DD')
        data.enter_starttime = dayjs(data.enter_starttime).format('YYYY-MM-DD')
        let requeryParam = {
          url: '/admin/v1/customer/' + props.tableColumnsData.id,
          method: 'put',
          data: data
        }
        baseService(requeryParam)
          .then((res: any) => {
            message.success(res.msg)
            state.confirmLoading = false
            emit('ok')
          })
          .catch((res) => {
            if (res) {
              state.confirmLoading = false
              if (res.msg) {
                message.warning(res.msg)
                return
              }
              message.warning('参数错误,请检查')
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/customer',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            message.success(res.msg)
            state.confirmLoading = false
            emit('ok')
          })
          .catch((res) => {
            if (res) {
              state.confirmLoading = false
              if (res.msg) {
                message.warning(res.msg)
                return
              }
              message.warning('参数错误,请检查')
            }
          })
      }
    })
    .catch((val) => {
      console.log(val)
      message.warning('请检查必填项')
    })
}
// 获取邮寄信息
const emailChange = () => {
  if (!formState.renew_first_contact_mobile) {
    message.warn('请填写续费联系人手机号码再获取邮寄信息')
    return
  }
  state.emailLoading = true
  //   if (state.emailList?.length > 0) {
  //     let obj: any = {}
  //     if (state.emailIndex == state.emailList.length - 1) {
  //       obj = state.emailList[0]
  //       state.emailIndex = 0
  //     } else {
  //       obj = state.emailList[state.emailIndex + 1]
  //     }
  //     formState.recipient_name = obj.name
  //     formState.recipient_mobile = obj.tel
  //     formState.recipient_address = obj.address
  //     formState.recipient_area_ids = `${obj.recipient_province_id},${obj.recipient_city_id},${obj.recipient_area_id}`
  //     state.emailLoading = false
  //     message.success('获取成功')
  //   } else {
  let requeryParam = {
    url: '/admin/v1/mailingAddressList',
    method: 'post',
    data: {
      page: 1,
      limit: 'all',
      customer_tel: formState.renew_first_contact_mobile
    }
  }
  baseService(requeryParam)
    .then((res: any) => {
      state.emailIndex = 0
      state.emailList = res.data.list
      if (res.data.list?.length > 0) {
        formState.recipient_name = res.data.list[0].name
        formState.recipient_mobile = res.data.list[0].tel
        formState.recipient_address = res.data.list[0].address
        formState.recipient_area_ids = `${res.data.list[0].recipient_province_id},${res.data.list[0].recipient_city_id},${res.data.list[0].recipient_area_id}`
      }
      message.success(res.msg)
      state.emailLoading = false
    })
    .catch((res) => {
      if (res) {
        state.emailLoading = false
        if (res.msg) {
          message.warning(res.msg)
          return
        }
      }
    })
  //   }
}
</script>

<style lang="less" scoped>
.category {
  padding: 30px 0;
  display: flex;
  justify-content: space-around;

  :deep(.ant-input-number-handler-wrap) {
    display: none;
  }

  .category-item {
    font-size: 24px;
    cursor: pointer;
    padding: 50px;
    border-radius: 15px;
    width: 100%;
    margin-right: 20px;
    text-align: center;
  }

  .company {
    border: 1px solid #e0f9f2;
    background: #e0f9f2;
    color: #00d097;
  }

  .company.check {
    border: 1px solid #00d097;
  }

  .individual {
    background: #e8f1ff;
    color: #3f8cff;
    border: 1px solid #e8f1ff;
  }

  .individual.check {
    border: 1px solid #3f8cff;
  }

  .proprietorship {
    border: 1px solid #e6fffb;
    background: #e6fffb;
    color: #13c2c2;
  }

  .proprietorship.check {
    border: 1px solid #13c2c2;
  }
}

.platform-label {
  line-height: 32px;
  max-width: 102px;
  display: flex;
  justify-content: end;
}

.platform {
  :deep(.ant-form-item-label) {
    display: none;
  }

  :deep(.ant-form-item .ant-form-item-label > label.ant-form-item-no-colon::after) {
    display: none;
  }
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.add-button {
  position: absolute;
  right: 70px;
  top: 10px;
}

.positionItem {
  display: inline-block;
  margin: 5px 0;
}

// 通过标签样式
.range {
  display: inline-block;
  box-sizing: border-box;
  height: auto;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 4px 7px;
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 20px;
  white-space: inherit;
  list-style: none;
  border-radius: 2px;
}

.pass-through {
  color: #5ca200 !important;
  background: #f6ffe4 !important;
  border: 1px solid #bad092 !important;
}

.no-pass {
  color: #ff6e03 !important;
  background: #fff5e7 !important;
  border: 1px solid #ffc067 !important;
}

:deep(.ant-modal-title) {
  font-size: 20px;
}

.share-ownership {
  min-width: 1100px;
  margin-top: 50px;
  position: relative;
  display: flex;
  justify-content: center;

  .ownership-table {
    margin-bottom: 30px;
    width: 90%;
  }
}
</style>
