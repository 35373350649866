<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CustomerList',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="平台名称" name="platform_id">
              <a-select mode="multiple" v-model:value="queryParam.platform_id" placeholder="请选择平台">
                <a-select-option v-for="(item, index) in data.platformList" :key="index" :value="item.id">{{ item.platform_abbreviation }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="公司名称" name="nameOrCode">
              <a-input
                @keyup.enter="loadData"
                @change="
                  (v) => {
                    nameOrCodeChange()
                  }
                "
                v-model:value="queryParam.nameOrCode"
                placeholder="请输入公司名称、税号"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="法代姓名" name="fr_name">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.fr_name" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="续费号码" name="renew_first_contact_mobile">
              <a-input
                @keyup.enter="loadData"
                v-model:value="queryParam.renew_first_contact_mobile"
                placeholder="请输入"
                @change="
                  (v) => {
                    phoneChange(v, 'renew_first_contact_mobile', queryParam)
                  }
                "
              >
              </a-input>
            </a-form-item>
          </a-col>

          <template v-if="data.advanced">
            <a-col :md="6" :sm="24">
              <a-form-item label="入驻类型" name="business_type">
                <a-select mode="multiple" v-model:value="queryParam.business_type" placeholder="请选择">
                  <a-select-option :value="1">设立</a-select-option>
                  <a-select-option :value="2">同区迁入</a-select-option>
                  <a-select-option :value="3">跨区迁入</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="主体类型" name="main_type">
                <a-select mode="multiple" v-model:value="queryParam.main_type" placeholder="请选择">
                  <a-select-option :value="1">公司</a-select-option>
                  <a-select-option :value="2">个体</a-select-option>
                  <a-select-option :value="3">个人独资</a-select-option>
                  <a-select-option :value="4">合伙企业</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="进度状态" name="order_status">
                <a-select mode="multiple" :disabled="queryParam.order_status" v-model:value="queryParam.order_status_list" placeholder="请选择" @change="orderStatusChange" @select="orderStatusSelect">
                  <a-select-option v-for="(item, index) in data.progressOptions" :key="index" :value="item.value">{{ item.text }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" v-if="user.username == 'wanduo' || user.username == 'admin'">
              <a-form-item label="代理姓名" name="seller">
                <a-input @keyup.enter="loadData" v-model:value="queryParam.seller" placeholder="请输入"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="付款状态" name="is_pay">
                <a-select mode="multiple" v-model:value="queryParam.is_pay" placeholder="请选择">
                  <a-select-option :value="1">已付款</a-select-option>
                  <a-select-option :value="2">未付款</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="收费类型" name="fee_standard">
                <a-select mode="multiple" v-model:value="queryParam.fee_standard" placeholder="请选择">
                  <a-select-option :value="1">按年收费</a-select-option>
                  <a-select-option :value="2">一次性</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="主体状态" name="exception_flag">
                <a-select mode="multiple" v-model:value="queryParam.exception_flag" placeholder="请选择">
                  <a-select-option :value="1">正常状态</a-select-option>
                  <a-select-option :value="2">待申报地址异常</a-select-option>
                  <a-select-option :value="3">已办理地址异常手续</a-select-option>
                  <a-select-option :value="4">确认地址已进入异常名单客户</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="录单时间" name="between_time">
                <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
              </a-form-item>
            </a-col>
          </template>

          <a-col :md="7" :sm="24">
            <div class="navlist">
              <a-button type="primary" :icon="h(PlusOutlined)" @click="handleAdd">新建客户</a-button>
              <a-button type="primary" @click="onActionClick({}, 'SignatureStatus')" :icon="h(SearchOutlined)">查询客户签字状态</a-button>
              <!-- <a-popover trigger="click" placement="bottom" overlayClassName="customer-options-popover">
                <template #content>
                  <p @click="donwloadClick(1)">通用芙蓉区301开户资料(打印盖章纸质版)</p>
                </template>
                <a-button type="primary">获取开户资料</a-button>
              </a-popover> -->
            </div>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="查询已删除主体" name="recycle">
              <a-switch :checkedValue="1" :unCheckedValue="2" checked-children="是" un-checked-children="否" v-model:checked="queryParam.recycle"></a-switch>
            </a-form-item> </a-col
          ><a-col :md="6" :sm="24">
            <a-form-item label="地址编号" name="address_number">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.address_number" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <div class="table-page-search-submitButtons" style="overflow: hidden; margin-bottom: 0">
              <a-button type="primary" @click="loadData" :icon="h(SearchOutlined)">查询</a-button>
              <a @click="toggleAdvanced" style="margin-left: 8px">
                {{ data.advanced ? '收起' : '展开' }}
                <UpOutlined v-if="data.advanced" />
                <DownOutlined v-else />
              </a>

              <a-popover title="导出参数配置" trigger="click" :overlayStyle="{ width: '60%' }" placement="left" v-model:open="exportDataArgumentChecked.visible">
                <template #content>
                  <a-tabs v-model:activeKey="exportDatatab">
                    <a-tab-pane key="1" tab="根据搜索参数直接导出"
                      ><a-descriptions :column="1">
                        <a-descriptions-item label="导出版本" :contentStyle="{ paddingTop: '10px' }" :labelStyle="{ paddingTop: '10px' }">
                          <a-button @click="selectExportDataArgument(1)">精简版</a-button><a-divider type="vertical" />
                          <a-button @click="selectExportDataArgument(2)">全能版</a-button>
                        </a-descriptions-item>
                        <a-descriptions-item label="导出字段">
                          <a-checkbox-group v-model:value="exportDataArgumentChecked.checkedfieldSimple" name="checkboxgroup" :options="exportDataArgument" />
                        </a-descriptions-item>
                        <a-descriptions-item :contentStyle="{ paddingLeft: '62px' }">
                          <a-button style="margin-left: 8px; display: inline-flex" :icon="h(SvgIcon, { name: 'excle' })" @click="exportClick(1)" :loading="data.exportLoading">导出</a-button>
                        </a-descriptions-item>
                      </a-descriptions></a-tab-pane
                    >
                    <a-tab-pane key="2" tab="根据输入的公司名称导出详细信息（选择此项，搜索条件将失效）"
                      ><a-descriptions :column="1">
                        <a-descriptions-item label="公司名称" :contentStyle="{ paddingTop: '10px' }" :labelStyle="{ paddingTop: '10px' }">
                          <a-textarea v-model:value="exportDataArgumentChecked.companylist" placeholder="请输入公司名称" allow-clear :rows="6" />
                        </a-descriptions-item>
                        <a-descriptions-item label="导出版本" :contentStyle="{ paddingTop: '10px' }" :labelStyle="{ paddingTop: '10px' }">
                          <a-button @click="selectExportDataArgument(1)">精简版</a-button><a-divider type="vertical" />
                          <a-button @click="selectExportDataArgument(2)">全能版</a-button>
                        </a-descriptions-item>
                        <a-descriptions-item label="导出字段">
                          <a-checkbox-group v-model:value="exportDataArgumentChecked.checkedfieldSimple" name="checkboxgroup" :options="exportDataArgument" />
                        </a-descriptions-item>
                        <a-descriptions-item :contentStyle="{ paddingLeft: '62px' }">
                          <a-button style="margin-left: 8px; display: inline-flex" :icon="h(SvgIcon, { name: 'excle' })" @click="exportClick(2)" :loading="data.exportLoading">导出</a-button>
                        </a-descriptions-item>
                      </a-descriptions>
                    </a-tab-pane>
                  </a-tabs>
                </template>
                <a-button style="margin-left: 8px; display: inline-flex" :icon="h(SvgIcon, { name: 'excle' })" :loading="data.exportLoading">导出</a-button>
              </a-popover>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="tabs">
      <a-tabs v-model:activeKey="data.tabsKey" @change="tabsChange">
        <a-tab-pane :key="2" tab="所有客户"> </a-tab-pane>
        <a-tab-pane :key="1" tab="办理中"> </a-tab-pane>
      </a-tabs>
      <div class="tabs-right">
        <div class="pointer" @click="onStisticsClick(1)">
          <a-badge :count="data.customerNavig.unrecorded_electronic" :offset="[5, -5]">
            <span :style="queryParam.order_status == 1 ? { color: 'var(--ant-primary-color)' } : {}"> 未录电子化 </span>
          </a-badge>
        </div>
        <div class="pointer" @click="onStisticsClick(2)">
          <a-badge :count="data.customerNavig.modify_data" :offset="[5, -5]">
            <span :style="queryParam.order_status == 2 ? { color: 'var(--ant-primary-color)' } : {}"> 资料待修改 </span>
          </a-badge>
        </div>
        <div class="pointer" @click="onStisticsClick(3)">
          <a-badge :count="data.customerNavig.to_be_verified" :offset="[5, -5]"> 其他资料核验待审核 </a-badge>
        </div>
        <!-- <div class="pointer" @click="onStisticsClick(4)">
          <a-badge :count="data.customerNavig.filings" :offset="[5, -5]"> 渠道备案核验 </a-badge>
        </div> -->
        <div class="pointer" @click="onStisticsClick(5)">
          <a-badge :count="data.customerNavig.unfinished_work_order" :offset="[5, -5]">
            <span :style="queryParam.unsettled == 1 ? { color: 'var(--ant-primary-color)' } : {}"> 工单未办结 </span>
          </a-badge>
        </div>
        <div class="pointer" @click="onStisticsClick(6)">
          <a-badge :count="data.customerNavig.license_log_count" :offset="[5, -5]"> 营业执照归档 </a-badge>
        </div>
        <div class="pointer" @click="onStisticsClick(7)">
          <a-badge :count="data.customerNavig.customer_chat_count" :offset="[5, -5]"> 微信沟通</a-badge>
        </div>
        <!-- <div class="pointer" @click="onStisticsClick(8)">
          <a-badge :count="data.customerNavig.download_agreement_count" :offset="[5, -5]"
            ><span :style="queryParam.downzc == 1 ? { color: 'var(--ant-primary-color)' } : {}"> 下载章程 </span>
          </a-badge>
        </div> -->
      </div>
    </div>
    <a-table size="small" rowClassName="tableRowClass" :columns="columns" :loading="data.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'companyInformation'">
          <div class="companyInformation" :style="[2, 3, 4, 5, 6].includes(record.status) || record.exception_flag == 4 ? { borderLeft: '10px dashed #000' } : {}">
            <a-tag color="grey" v-if="record.status === 2">该主体已注销</a-tag>
            <a-tag color="grey" v-if="record.status === 3">该主体已吊销</a-tag>
            <a-tag color="grey" v-if="record.status === 4">该主体已迁走</a-tag>
            <a-popconfirm title="确定【撤销已迁走】?" @confirm="cancel_delete_zhuti(record.id, 4)">
              <a v-if="record.status === 4">撤销已迁走</a>
            </a-popconfirm>
            <a-tag color="grey" v-if="record.status === 7">主体迁走审核中...</a-tag>
            <a-popconfirm title="确定【撤销迁走审核】?" @confirm="cancel_delete_zhuti(record.id, 1)">
              <a v-if="record.status === 7">撤销迁走审核</a>
            </a-popconfirm>
            <a-tag color="grey" v-if="record.status === 5">该主体已删除</a-tag>
            <a-popconfirm title="确定【撤销已删除】?" @confirm="cancel_delete_zhuti(record.id, 3)">
              <a v-if="record.status === 5">撤销已删除</a>
            </a-popconfirm>
            <a-tag v-if="record.status === 6" color="grey"> 主体删除审核中...</a-tag>
            <a-popconfirm title="确定【撤销删除审核】?" @confirm="cancel_delete_zhuti(record.id, 2)">
              <a v-if="record.status === 6">撤销删除审核</a>
            </a-popconfirm>
            <a-tag color="black" v-if="record.exception_flag > 1">已解除入驻协议，主体在申报地址异常台账库中</a-tag>
            <a-tag color="red" v-if="record.exception_flag === 2">当前状态：待申报异常</a-tag>
            <a-tag color="red" v-if="record.exception_flag === 3">当前状态：已交申报异常材料</a-tag>
            <a-tag color="black" v-if="record.exception_flag === 4">当前状态：人工确认已地址异常</a-tag>
            <a-tag color="black" v-if="record.exception_flag > 1"><a @click="getExceptionLog(record, 1)">查看异常日志</a></a-tag>
            <div class="mv-1x">
              <a-popover trigger="click" v-model:open="record.isShowCompany" overlayClassName="customer-options-popover">
                <template #content>
                  <p @click="handleDoubleNameClick(record.tyc_url)">跳转到天眼查</p>
                  <p @click="handleDoubleNameClick(record.qcc_url)">跳转到企查查</p>
                </template>
                <div style="width: 100%"></div>
              </a-popover>
              <span
                class="mr-1x pointer"
                :style="record.status === 5 ? { textDecorationLine: 'line-through' } : {}"
                @click="
                  (e) => {
                    record.isShowCompany = false
                    handleNameClick(e, record.full_name)
                  }
                "
                @dblclick="
                  () => {
                    record.isShowCompany = true
                  }
                "
                v-html="record.fullName"
              ></span>
              <a-tag class="pointer" color="#f50" @click="onDirectPaymentClick(record)" v-if="record.is_pay == 2">未支付</a-tag>
              <a-tag color="#f50" v-else-if="record.is_pay == 3">待续费</a-tag>
              <a-tag color="cyan" v-else-if="record.is_pay == 1">已支付√</a-tag>
              <a-tag color="orange" v-else>状态未知</a-tag>
              <a-tag color="green" v-if="record.business_type != 1">迁入</a-tag>
              <a-tag color="green" v-if="record.fee_standard == 2">一次性付费</a-tag>
              <a-tag color="green" v-if="record.registration_type == 2">裸地址</a-tag>
              <a-tag color="green" v-if="record.main_type == 3">个人独资</a-tag>
              <!--   `status` tinyint(1) DEFAULT '0' COMMENT '0待审核 1已审核 2驳回 3撤销', -->
              <a-tag class="pointer" color="#87d068" defaultBg="red" v-if="record.CustomerRefundOrder">
                <a-popover trigger="click" overlayClassName="customer-options-popover">
                  <template #content>
                    退款状态：<span style="color: orange" v-if="record.CustomerRefundOrder?.status == 0">退款待审核</span>
                    <span v-if="record.CustomerRefundOrder?.status == 2">退款已驳回</span>
                    <span v-if="record.CustomerRefundOrder?.status == 3">退款已撤销</span>
                    <span style="color: green" v-if="record.CustomerRefundOrder?.status == 1">退款成功</span>
                    <br />
                    退款编号：{{ record.CustomerRefundOrder?.order_number }}
                    <br />
                    退款理由：{{ record.CustomerRefundOrder?.refund_remark }}
                    <br />
                    申请时间：{{ record.CustomerRefundOrder?.created_at }}
                    <br />
                    <div v-if="record.CustomerRefundOrder?.status == 1">
                      退款时间：{{ record.CustomerRefundOrder?.updated_at }}
                      <br />
                      退款金额：{{ record.CustomerRefundOrder?.reality_refund_amount }}
                    </div>
                  </template>
                  <span>有退款记录</span>
                </a-popover>
              </a-tag>
            </div>
            <div style="margin-bottom: 8px" v-if="record.taglist">
              <a-tag :color="item.color" v-for="(item, index) in record.taglist" :key="index">{{ item.name }}</a-tag>
            </div>
            <div style="font-size: 13px" class="mb-x">
              <span class="pointer" @click="handleCopyClick(record.userPositionList[0].usrename[0])">
                {{ record.is_filiale == 1 ? '负责人' : record.main_type == 1 ? '法人代表姓名' : '负责人' }}：
                {{ record.is_filiale == 1 ? record.userPositionList[0].usrename[0] : record.userPositionList[0].usrename[0] }}
              </span>
              <a-popover
                placement="right"
                :overlayInnerStyle="{
                  padding: '25px'
                }"
              >
                <template #content>
                  <a-watermark :content="'信息确认单 ' + record.seller" :gap="[150, 150]" fontWeight="light">
                    <div style="font-size: 16px; font-weight: 550; border-bottom: 1px dotted #ccc; padding-bottom: 8px">主体名称: {{ record.full_name }}</div>
                    <div style="margin: 6px 0">地址：{{ record.full_address_number }}</div>
                    <div style="margin: 6px 0" class="d-flex" v-if="record.history_names?.length > 0">
                      <div>历史申请：</div>
                      <div>
                        <div v-for="(item, index) in record.history_names" :key="index">
                          {{ item }}
                        </div>
                      </div>
                    </div>
                    <div style="margin: 6px 0" v-if="record.social_credit_code?.length > 0">统一社会信用代码：{{ record.social_credit_code }}</div>
                    <div style="margin: 6px 0">
                      <span v-if="record.verify_time" class="mr-2x">核准日期：{{ record.verify_time.substr(0, 10) }} </span>
                      <span v-if="record.founding_time"> 成立日期：{{ record.founding_time.substr(0, 10) }} </span>
                    </div>
                    <div style="margin: 6px 0">
                      <span class="mr-2x"
                        >入驻时间：{{ record.enter_starttime.substr(0, 10) }} <span class="mv-1x">~</span> {{ record.enter_endtime.substr(0, 10) }}
                        <span v-if="enterTime(record.enter_endtime)" class="red">（{{ enterTime(record.enter_endtime) }}）</span>
                        <a-tag color="green">{{ record.fee_standard == 1 ? '按年续费' : record.fee_standard == 2 ? '一次性付费' : '未知付费方式' }}</a-tag>
                      </span>
                    </div>
                    <div style="margin: 6px 0">
                      <p class="mr-2x">
                        工商预留手机号码：<span style="color: red">{{ record.registration_mobile }}</span>
                      </p>
                    </div>
                    <div style="margin: 6px 0; max-width: 800px">
                      <span class="mr-2x">经营范围：{{ record.business_scope }} </span>
                    </div>
                    <div style="margin: 16px 0">
                      <a-table :columns="shareholderColumns" size="small" rowKey="name" :pagination="false" :data-source="record.shareholder_list">
                        <template #bodyCell="res">
                          <template v-if="res.column.dataIndex == 'name'">
                            <div>{{ res.text }}</div>
                            <div>{{ res.record.id_card_number }}</div>
                            <div>{{ res.record.phone_number }}<a-tag color="green" v-if="res.record.isuseteltogsj == 2">职位处预留手机号码</a-tag></div>
                          </template>
                          <template v-if="res.column.dataIndex == 'address'">
                            <div>{{ res.record.address }}</div>
                            <div v-if="res.record.type == 1">
                              <a-button v-if="res.record.showidcard" @click="res.record.showidcard = 0" style="display: block; padding: 0" type="link">查看身份证照片</a-button>
                            </div>
                            <div v-if="res.record.type == 2">
                              <a-button v-if="res.record.showidcard" @click="res.record.showidcard = 0" style="display: block; padding: 0" type="link">查看营业执照照片</a-button>
                            </div>
                            <div v-if="!res.record.showidcard && res.record.type == 1">
                              <a-image :width="100" :src="$store.state.user.image_domain + res.record.id_card_back"></a-image>
                              <a-image :width="100" :src="$store.state.user.image_domain + res.record.id_card_front"></a-image>
                            </div>
                            <div v-if="!res.record.showidcard && res.record.type == 2">
                              <a-image :width="100" :src="$store.state.user.image_domain + res.record.business_license_url"></a-image>
                            </div>
                          </template>
                          <template v-if="res.column.dataIndex == 'position_name'">
                            <div v-if="record.main_type == 1" style="max-width: 230px">
                              <div v-if="res.text">
                                <span v-for="(item, index) in res.text.split(',')" :key="index">
                                  <a-tag v-if="item?.length > 0" color="cyan">{{ item }}</a-tag>
                                </span>
                              </div>
                              <div v-else>-</div>
                            </div>
                            <div v-else>
                              <a-tag color="cyan">负责人</a-tag>
                            </div>
                          </template>
                          <template v-else-if="res.column.dataIndex == 'investment_amount'">
                            <div v-if="res.record.is_shareholder == 1">{{ res.text }}万元</div>
                            <div v-else>-</div>
                          </template>
                          <template v-else-if="res.column.dataIndex == 'subscribe_endtime'">
                            <div v-if="record.main_type == 1 && res.record.is_shareholder == 1">
                              {{ res.text?.substr(0, 10) }}
                            </div>
                            <div v-else>-</div>
                          </template>
                        </template>
                      </a-table>
                    </div>
                  </a-watermark>
                </template>

                <a type="primary" style="margin-left: 10px">更多信息</a>
              </a-popover>
            </div>
            <div class="mb-x" style="font-size: 12px" v-if="record.history_names?.length > 0">
              <span> 历史申请: </span>
              <div v-for="(item, index) in record.history_names" :key="index">
                {{ item }}
              </div>
            </div>
            <div style="height: 25px; font-size: 13px">
              <div class="deng">
                <a class="mr-1x" :href="`https://hncs.cskaiyela.com/admin/v1/customerAutoLogin?token=${token}&customer_id=${record.id}`" target="_blank"> 登录</a>
                <a-popconfirm title="确定推送?" @confirm="handleTuiSYBJ(record)">
                  <a v-if="record.customer_work_order_status?.after_status_name != '已办结'">推送为已办结</a>
                </a-popconfirm>
                <a v-if="record.signatureStatus == 1" style="float: right" @click="onActionClick(record, 'SignatureStatus')">查看签名状态</a>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="column.dataIndex === 'full_address_number'">
          <div @click="handleCopyClick(text)" class="pointer">
            <div v-if="record.raw_address?.length > 0" style="border-bottom: 1px dashed #ccc">由：{{ record.raw_address }}</div>
            <div><span v-if="record.raw_address?.length > 0">迁到：</span>{{ text }}</div>
          </div>
        </template>
        <template v-else-if="column.dataIndex === 'status'">
          <div v-if="record.customer_work_order_status">
            <p class="mb-x">
              <a-badge color="#87d068" v-if="record.customer_work_order_status.after_status_name == '已办结'" status="success" />
              <a-badge color="#108ee9" v-else status="processing" />{{ record.customer_work_order_status?.after_status_name }}

              <a-popconfirm title="确定推送?" @confirm="handleTuiS(record)">
                <a v-if="record.customer_work_order_status?.after_status_name && record.customer_work_order_status?.after_status_name != '已办结'">推送</a> </a-popconfirm
              ><a-switch
                v-if="[7080, 7081, 7002, 7005, 7009, 7001, 7081, 7082, 4, 1003, 1004, 1005, 1006, 1007, 1008, 1010, 1011, 1013].includes(record.customer_work_order_status.after_status)"
                v-model:checked="record.is_auto_switch"
                checked-children="自动维护状态"
                un-checked-children="不自动维护状态"
                size="small"
                style="margin: 0 0 4px 3px"
                @click="change_order_preparation_mode(record)"
              />
            </p>
            <p class="mb-x">状态改变时间：{{ record.customer_work_order_status?.created_at }}</p>
            <p class="mb-x">上次查询时间：{{ record.customer_work_order_status?.updated_at }}</p>
          </div>
          <div v-if="record.customer_work_order_status?.after_status == 1">
            <a-switch
              v-model:checked="record.is_auto_switch"
              checked-children="资料已确定，资料员可以录单了"
              un-checked-children="还有资料未确定，资料员不要录单"
              style="margin-bottom: 6px"
              @click="change_order_preparation_mode(record)"
              size="small"
            />
          </div>

          <div class="d-flex flex-ac" style="justify-content: space-between">
            <a @click="onViewLogClick(record, 2)">状态</a>
            <a @click="onViewLogClick(record, 3)" :offset="[5, -5]">工单</a>
            <a-badge :count="record.chat_read_count" :offset="[5, -5]"> <a @click="onViewLogClick(record, 4)">微信沟通</a> </a-badge>
            <a @click="onViewLogClick(record, 1)" :offset="[5, -5]">日志</a>
          </div>
        </template>

        <template v-else-if="column.dataIndex === 'seller'">
          <div class="changerole">
            <p class="mb-x">代理商：{{ record.seller ? record.seller : '-' }} <a @click="changerole(record.seller)" v-if="user.id == 1" class="deng">切换身份</a></p>
            <p class="mb-x">
              <a-popover
                placement="left"
                :overlayInnerStyle="{
                  padding: '25px'
                }"
              >
                <template #content>
                  <div style="font-size: 16px; font-weight: 550; border-bottom: 1px dotted #ccc; padding-bottom: 8px">渠道详细情况：</div>
                  <div style="width: 450px; line-height: 26px">
                    <a-row>
                      <a-col :span="10" style="text-align: right">工商预留手机号码：</a-col>
                      <a-col :span="14"
                        >{{ record.registration_mobile }}<a @click="handleCopyClick(record.registration_mobile)" class="ml-1x"><CopyOutlined /></a
                      ></a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">续费联系人手机号码：</a-col>
                      <a-col :span="14">
                        <div v-if="record.isEditXftel">
                          <a-input
                            style="width: 130px; margin-bottom: 5px"
                            v-model:value="record.new_renew_first_contact_mobile"
                            @change="
                              (v) => {
                                phoneChange(v, 'new_renew_first_contact_mobile', record)
                              }
                            "
                            name="new_renew_first_contact_mobile"
                            placeholder="请输入手机号码"
                          ></a-input>
                          <div>
                            <a-button
                              class="mr-2x"
                              @click="
                                () => {
                                  record.isEditXftel = false
                                }
                              "
                              size="small"
                              >取消</a-button
                            >
                            <a-button @click="editPhoneChange(record)" size="small" type="primary">确认</a-button>
                          </div>
                        </div>
                        <span
                          v-else
                          @click="
                            () => {
                              record.new_renew_first_contact_mobile = record.renew_first_contact_mobile
                              record.isEditXftel = true
                            }
                          "
                          class="pointer phone_number"
                          >{{ record.renew_first_contact_mobile || '无' }}
                          <EditTwoTone />
                        </span>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司名称：</a-col>
                      <a-col :span="14">{{ record.finance_company_name }}</a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司统一社会信用代码：</a-col>
                      <a-col :span="14">{{ record.finance_social_credit_code }}</a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司对接人姓名：</a-col>
                      <a-col :span="14">{{ record.finance_contact_name }}</a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司对接人其他号码：</a-col>
                      <a-col :span="14">{{ record.finance_contact_phone }}</a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司对接人微信昵称：</a-col>
                      <a-col :span="14"
                        ><a-avatar size="small" :src="record.xf_person?.headimgurl" v-if="!!record.finance_contact_wechat_nickname">
                          <template #icon><UserOutlined /></template> </a-avatar
                        >{{ record.finance_contact_wechat_nickname }}</a-col
                      >
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司对接人微信号：</a-col>
                      <a-col :span="14"
                        >{{ record.finance_contact_wechat_account
                        }}<a v-if="!!record.finance_contact_wechat_account" @click="handleCopyClick(record.finance_contact_wechat_account)" class="ml-1x"><CopyOutlined /></a
                      ></a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">备注：</a-col>
                      <a-col :span="14">{{ record.remark }}</a-col>
                    </a-row>
                  </div>
                </template>
                <a-avatar size="small" :src="record.xf_person?.headimgurl">
                  <template #icon><UserOutlined /></template>
                </a-avatar>
              </a-popover>

              {{ record.xf_person?.nickname }}
              <span v-if="record.xf_person">
                <a type="primary" style="margin-left: 10px" @click="onViewLogClick(record, 4)"> 发消息 </a>
              </span>
              <span v-else>请续费联系人关注公众号<a-image :width="20" src="https://hncs.cskaiyela.com/wxewm.jpg" /></span>
            </p>
            <span>{{ record.created_at }}</span
            ><a @click="handleCopyClick(record.renew_first_contact_mobile)" class="ml-1x"><CopyOutlined /></a>
          </div>
        </template>

        <template v-else-if="column.dataIndex === 'action'">
          <div class="mb-x">
            <a :data-id="record.id" @click="onPushOrderMakingClick(record)">通知做单</a>
            <a-divider type="vertical" />
            <a-popover
              @openChange="
                (val) => {
                  optionsVisibleChange(val, record)
                }
              "
              trigger="click"
              v-model:open="record.isShowOptions"
              overlayClassName="customer-options-popover"
            >
              <template #content>
                <p @click="onActionClick(record, 'PayCenter')">支付中心</p>
                <a-popconfirm
                  title="确定申报地址异常?"
                  placement="left"
                  @openChange="
                    (e) => {
                      openAnomalyChange(e, record, 1)
                    }
                  "
                  @confirm="listed_in_anomaly(record)"
                >
                  <template #description>
                    <a-form style="width: 600px; margin-top: 30px" v-bind="formLayout">
                      <a-form-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="申报地址异常理由" style="width: 800px" required>
                        <a-input v-model:value="record.anomaly_desc" placeholder="请输入理由" style="width: 320px"> </a-input>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="失联的短信电话截图" style="width: 800px" labelWrap="true">
                        <Upload
                          :upload_path="record.upload_path"
                          :accept="['png', 'jpg', 'jpeg', 'pdf']"
                          uploadType="picture-card"
                          :limitation="99"
                          :customerName="record.full_name"
                          v-model:value="record.anomaly_url"
                          uploadText="上传失联的短信、电话截图"
                        >
                        </Upload>
                      </a-form-item>
                      <a-form-item label="操作" :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" style="width: 800px" required>
                        <a-radio-group v-model:value="record.doaction_type">
                          <a-radio :value="1"> 直接列入 </a-radio>
                          <a-radio :value="2"> 仅下载列入异常申报资料 </a-radio>
                        </a-radio-group>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="备注" style="width: 800px">
                        <a-input v-model:value="record.anomaly_remark" placeholder="请输入备注（可填）" style="width: 320px"> </a-input>
                      </a-form-item>
                      <a-form-item style="width: 800px"> 提示：申报地址异常后还需要递交资料才可以正式列入，请知悉！确定后将自动生成列入异常模板，也可前往档案管理里面再次下载！ </a-form-item>
                    </a-form>
                  </template>
                  <p>申报地址异常</p>
                </a-popconfirm>

                <!-- <a-popconfirm
                  title="确定录入?"
                  placement="left"
                  @openChange="
                    (e) => {
                      openShouru(e, record)
                    }
                  "
                  @confirm="handleShouru(record)"
                >
                  <template #description>
                    <a-form style="width: 600px" v-bind="formLayout">
                      <a-form-item label="收入事项">
                        <a-input v-model:value="revenue.income_event_text" placeholder="请输入收入事项"> </a-input>
                      </a-form-item>
                      <a-form-item label="收入">
                        <a-input type="number" addon-after="元" v-model:value="revenue.income" placeholder="请输入收入"> </a-input>
                      </a-form-item>
                      <a-form-item label="销售员">
                        <a-input v-model:value="revenue.salesman" placeholder="请输入销售员"> </a-input>
                      </a-form-item>
                      <a-form-item label="收款人">
                        <a-input v-model:value="revenue.payee" placeholder="请输入收款人"> </a-input>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="相关附件(选填)">
                        <Upload :upload_path="record.upload_path" :accept="['png', 'jpg', 'jpeg', 'pdf']" uploadType="text" :limitation="1" v-model:value="revenue.annex_url" uploadText="上传附件">
                        </Upload>
                      </a-form-item>
                    </a-form>
                  </template>
                  <p>录入收入</p>
                </a-popconfirm> -->
                <p @click="onActionClick(record, 'Refund')">退款</p>
                <a-popconfirm
                  title="属性调整控制台"
                  placement="left"
                  @openChange="
                    (e) => {
                      openAttrChange(e, record)
                    }
                  "
                  @confirm="submitAttrAdjust(record)"
                >
                  <template #description>
                    <a-form style="width: 600px; margin-top: 30px" v-bind="formLayout" ref="attrformRef">
                      <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="属性名称" required>
                        <a-select style="width: 250px" :getPopupContainer="(triggerNode) => triggerNode.parentNode" placeholder="请选择待调整的属性名称" v-model:value="customer_attr.name" required>
                          <a-select-option value="fee_standard"> 收费标准 </a-select-option>
                          <a-select-option value="next_year_price"> 续费价格 </a-select-option>
                          <a-select-option value="enter_starttime"> 入驻开始日期 </a-select-option>
                          <a-select-option value="enter_endtime"> 入驻到期日期 </a-select-option>
                          <a-select-option value="founding_time"> 成立日期 </a-select-option>
                          <a-select-option value="verify_time"> 核准日期 </a-select-option>
                          <a-select-option value="yifang_name"> 租房合同乙方姓名 </a-select-option>
                        </a-select>
                      </a-form-item>
                      <div v-if="customer_attr.name == 'fee_standard'">
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整前值" required>
                          <a-radio-group v-model:value="customer_attr.old_fee_standard" button-style="solid">
                            <a-radio-button :value="1" v-if="record.fee_standard == 1">按年收费</a-radio-button>
                            <a-radio-button :value="2" v-if="record.fee_standard == 2">一次性收费</a-radio-button>
                          </a-radio-group>
                        </a-form-item>
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整后值" required>
                          <a-radio-group v-model:value="customer_attr.fee_standard" button-style="solid">
                            <a-radio-button :value="1" v-if="record.fee_standard == 2">按年收费</a-radio-button>
                            <a-radio-button :value="2" v-if="record.fee_standard == 1">一次性收费</a-radio-button>
                          </a-radio-group>
                        </a-form-item>
                      </div>
                      <div v-else-if="customer_attr.name == 'next_year_price'">
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整前值" required> ￥{{ record.next_year_price }} </a-form-item>
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整后值" required>
                          <a-input v-model:value="customer_attr.next_year_price" prefix="￥" suffix="RMB" style="width: 250px" placeholder="请输入续费价格"></a-input>
                        </a-form-item>
                      </div>
                      <div v-else-if="customer_attr.name == 'enter_starttime'">
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整前值" required> {{ record.enter_starttime }} </a-form-item>
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整后值" required>
                          <a-date-picker v-model:value="customer_attr.enter_starttime" placeholder="选择入驻开始日期" :popupStyle="{ 'z-index': 9999 }"></a-date-picker>
                        </a-form-item>
                      </div>

                      <div v-else-if="customer_attr.name == 'enter_endtime'">
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整前值" required> {{ record.enter_endtime }} </a-form-item>
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整后值" required>
                          <a-date-picker v-model:value="customer_attr.enter_endtime" placeholder="选择入驻结束日期" :popupStyle="{ 'z-index': 9999 }"></a-date-picker>
                        </a-form-item>
                      </div>
                      <div v-else-if="customer_attr.name == 'founding_time'">
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整前值" required> {{ record.founding_time }} </a-form-item>
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整后值" required>
                          <a-date-picker v-model:value="customer_attr.founding_time" placeholder="选择成立日期" :popupStyle="{ 'z-index': 9999 }"></a-date-picker>
                        </a-form-item>
                      </div>
                      <div v-else-if="customer_attr.name == 'verify_time'">
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整前值" required> {{ record.verify_time }} </a-form-item>
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整后值" required>
                          <a-date-picker v-model:value="customer_attr.verify_time" placeholder="选择核准日期" :popupStyle="{ 'z-index': 9999 }"></a-date-picker>
                        </a-form-item>
                      </div>
                      <div v-else-if="customer_attr.name == 'yifang_name'">
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整前值" required> {{ record.yifang_name }} </a-form-item>
                        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整后值" required>
                          <a-input v-model:value="customer_attr.yifang_name" style="width: 250px" placeholder="请输入乙方名称"></a-input>
                        </a-form-item>
                      </div>

                      <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="调整理由" required name="remark">
                        <a-input v-model:value="customer_attr.remark" style="width: 250px" placeholder="调整理由"></a-input>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="相关附件(选填)" name="template_url">
                        <Upload
                          upload_path="public/uploads/调整属性相关附件"
                          uploadType="text"
                          :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
                          :limitation="1"
                          v-model:value="customer_attr.annex_url"
                          uploadText="上传附件"
                        >
                        </Upload>
                      </a-form-item>
                    </a-form>
                  </template>
                  <p>属性调整</p>
                </a-popconfirm>

                <!-- <p v-if="record.is_pay != 2 && record.fee_standard != 2" @click="onActionClick(record, 'PayCenter')">地址续费</p> -->
                <p @click="onPushPaymentClick(record)">推送至微信收款</p>
                <!-- <p v-if="!record.first_pay_time" @click="onDirectPaymentClick(record)">支付第一次入驻服务费</p> -->
                <a-popover trigger="click" overlayClassName="customer-options-popover">
                  <template #content>
                    <p @click="onTaxInquiryClick(record, 1)">税务基础情况查询</p>
                    <p @click="onTaxInquiryClick(record, 2)">一般纳税人查询</p>
                    <p @click="onTaxInquiryClick(record, 3)">税务登记基本信息查询</p>
                  </template>
                  <p>税务查询</p>
                </a-popover>
                <p @click="onActionClick(record, 'AddTable')">标签管理</p>
                <p @click="onActionClick(record, 'SFMail')">顺丰邮寄</p>
                <p @click="onPlatformClick(record)">平台充值</p>
                <a-popconfirm
                  title="确定删除?"
                  placement="left"
                  @openChange="
                    (e) => {
                      openDeleteChange(e, record)
                    }
                  "
                  @confirm="handleDelete(record)"
                >
                  <template #description>
                    <a-form style="width: 600px" v-bind="formLayout">
                      <a-form-item label="删除原因" required>
                        <a-radio-group v-model:value="record.deleteType">
                          <a-radio :value="1">废弃</a-radio>
                          <a-radio :value="2">该主体已注销</a-radio>
                          <a-radio :value="3">该主体已迁出</a-radio>
                        </a-radio-group>
                      </a-form-item>
                      <a-form-item label="理由" required v-if="record.deleteType == 1">
                        <a-input v-model:value="record.desc" placeholder="请输入理由"> </a-input>
                      </a-form-item>
                      <a-form-item label="理由" v-else>
                        <a-input v-model:value="record.desc" placeholder="请输入理由"> </a-input>
                      </a-form-item>
                      <a-form-item label="备注"> 删除后可在主体回收站中撤回删除 </a-form-item>
                    </a-form>
                  </template>
                  <p>删除</p>
                </a-popconfirm>
                <p @click="onCopyClick(record)">复制</p>
                <a-popconfirm
                  title="确定锁定不列入异常?"
                  placement="left"
                  @openChange="
                    (e) => {
                      openLockExceptionChange(e, record)
                    }
                  "
                  @confirm="submitLockException(record)"
                >
                  <template #description>
                    <a-form style="width: 600px; margin-top: 30px" v-bind="formLayout">
                      <a-form-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="锁定理由" style="width: 800px" required>
                        <a-input v-model:value="lockException.reason" placeholder="请输入理由" style="width: 320px"> </a-input>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="锁定起止时间" style="width: 800px" required>
                        <div class="d-flex">
                          <a-range-picker
                            :show-time="{ defaultValue: arrayMonent }"
                            :popupStyle="{ 'z-index': 9999 }"
                            :value="lockRangTime"
                            @change="lockRangTimeChange"
                            :format="format"
                          ></a-range-picker>
                        </div>
                      </a-form-item>

                      <a-form-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="锁定理由图片" style="width: 800px" labelWrap="true">
                        <Upload
                          :upload_path="record.upload_path"
                          :accept="['png', 'jpg', 'jpeg', 'pdf']"
                          uploadType="picture-card"
                          :limitation="99"
                          :customerName="record.full_name"
                          v-model:value="lockException.url"
                          uploadText="上传锁定理由图片"
                        >
                        </Upload>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="备注" style="width: 800px">
                        <a-input v-model:value="lockException.remark" placeholder="请输入备注（可填）" style="width: 320px"> </a-input>
                      </a-form-item>
                      <a-form-item style="width: 800px"> 提示：锁定后无法列入异常。 </a-form-item>
                    </a-form>
                  </template>
                  <p>申请锁定不列异常</p>
                </a-popconfirm>
              </template>
              <a
                @click="
                  () => {
                    record.isShowOptions = true
                  }
                "
                >更多操作</a
              >
            </a-popover>
          </div>
          <a @click="onActionClick(record, 'Record')">档案管理</a>
          <a-divider type="vertical" />
          <a-popover trigger="click" overlayClassName="customer-options-popover">
            <template #content>
              <div v-for="(item, index) in record.DownType" :key="index">
                <a-popover trigger="click" overlayClassName="customer-options-popover" v-if="item.children?.length > 0" placement="left">
                  <template #content>
                    <div v-for="(downLeaseItem, downLeaseIndex) in item.children" :key="downLeaseIndex" @click="downLease(downLeaseItem)">
                      <div v-if="downLeaseItem.children?.length > 0">
                        {{ downLeaseItem.name }}
                      </div>
                      <p v-else>
                        {{ downLeaseItem.name }}
                      </p>
                      <div v-if="downLeaseItem.children?.length > 0">
                        <p v-for="(downItem, downIndex) in downLeaseItem.children" :key="downIndex" @click="downLease(downItem)">
                          {{ downItem.name }}
                        </p>
                      </div>
                    </div>
                  </template>
                  <p>{{ item.name }}</p>
                </a-popover>
                <a-popconfirm
                  v-else-if="item.id == 4"
                  title="租赁合同"
                  placement="left"
                  @openChange="
                    (e) => {
                      openLeaseChange(e, record)
                    }
                  "
                  @confirm="downLeaseLease(record, item)"
                >
                  <template #description>
                    <a-form style="width: 400px; margin-top: 30px" v-bind="formLayout">
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="是否盖公章" required>
                        <a-radio-group v-model:value="leaseData.gz" @change="changeLeaseGz">
                          <a-radio :value="1"> 有公章 </a-radio>
                          <a-radio :value="2"> 无公章 </a-radio>
                        </a-radio-group>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="是否签字" required>
                        <a-radio-group v-model:value="leaseData.qz">
                          <a-radio :value="1" v-if="leaseData.gz == 1"> 有签字 </a-radio>
                          <a-radio :value="2"> 无签字 </a-radio>
                        </a-radio-group>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="合同租金是否遮挡" required>
                        <a-radio-group v-model:value="leaseData.zd">
                          <a-radio :value="1"> 遮挡 </a-radio>
                          <a-radio :value="2"> 未遮挡 </a-radio>
                        </a-radio-group>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="是否显示电话" required>
                        <a-radio-group v-model:value="leaseData.tel">
                          <a-radio :value="2"> 不显示 </a-radio>
                          <a-radio :value="1"> 显示 </a-radio>
                        </a-radio-group>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="租金" v-if="user.nickname == '丁朋' || user.nickname == '管理员'" required>
                        <a-input v-model:value="leaseData.zujin" placeholder="请输入合同价格" style="width: 150px" prefix="￥" suffix="RMB"> </a-input>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="租期" required>
                        <a-select style="width: 250px" :getPopupContainer="(triggerNode) => triggerNode.parentNode" placeholder="请选择租期" v-model:value="leaseData.zq" required>
                          <a-select-option :value="0"> {{ record.enter_starttime }}~{{ record.enter_endtime }} </a-select-option>
                          <a-select-option :value="1">
                            {{ dayjs(record.enter_starttime).add(1, 'year').format('YYYY-MM-DD') }}~{{ dayjs(record.enter_endtime).add(1, 'year').format('YYYY-MM-DD') }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="理由" required>
                        <a-select style="width: 250px" :getPopupContainer="(triggerNode) => triggerNode.parentNode" placeholder="请选择理由" v-model:value="leaseData.reason" required>
                          <a-select-option value="仅供银行开户使用"> 仅供银行开户使用 </a-select-option>
                          <a-select-option value="其他"> 其他 </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="合同签订日期" required>
                        <a-date-picker v-model:value="leaseData.hetong_sign_date" style="width: 250px" placeholder="请选择合同签订日期" :popupStyle="{ 'z-index': 9999 }" />
                      </a-form-item>
                    </a-form>
                  </template>
                  <p>{{ item.name }}</p>
                </a-popconfirm>
                <a-popconfirm
                  title="催款告知书参数配置"
                  placement="left"
                  @openChange="
                    (e) => {
                      openNextYearChange(e, record)
                    }
                  "
                  @confirm="downLeaseNextYear(record, item)"
                  v-else-if="item.id == 5"
                >
                  <template #description>
                    <a-form style="width: 400px; margin-top: 30px" v-bind="formLayout">
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="渠道联价格" style="width: 300px" required>
                        <a-input v-model:value="record.read_next_year_price1" placeholder="请输入渠道联价格" style="width: 150px" prefix="￥" suffix="RMB"> </a-input>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="客户联价格" style="width: 300px" required>
                        <a-input v-model:value="record.read_next_year_price2" placeholder="请输入客户联价格" style="width: 150px" prefix="￥" suffix="RMB"> </a-input>
                      </a-form-item>
                    </a-form>
                  </template>
                  <p>{{ item.name }}</p>
                </a-popconfirm>
                <a-popconfirm
                  title="确定申报地址异常?"
                  placement="left"
                  @openChange="
                    (e) => {
                      openAnomalyChange(e, record, 2)
                    }
                  "
                  @confirm="listed_in_anomaly(record)"
                  v-else-if="item.id == 11"
                >
                  <template #description>
                    <a-form style="width: 600px; margin-top: 30px" v-bind="formLayout">
                      <a-form-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="申报地址异常理由" style="width: 800px" required>
                        <a-input v-model:value="record.anomaly_desc" placeholder="请输入理由" style="width: 320px"> </a-input>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="失联的短信电话截图" style="width: 800px" labelWrap="true">
                        <Upload
                          :upload_path="record.upload_path"
                          :accept="['png', 'jpg', 'jpeg', 'pdf']"
                          uploadType="picture-card"
                          :limitation="99"
                          :customerName="record.full_name"
                          v-model:value="record.anomaly_url"
                          uploadText="上传失联的短信、电话截图"
                        >
                        </Upload>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="备注" style="width: 800px">
                        <a-input v-model:value="record.anomaly_remark" placeholder="请输入备注（可填）" style="width: 320px"> </a-input>
                      </a-form-item>
                      <a-form-item style="width: 800px"> 提示：申报地址异常后还需要递交资料才可以正式列入，请知悉！确定后将自动生成列入异常模板，也可前往档案管理里面再次下载！ </a-form-item>
                    </a-form>
                  </template>
                  <p>列入异常资料</p>
                </a-popconfirm>
                <a-popconfirm
                  title="案件线索移送函参数配置"
                  placement="left"
                  @openChange="
                    (e) => {
                      openClueChange(e, record)
                    }
                  "
                  @confirm="downLeaseClue(record, item)"
                  v-else-if="item.id == 12"
                >
                  <template #description>
                    <a-form style="width: 500px; margin-top: 30px" v-bind="formLayout">
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="投诉举报来源" required>
                        <a-select style="width: 250px" :getPopupContainer="(triggerNode) => triggerNode.parentNode" placeholder="请选择投诉举报来源" v-model:value="yisongdata.tsjb_from" required>
                          <a-select-option value="1"> 湖南市场监管投诉举报平台工单 </a-select-option>
                          <a-select-option value="2"> 长沙市12345政务热线工单 </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="店铺名称" required>
                        <a-input v-model:value="yisongdata.dp_name" style="width: 250px" placeholder="请输入店铺名称"></a-input>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="销售的产品名称" required>
                        <a-input v-model:value="yisongdata.dp_sell_productname" style="width: 250px" placeholder="请输入销售的产品名称"></a-input>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="被投诉产品的订单号" required>
                        <a-input v-model:value="yisongdata.dp_sell_productid" style="width: 250px" placeholder="请输入被投诉产品的订单号"></a-input>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="违法违规情况" required>
                        <a-select
                          style="width: 250px"
                          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                          placeholder="请选择违规情况"
                          v-model:value="yisongdata.dp_sell_product_wfwgqk"
                          required
                        >
                          <a-select-option value="1"> 涉嫌食品安全违法行为 </a-select-option>
                          <a-select-option value="2"> 不符合国家强制性标准 </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="移送平台" required>
                        <a-select
                          @change="ysptchange"
                          style="width: 250px"
                          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                          placeholder="请选择移送平台"
                          v-model:value="yisongdata.dp_yspt"
                          required
                        >
                          <a-select-option value="阿里巴巴"> 阿里巴巴 </a-select-option>
                          <a-select-option value="淘宝"> 淘宝 </a-select-option>
                          <a-select-option value="抖音"> 抖音 </a-select-option>
                          <a-select-option value="拼多多"> 拼多多 </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="移送至市监所名称" required>
                        <a-input v-model:value="yisongdata.yssjs_name" style="width: 250px" placeholder="移送至市监所名称"></a-input>
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="接到投诉的日期" required>
                        <a-date-picker v-model:value="yisongdata.date_jdts" style="width: 250px" placeholder="请输入接到投诉的日期" :popupStyle="{ 'z-index': 9999 }" />
                      </a-form-item>
                      <a-form-item :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" label="监管部门上门核查日期" required>
                        <a-date-picker v-model:value="yisongdata.date_smhc" style="width: 250px" placeholder="请输入监管部门上门核查日期" :popupStyle="{ 'z-index': 9999 }" />
                      </a-form-item>
                    </a-form>
                  </template>
                  <p>{{ item.name }}</p>
                </a-popconfirm>
                <p v-else @click="downLease(item)">{{ item.name }}</p>
              </div>
            </template>
            <a>下载</a>
          </a-popover>
          <a-divider type="vertical" />
          <a @click="handleEdit(record)">编辑</a>
        </template>
      </template>
    </a-table>
    <!-- 新增客户 -->
    <create-form ref="createForm" :key="data.createKey" :open="data.visible" @cancel="handleCancel" @ok="handleOk" />
    <edit-form v-if="data.editVisible" :open="true" :tableColumnsData="data.tableColumnsData" @cancel="handleCancel" @ok="handleOk" />
    <CopyForm v-if="data.copyVisible" :open="true" :tableColumnsData="data.tableColumnsData" @cancel="handleCancel" @ok="handleOk" />
    <Refund v-if="data.refundVisible" :tableColumnsData="data.tableColumnsData" @cancel="handleCancel" @ok="handleOk" />

    <!-- 查看日志 -->
    <view-log :logTabsKey="data.logTabsKey" v-if="data.visibleLog" :tableColumnsData="data.tableColumnsData" @cancel="viewLogCancel"></view-log>
    <!-- 直接付款 -->
    <direct-payment v-if="data.directPaymentVisible" @cancel="handleCancel" @ok="handleOk" :tableColumnsData="data.tableColumnsData"></direct-payment>

    <PlatformPayment v-if="data.platformVisible" @cancel="handleCancel" @ok="handleOk" :tableColumnsData="data.tableColumnsData"></PlatformPayment>

    <!-- 推送做单 -->
    <push-order-making :tableColumnsData="data.tableColumnsData" v-if="data.pushOrderMakingVisible" @cancel="handleCancel" @ok="handleOk"></push-order-making>

    <!-- 查看签名状态 -->
    <SignatureStatus
      :tableColumnsData="data.tableColumnsData"
      v-if="data.signatureStatusVisible"
      @cancel="
        () => {
          data.signatureStatusVisible = false
        }
      "
    ></SignatureStatus>

    <s-f-mail :tableColumnsData="data.tableColumnsData" v-if="data.SFMailVisible" @cancel="handleCancel" @ok="handleOk"></s-f-mail>
    <PayCenter :tableColumnsData="data.tableColumnsData" v-if="data.addressRenewVisible" @cancel="handleCancel" @ok="handleOk"></PayCenter>
    <AddLabel
      :tableColumnsData="data.tableColumnsData"
      v-if="data.addTableVisible"
      @cancel="
        () => {
          data.addTableVisible = false
        }
      "
    ></AddLabel>
    <Record
      :tableColumnsData="data.tableColumnsData"
      v-if="data.recordVisible"
      @cancel="
        () => {
          data.recordVisible = false
        }
      "
    ></Record>
    <!--
        <entry-agreement :tableColumnsData="data.tableColumnsData" v-if="data.entryAgreementVisible" @cancel="() => {
            data.entryAgreementVisible = false
        }
            "></entry-agreement> -->
  </a-card>
</template>

<script lang="ts" setup name="CustomerList">
import { UserOutlined, SearchOutlined } from '@ant-design/icons-vue'
import SvgIcon from '@/components/SvgIcon/index.vue'
import { PlusOutlined, DownOutlined, UpOutlined, CopyOutlined, EditTwoTone } from '@ant-design/icons-vue'
import type { Dayjs } from 'dayjs'
import type { PaginationProps } from 'ant-design-vue'
import CreateForm from './CreateForm.vue'
import CopyForm from './CreateForm.vue'
import EditForm from './EditForm.vue'
import Refund from './components/Refund.vue'
import Record from './components/Record.vue'
import Upload from '@/components/base/Upload.vue'
import ViewLog from './components/ViewLog.vue'
import PushOrderMaking from './components/PushOrderMaking.vue'
import SignatureStatus from './components/SignatureStatus.vue'
import PayCenter from './components/PayCenter.vue'
import DirectPayment from './components/DirectPayment.vue'
import PlatformPayment from './components/PlatformPayment.vue'
import SFMail from './components/SFMail.vue'
import AddLabel from './components/addLabel.vue'
import type { Rule } from 'ant-design-vue/es/form'
import { useRouter } from 'vue-router'
import { message, Modal, Table } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { unref, ref, reactive, h, onMounted, computed, onActivated, customRef } from 'vue'
import dayjs from 'dayjs'
import ls from '@/utils/Storage'
import { mobileRequiredValidator } from '@/utils/util'
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const token = ls.get('ACCESS_TOKEN').replace('bearer ', '')
import { useStore } from 'vuex'
import { loginSuccess, requestFailed } from './../user/helper'
const $store = useStore()
const user = ls.get('USER_INFO')
const router = useRouter()
const format = 'YYYY-MM-DD HH:mm:ss'
const shareholderColumns: Object[] = [
  {
    title: '姓名、身份标识、手机号码',
    dataIndex: 'name'
  },
  {
    title: '照片',
    dataIndex: 'address',
    width: 200
  },
  {
    title: '职位',
    dataIndex: 'position_name'
  },
  {
    title: '出资金额',
    dataIndex: 'investment_amount'
  },
  {
    title: '认缴出资日期',
    dataIndex: 'subscribe_endtime'
  }
]

interface data {
  key: string | number
  rowSelection: number[]
  customerNavig: any
  entryAgreementVisible: boolean
  recordVisible: boolean
  addTableVisible: boolean
  signatureStatusVisible: boolean
  directPaymentVisible: boolean
  platformVisible: boolean
  pushPaymentVisible: boolean
  pushOrderMakingVisible: boolean
  SFMailVisible: boolean
  addressRenewVisible: boolean
  visibleLog: boolean
  visibleStatus: boolean
  optionsVisible: boolean
  visible: boolean
  advanced: boolean
  tableColumnsData: Object
  loading: boolean
  editVisible: boolean
  copyVisible: boolean
  refundVisible: boolean
  exportLoading: boolean
  logTabsKey: number
  createKey: number
  platformList: any[]
  downList: any
  customerInfo: any
  progressOptions: any[]
  tabsKey: number
  chat_read_count: number
}
const data = reactive<data>({
  key: '',
  rowSelection: [],
  customerNavig: {},
  exportLoading: false,
  // 入驻协议
  entryAgreementVisible: false,
  // 档案管理
  recordVisible: false,
  // 打标签
  addTableVisible: false,
  // 查看签名状态
  signatureStatusVisible: false,
  // 付款弹窗
  directPaymentVisible: false,
  platformVisible: false,
  // 推送付款
  pushPaymentVisible: false,
  // 推送做单
  pushOrderMakingVisible: false,
  // 顺丰邮寄
  SFMailVisible: false,
  //   地址续费
  addressRenewVisible: false,
  visibleLog: false,
  visibleStatus: false,
  // 操作弹窗
  optionsVisible: false,
  // create model
  visible: false,
  // 高级搜索 展开/关闭
  advanced: false,
  tableColumnsData: {},
  loading: false,
  // 编辑
  editVisible: false,
  copyVisible: false,
  refundVisible: false,
  // 进入日志 还是进入状态
  logTabsKey: 1,
  createKey: 1,
  platformList: [],
  /* 下载列表 */
  downList: {},
  //数据列表
  customerInfo: {},
  // 进度状态
  progressOptions: [],
  tabsKey: 2,
  chat_read_count: 0
})
const yisongdata = reactive({
  curent_id: 0,
  tsjb_from: '1',
  dp_name: '',
  dp_sell_productname: '',
  dp_sell_productid: '',
  dp_sell_product_wfwgqk: '',
  dp_yspt: '',
  yssjs_name: '',
  date_jdts: '',
  date_smhc: ''
})
const nameOrCodeChange = () => {
  //将(替换成（
  queryParam.nameOrCode = queryParam.nameOrCode.replace(/\(/g, '（')
  //将)替换成）
  queryParam.nameOrCode = queryParam.nameOrCode.replace(/\)/g, '）')
}
const ysptchange = () => {
  yisongdata.yssjs_name =
    yisongdata.dp_yspt == '阿里巴巴'
      ? '杭州市高新区（滨江）市场监督管理局'
      : yisongdata.dp_yspt == '淘宝'
      ? '杭州市余杭区市场监督管理局'
      : yisongdata.dp_yspt == '抖音'
      ? '上海市杨浦区市场监督管理局'
      : yisongdata.dp_yspt == '拼多多'
      ? '长宁区市场监督管理局'
      : ''
}

const filteredInfo = ref()
const createForm = ref()

const columns = computed(() => {
  const filtered = filteredInfo.value || []
  const progressOptions = data.progressOptions
  return [
    {
      title: '公司名称',
      dataIndex: 'companyInformation',
      width: 350
    },
    {
      title: '当前状态',
      dataIndex: 'status',
      filters: progressOptions,
      filteredValue: filtered || null,
      width: 220
    },
    {
      title: '地址',
      dataIndex: 'full_address_number',
      width: 220
    },
    {
      title: '其他信息',
      dataIndex: 'seller',
      width: 240
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 200
    }
  ]
})

const formLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  }
}
let queryParam = reactive<any>({
  order_status_list: []
})
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '40', '50', '60', '200'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  platformList()
  customerNavig()
  let changerole_search = localStorage.getItem('changerole_search')
  if (changerole_search) {
    queryParam.nameOrCode = changerole_search
    localStorage.removeItem('changerole_search')
  }
  loadData()
})
onActivated(() => {
  if (router.currentRoute?.value?.query?.customer_name) {
    queryParam.nameOrCode = router.currentRoute.value.query.customer_name
    loadData()
  }
})
// 跳转天眼查
const handleDoubleNameClick = (url) => {
  window.open(url)
}
// 时间选择
const rangTime = computed(() => {
  let array: any = []
  if (queryParam.between_time) {
    array = queryParam.between_time.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
// 时间选择
const rangTimeChange = (val, str) => {
  if (val) {
    queryParam.between_time = str.join(',')
  } else {
    queryParam.between_time = null
  }
}

// 复制
const handleCopyClick = (val) => {
  var input = document.createElement('input')
  //将input的值设置为需要复制的内容
  input.value = val
  //添加input标签
  document.body.appendChild(input)
  //选中input标签
  input.select()
  //执行复制
  document.execCommand('copy')
  //成功提示信息
  message.success('复制成功!')
  //移除input标签
  document.body.removeChild(input)
}
// 复制名称
const handleNameClick = (e, name) => {
  if (e.detail == 1) {
    var input = document.createElement('input')
    //将input的值设置为需要复制的内容
    input.value = name
    //添加input标签
    document.body.appendChild(input)
    //选中input标签
    input.select()
    //执行复制
    document.execCommand('copy')
    //成功提示信息
    message.success('复制成功，尝试双击有惊喜！')
    //移除input标签
    document.body.removeChild(input)
  }
}
const handleTuiS = (record) => {
  const hide = message.loading({
    content: '推送中,请稍等',
    duration: 0,
    class: 'loading-message'
  })
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/pushWechatMessage?customer_id=' + record.id,
    method: 'get'
  }
  baseService(requestParam)
    .then((res: any) => {
      setTimeout(hide, 500)
      message.success(res.msg ? res.msg : '推送成功')
    })
    .catch((res) => {
      setTimeout(hide, 500)
      message.error(res.msg ? res.msg : '推送失败')
    })
}
const phoneChange = (v, type, record) => {
  let str = v.target.value.replaceAll(/\D/g, '')
  record[type] = str
}
//该表做单快捷备注
const change_order_preparation_mode = (record) => {
  record.is_auto = record.is_auto == 1 ? 2 : 1
  let requestParam = {
    url: '/admin/v1/change_order_preparation_mode',
    method: 'post',
    data: {
      customer_id: record.id
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg ? res.msg : '推送成功')
    })
    .catch((res) => {
      message.error(res.msg ? res.msg : '推送失败')
    })
}

const handleTuiSYBJ = (record) => {
  const hide = message.loading({
    content: '推送中,请稍等',
    duration: 0,
    class: 'loading-message'
  })
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/addWorkOrderLogbj',
    method: 'post',
    data: {
      customer_id: record.id
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      setTimeout(hide, 500)
      loadData()
      message.success(res.msg ? res.msg : '推送成功')
    })
    .catch((res) => {
      setTimeout(hide, 500)
      message.error(res.msg ? res.msg : '推送失败')
    })
}

const cancel_delete_zhuti = (id, type) => {
  const hide = message.loading({
    content: '操作中,请稍等...',
    duration: 0,
    class: 'loading-message'
  })
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customer/cancel_delete_zhuti',
    method: 'post',
    data: {
      customer_id: id,
      type: type
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      setTimeout(hide, 500)
      message.success(res.msg ? res.msg : '操作成功')
      loadData()
    })
    .catch((res) => {
      setTimeout(hide, 500)
      message.error(res.msg ? res.msg : '操作失败')
    })
}

// 进度状态选择
const tabsChange = (val) => {
  if (val == 1) {
    queryParam.order_status_list = ['blz']
    filteredInfo.value = ['blz']
    //如果登录的用户id为1
    if (user.username == 'admin') {
      queryParam.seller = '丁朋'
    }
    loadData()
  } else {
    filteredInfo.value = []
    refreshTabel()
  }
}
// 进度状态选择
const orderStatusChange = (val) => {
  if (!val || val.length === 0) {
    data.tabsKey = 2
  }
}
// 进度状态选择
const orderStatusSelect = (val) => {
  if (val == 'blz') {
    queryParam.order_status_list = ['blz']
    data.tabsKey = 1
  } else {
    data.tabsKey = 2
    queryParam.order_status_list = queryParam.order_status_list.filter((item) => item != 'blz')
  }
  filteredInfo.value = queryParam.order_status_list
}
// 重置刷新
const refreshTabel = () => {
  pagination.current = 1
  filteredInfo.value = []
  data.tabsKey = 2
  let obj = Object.keys(queryParam).reduce((a, key) => ({ ...a, [key]: undefined }), {})
  Object.assign(queryParam, obj)
  loadData()
}
// 统计状态切换
const onStisticsClick = (val) => {
  if (queryParam.order_status == val) {
    queryParam.order_status = undefined
    loadData()
    return
  }
  if (val == 3) {
    if (user.username == 'admin') {
      router.push({ path: '/verification' })
    } else {
      message.warn('您没有权限访问资料审核')
    }
  } else if (val == 5) {
    router.push({ path: '/workOrder' })
  } else if (val == 6) {
    router.push({ path: '/customer/pigeonhole' })
  } else if (val == 8) {
    queryParam.downzc = 1
    queryParam.order_status = undefined
    queryParam.order_status_list = undefined
    loadData()
  } else {
    queryParam.order_status = val
    queryParam.order_status_list = undefined
    queryParam.downzc = undefined
    loadData()
  }
}
const customer_attr = reactive({
  name: '',
  old_fee_standard: 1,
  fee_standard: 2,
  next_year_price: '',
  remark: '',
  annex_url: [],
  enter_starttime: '',
  enter_endtime: '',
  founding_time: '',
  verify_time: '',
  yifang_name: ''
})
const formatDuration = (duration: number) => {
  const days: any = Math.floor(duration / (24 * 60 * 60))
  if (days > 0 && days <= 30) {
    return '到期前30天内'
  } else if (days > 30 && days <= 60) {
    return '到期前60天内'
  } else if (days < 0 && days >= -30) {
    return '到期后30天内'
  } else if (days < -30 && days >= -60) {
    return '到期后60天内'
  } else if (days < -60 && days >= -90) {
    return '到期后90天内'
  } else if (days < -90) {
    return '到期90天后客户,即将申报工商地址异常'
  } else {
    return null
  }
}
const enterTime = (endtime) => {
  const start = dayjs(endtime)
  const end = dayjs()
  const duration = start.diff(end, 'second')
  return formatDuration(duration)
}
// 列表数据
const loadData = () => {
  if (data.loading) {
    return
  }
  data.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customerList',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      pagination.total = res.data.total_records
      data.customerInfo = {
        unrecorded_electronic: res.data.unrecorded_electronic,
        modify_data: res.data.modify_data,
        to_be_verified: res.data.to_be_verified
      }
      dataSource.value = res.data.list.map((item) => {
        if (queryParam.nameOrCode) {
          item.fullName = item.full_name.replace(queryParam.nameOrCode, `<span style="color: red">${queryParam.nameOrCode}</span>`)
        } else {
          item.fullName = item.full_name
        }
        return { ...item, isShowOptions: false, isShowCompany: false, desc: null, deleteType: null, customer_id: item.id }
      })
      let downList: any = []
      for (const key in res.data.down_type) {
        downList.push({ type: key, name: res.data.down_type[key] })
      }
      if (data.progressOptions != res.data.order_status_list) {
        data.progressOptions = res.data.order_status_list
      }
      data.downList = downList
    })
    .catch((res) => {
      data.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('客户列表查询失败,请联系管理员')
      }
    })
}
//申报地址异常处理
const openAnomalyChange = (e, record, doaction_type) => {
  record.doaction_type = doaction_type
  record.anomaly_desc = '失联'
  if (!e) {
    record.anomaly_desc = null
    record.anomaly_url = null
    record.anomaly_remark = null
  }
}

//锁定列入异常
const lockException = reactive({
  customer_id: 0,
  reason: '',
  between_time: '',
  url: [],
  remark: ''
})
const openLockExceptionChange = (e, record) => {
  lockException.customer_id = record.id
  lockException.reason = ''
  //默认一个月
  lockException.between_time = dayjs().format(format) + ',' + dayjs().add(1, 'month').format(format)
  lockException.url = []
  lockException.remark = ''
}
const lockRangTimeChange = (val, str) => {
  if (val) {
    lockException.between_time = str.join(',')
  } else {
    lockException.between_time = ''
  }
}
const lockRangTime = computed(() => {
  let array: any = []
  if (lockException.between_time) {
    array = lockException.between_time.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
const submitLockException = (record) => {
  lockException.customer_id = record.id
  if (!lockException.between_time) {
    message.warn('请选择时间范围')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  if (!lockException.reason) {
    message.warn('请填写锁定理由')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  let requestParam = {
    url: '/admin/v1/addLockException',
    method: 'post',
    data: lockException
  }
  baseService(requestParam)
    .then((res) => {
      record.exception_flag = 2
      message.success({
        content: res.msg,
        duration: 10
      })
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('锁定失败，请联系管理员')
      }
    })
}

// 切换账号身份
const changerole = (seller) => {
  $store
    .dispatch('SwitchUser', seller)
    .then((res) => {
      $store.dispatch('GetInfo')
      // TODO 默认配置中使用服务端获取菜单
      // if (config.useAsyncRouter) {
      //     generateAsyncRoutes(router, res.menu)
      // }
      loginSuccess(router, res)
      setTimeout(() => {
        if (queryParam.nameOrCode) {
          localStorage.setItem('changerole_search', queryParam.nameOrCode)
        }
        window.location.href = '/'
      })
    })
    .catch((res) => {
      message.error(res.msg)
    })
}
const listed_in_anomaly = (record) => {
  if (!record.anomaly_desc) {
    message.warn('请输入申报地址异常的理由')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  if (!record.doaction_type) {
    message.warn('请选择操作类型')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  let requestParam = {
    url: '/admin/v1/listed_in_anomaly',
    method: 'post',
    data: { customer_id: record.id, desc: record.anomaly_desc, url: record.anomaly_url, remark: record.anomaly_remark, doaction_type: record.doaction_type }
  }
  baseService(requestParam)
    .then((res) => {
      record.exception_flag = 2
      message.success({
        content: res.msg,
        duration: 10
      })
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('列入异常失败，请联系管理员')
      }
    })
}
//催款告知书
const openNextYearChange = (e, record) => {
  record.read_next_year_price1 = record.read_next_year_price
  record.read_next_year_price2 = record.read_next_year_price
}
const downLeaseNextYear = (record, item) => {
  if (!record.read_next_year_price1) {
    message.warn('请输入渠道联价格')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  if (!record.read_next_year_price2) {
    message.warn('请输入客户联价格')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  downLease(item, '&read_next_year_price1=' + record.read_next_year_price1 + '&read_next_year_price2=' + record.read_next_year_price2)
}
//线索移送函
const openClueChange = (e, record) => {
  if (record.id != yisongdata.curent_id) {
    yisongdata.tsjb_from = '1' //初始化
    yisongdata.dp_name = ''
    yisongdata.dp_sell_productname = ''
    yisongdata.dp_sell_productid = ''
    yisongdata.dp_sell_product_wfwgqk = ''
    yisongdata.dp_yspt = ''
    yisongdata.yssjs_name = ''
    yisongdata.date_jdts = ''
    yisongdata.date_smhc = ''
    yisongdata.curent_id = record.id
  }
}
const downLeaseClue = (record, item) => {
  if (
    !yisongdata.dp_name ||
    !yisongdata.dp_sell_productname ||
    !yisongdata.dp_sell_productid ||
    !yisongdata.dp_sell_product_wfwgqk ||
    !yisongdata.dp_yspt ||
    !yisongdata.yssjs_name ||
    !yisongdata.date_jdts ||
    !yisongdata.date_smhc
  ) {
    message.warn('请检查必填项')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  downLease(
    item,
    '&tsjb_from=' +
      yisongdata.tsjb_from +
      '&dp_name=' +
      yisongdata.dp_name +
      '&dp_sell_productname=' +
      yisongdata.dp_sell_productname +
      '&dp_sell_productid=' +
      yisongdata.dp_sell_productid +
      '&dp_sell_product_wfwgqk=' +
      yisongdata.dp_sell_product_wfwgqk +
      '&dp_yspt=' +
      yisongdata.dp_yspt +
      '&yssjs_name=' +
      yisongdata.yssjs_name +
      '&date_jdts=' +
      yisongdata.date_jdts +
      '&date_smhc=' +
      yisongdata.date_smhc
  )
}

//租赁合同
const leaseData = reactive({
  gz: 1, //公章
  qz: 2, //签字
  zd: 1, //遮挡
  tel: 2, //是否显示电话
  zujin: null, //租金
  zq: 0, //租期
  reason: '仅供银行开户使用',
  hetong_sign_date: dayjs(dayjs().format('YYYY-MM-DD'), 'YYYY-MM-DD')
})
const openLeaseChange = (e, record) => {
  leaseData.gz = 1 //初始化
  leaseData.qz = 2
  leaseData.zd = 1
  leaseData.tel = 2
  leaseData.zujin = record.read_next_year_price == 0 ? 300 : record.read_next_year_price
  leaseData.zq = 1
  leaseData.reason = '仅供银行开户使用'
  leaseData.hetong_sign_date = dayjs(dayjs().format('YYYY-MM-DD'), 'YYYY-MM-DD')
}
const changeLeaseGz = () => {
  if (leaseData.gz == 2) {
    leaseData.qz = 2
  }
}
const downLeaseLease = (record, item) => {
  if (!leaseData.reason) {
    message.warn('请选择理由')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  downLease(
    item,
    '&gz=' +
      leaseData.gz +
      '&qz=' +
      leaseData.qz +
      '&zd=' +
      leaseData.zd +
      '&tel=' +
      leaseData.tel +
      '&zujin=' +
      leaseData.zujin +
      '&zq=' +
      leaseData.zq +
      '&reason=' +
      leaseData.reason +
      '&hetong_sign_date=' +
      leaseData.hetong_sign_date
  )
}

//属性调整控制台
const openAttrChange = (e, record) => {
  customer_attr.name = ''
  customer_attr.remark = ''
  customer_attr.annex_url = []
  customer_attr.old_fee_standard = record.fee_standard
  if (record.fee_standard == 1) {
    customer_attr.fee_standard = 2
  } else {
    customer_attr.fee_standard = 1
  }
  customer_attr.enter_starttime = ''
  customer_attr.enter_endtime = ''
  customer_attr.founding_time = ''
  customer_attr.verify_time = ''
}
//规则
const attrformRef = ref()
const submitAttrAdjust = (record) => {
  // 阻止默认行为，保持弹窗打开
  return new Promise((resolve, reject) => {
    if (!customer_attr.remark) {
      message.error('请填写调整理由！')
      reject()
    }
    let requestParam = {
      url: '/admin/v1/attrAdjust',
      method: 'post',
      data: {
        customer_id: record.id,
        ...customer_attr
      }
    }
    baseService(requestParam)
      .then((res) => {
        message.success({
          content: res.msg,
          duration: 10
        })
        resolve(true)
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('提交失败，请稍后重试')
        }
        // 阻止默认行为，保持弹窗打开
        reject(true)
      })
  })
}

//得到地址异常日志
const getExceptionLog = (record, index) => {
  data.visibleLog = true
  record.from = 1
  data.tableColumnsData = { ...record }
  data.logTabsKey = index
}
const customerNavig = () => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customerNavig',
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      Object.assign(data.customerNavig, res.data)
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取统计失败')
      }
    })
}
// 获取平台数据
const platformList = () => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/platformList',
    method: 'post',
    data: { limit: 'all' }
  }
  baseService(requestParam)
    .then((res) => {
      data.platformList = res.data.list
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('平台列表查询失败,请联系管理员')
      }
    })
}
// 表格分页查询
const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  if (filters.status) {
    queryParam.order_status_list = filters.status
  }
  loadData()
}

const onActionClick = (record, type) => {
  record.isShowOptions = false
  data.tableColumnsData = { ...record }
  switch (type) {
    case 'EntryAgreement':
      data.entryAgreementVisible = true
      break
    case 'SignatureStatus':
      data.signatureStatusVisible = true
      break
    case 'Record':
      data.recordVisible = true
      break
    case 'AddTable':
      data.addTableVisible = true
      break
    case 'SFMail':
      data.SFMailVisible = true
      break
    case 'PayCenter': //地址续费
      data.addressRenewVisible = true
      break
    case 'Refund':
      data.refundVisible = true
      break
  }
}
// 查询日志
const onViewLogClick = (record, val) => {
  record.from = 0
  data.visibleLog = true
  data.tableColumnsData = { ...record }
  data.logTabsKey = val
}

const onDirectPaymentClick = (record) => {
  record.isShowOptions = false
  data.directPaymentVisible = true
  data.tableColumnsData = { ...record }
}
const onPlatformClick = (record) => {
  record.isShowOptions = false
  data.platformVisible = true
  data.tableColumnsData = { ...record }
}
const onTaxInquiryClick = (record, type) => {
  let param = `?${record.social_credit_code ? 'data-creditcode=' + record.social_credit_code + '&' : ''}data-companyname=${record.full_name}&type=1`
  if (type == 1) {
    window.open('http://hunan.chinatax.gov.cn/taxpayerstatesearch/20190413003982' + param)
  } else if (type == 2) {
    window.open('http://hunan.chinatax.gov.cn/taxpayersearch/20190413003981' + param)
  } else if (type == 3) {
    window.open('http://hunan.chinatax.gov.cn/taxreginfosearch/20190413003984' + param)
  }
}
const onPushOrderMakingClick = (record) => {
  data.tableColumnsData = { ...record }
  data.pushOrderMakingVisible = true
}

const onPushPaymentClick = (record) => {
  data.pushPaymentVisible = true
}

const optionsVisibleChange = (val, record) => {
  if (!val) {
    record.isShowOptions = false
  }
}

const handleAdd = () => {
  data.tableColumnsData = {}
  data.visible = true
}

const openDeleteChange = (e, record) => {
  if (!e) {
    record.deleteType = null
    record.desc = null
  }
}

const handleDelete = (record) => {
  if (!record.deleteType) {
    message.warn('请选择删除原因')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  if (!record.desc) {
    message.warn('请输入删除理由')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  return new Promise((resolve, reject) => {
    let requestParam = {
      url: '/admin/v1/customer/' + record.id,
      method: 'delete',
      data: {
        type: record.deleteType,
        reason: record.desc
      }
    }
    baseService(requestParam)
      .then((res: any) => {
        message.success(res.msg)
        loadData()
        resolve(true)
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
        reject()
      })
  })
}
//更多-录入收入
const revenue = reactive({
  income_event_text: '',
  income: null,
  salesman: '',
  payee: '',
  annex_url: []
})
const openShouru = (e, record) => {
  if (!e) {
    revenue.payee = ''
    revenue.salesman = ''
    revenue.income = null
    revenue.income_event_text = ''
    revenue.annex_url = []
  }
}
const handleShouru = (record) => {
  return new Promise((resolve, reject) => {
    let requestParam = {
      url: '/admin/v1/recordedRevenue/',
      method: 'post',
      data: {
        customer_id: record.id,
        ...revenue
      }
    }
    baseService(requestParam)
      .then((res: any) => {
        message.success(res.msg)
        loadData()
        resolve(true)
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
        reject()
      })
  })
}

const onCopyClick = (record) => {
  data.copyVisible = true
  record.isShowOptions = false
  data.tableColumnsData = { ...record, isCopy: true }
}

const handleEdit = (record) => {
  data.editVisible = true
  data.tableColumnsData = { ...record }
}

const handleOk = () => {
  data.createKey++
  data.pushOrderMakingVisible = false
  data.visible = false
  data.refundVisible = false
  data.platformVisible = false
  data.editVisible = false
  data.copyVisible = false
  data.addressRenewVisible = false
  data.SFMailVisible = false
  data.directPaymentVisible = false
  loadData()
}
const handleCancel = () => {
  data.visible = false
  data.editVisible = false
  data.refundVisible = false
  data.copyVisible = false
  data.platformVisible = false
  data.pushOrderMakingVisible = false
  data.addressRenewVisible = false
  data.SFMailVisible = false
  data.directPaymentVisible = false
}
const viewLogCancel = (isRefresh) => {
  if (isRefresh) {
    loadData()
  }
  data.visibleLog = false
}

const downLease = (downItem, attach = '') => {
  if (downItem?.children?.length > 0) {
    return
  }
  let url = downItem.url.replace('https://console.cskaiyela.com/hncskaiyela', '')
  const hide = message.loading({
    content: '等待下载中,请稍等',
    duration: 0,
    class: 'loading-message'
  })
  let requestParam = {
    url: url + attach,
    method: 'get'
  }
  baseService(requestParam)
    .then((res: any) => {
      if (res.msg == 'async') {
        const mess = message.success('资料正在准备中，稍后会自动下载，如未自动下载，请前往档案管理处下载！', 0)
        //异步处,根据zipid去查询是否转换完成
        let zipid = res.data
        let down = false //幂等
        let timer = setInterval(() => {
          let requestParam = {
            url: '/admin/v1/templateZipWait',
            method: 'get',
            params: {
              id: zipid
            }
          }
          baseService(requestParam).then((res2: any) => {
            if (res2.msg == 'finish') {
              //转换完成
              clearInterval(timer)
              if (!down) {
                down = true //幂等
                //转换完成，直接下载
                setTimeout(mess, 0)
                let a = document.createElement('a')
                let event = new MouseEvent('click')
                a.download = ''
                a.href = `/hncskaiyela/admin/v1/templateZipDown?id=${zipid}&token=${token}`
                console.log(a.href)
                a.dispatchEvent(event)
              }
            }
          })
        }, 2000)
        setTimeout(hide, 2000)

        return
      }
      let a = document.createElement('a')
      let event = new MouseEvent('click')
      a.download = ''
      a.href = downItem.url + `&token=${token}&down=1`
      console.log(a.href)
      a.dispatchEvent(event)
      setTimeout(hide, 2000)
    })
    .catch((res) => {
      setTimeout(hide, 500)
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败,请联系管理员')
      }
    })
}

const donwloadClick = (type) => {
  let href = 'https://hncs.cskaiyela.com/storage/public/uploads/模版库/芙蓉区通用开户资料.zip'
  let a = document.createElement('a')
  let event = new MouseEvent('click')
  a.download = ''
  a.href = href
  a.dispatchEvent(event)
}
const exportDataArgument = [
  '主体名称',
  '统一社会信用代码',
  '法人代表姓名',
  '注册地址',
  '合同开始时间',
  '合同结束时间',
  '主体类型',
  '入驻类型',
  '成立时间',
  '核准日期',
  '录入时间',
  '主体状态',
  '异常状态',
  '法人代表电话',
  '工商预留手机号码',
  '紧急联系人电话',
  '其他电话',
  '是否支付',
  '归属代理商',
  '财务会计电话(续费联系人)',
  '财务公司名称',
  '财务公司对接人姓名',
  '财务公司对接人电话',
  '财务公司统一社会信用代码',
  '销售价格',
  '来年续费价格'
]
const exportDataArgumentChecked = reactive({
  visible: false,
  companylist: '',
  checkedfieldSimple: ['主体名称', '统一社会信用代码', '法人代表姓名', '注册地址', '合同开始时间', '合同结束时间', '主体类型', '入驻类型', '成立时间', '核准日期', '录入时间', '主体状态', '异常状态'],
  checkedfieldAll: [
    '主体名称',
    '统一社会信用代码',
    '法人代表姓名',
    '注册地址',
    '合同开始时间',
    '合同结束时间',
    '主体类型',
    '入驻类型',
    '成立时间',
    '核准日期',
    '录入时间',
    '主体状态',
    '异常状态',
    '法人代表电话',
    '工商预留手机号码',
    '紧急联系人电话',
    '其他电话',
    '是否支付',
    '归属代理商',
    '财务会计电话(续费联系人)',
    '财务公司名称',
    '财务公司对接人姓名',
    '财务公司对接人电话',
    '财务公司统一社会信用代码',
    '销售价格',
    '来年续费价格'
  ],
  checkedfieldSimpleBak: [
    '主体名称',
    '统一社会信用代码',
    '法人代表姓名',
    '注册地址',
    '合同开始时间',
    '合同结束时间',
    '主体类型',
    '入驻类型',
    '成立时间',
    '核准日期',
    '录入时间',
    '主体状态',
    '异常状态'
  ]
})
const selectExportDataArgument = (type) => {
  if (type == 1) {
    exportDataArgumentChecked.checkedfieldSimple = exportDataArgumentChecked.checkedfieldSimpleBak
  } else {
    exportDataArgumentChecked.checkedfieldSimple = exportDataArgumentChecked.checkedfieldAll
  }
}

const exportDatatab = ref('1')
const exportClick = (type) => {
  data.exportLoading = true
  exportDataArgumentChecked.visible = false
  queryParam.exportfield = exportDataArgumentChecked.checkedfieldSimple
  queryParam.type = type
  if (type == 2) {
    queryParam.companylist = exportDataArgumentChecked.companylist
  }
  let requestParam = {
    url: '/admin/v1/customerExport',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      responseType: 'blob'
    },
    data: queryParam
  }
  baseService(requestParam)
    .then((res) => {
      data.exportLoading = false
      message.success('下载成功')
    })
    .catch((res) => {
      data.exportLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('下载失败')
      }
    })
}

const toggleAdvanced = () => {
  data.advanced = !data.advanced
}
// 修改续费联系人手机号码
const editPhoneChange = (record) => {
  if (!record.new_renew_first_contact_mobile) {
    message.error('请先填写续费联系人手机号码')
    return
  }

  let promise = mobileRequiredValidator({}, record.new_renew_first_contact_mobile)
  promise
    .then(() => {
      let data: any = {
        customer_id: record.id,
        renew_first_contact_mobile: record.new_renew_first_contact_mobile
      }

      let requestParam = {
        url: '/admin/v1/quickChangeXftel',
        method: 'post',
        data: data
      }
      baseService(requestParam)
        .then((res: any) => {
          message.success(res.msg)
          record.isEditXftel = false
          record.renew_first_contact_mobile = record.new_renew_first_contact_mobile
        })
        .catch((res) => {
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('操作失败')
          }
        })
    })
    .catch((res) => {
      message.error(res)
    })
}
</script>
<style lang="less" scoped>
.navlist {
  button {
    margin-right: 10px;
  }
}

.tabs {
  position: relative;
  .tabs-right {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px 0;
    display: flex;
    gap: 32px;
    align-items: center;
  }
}
:deep(.tableRowClass) {
  .ant-table-cell:first-child {
    padding: 0 !important;
  }
}
.table-operator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px !important;
}
.customer-options-popover {
  p {
    padding: 0 16px;
    margin: 0;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    color: #000;
  }

  p:hover {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color-deprecated-bg);
  }
}
.changerole:hover {
  .deng {
    display: inline-block;
  }
}
.changerole {
  .deng {
    display: none;
  }
}

.companyInformation {
  padding: 16px;
  .deng {
    display: none;
  }
}

.companyInformation:hover {
  .deng {
    display: block;
  }
}

.loading-message {
  .ant-message-notice-content {
    border: 1px solid #c8d8cf;
    color: rgb(8, 153, 68);
    background: rgb(233, 252, 241);
  }
}
</style>
