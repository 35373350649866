<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'yichangAuditList',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="物品名称" name="name">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.name" placeholder="请输入订单号或第三方单号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="寄件人" name="send_tel">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.send_tel" placeholder="请输入寄件人姓名或手机号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="收件人" name="receive_tel">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.receive_tel" placeholder="请输入收件人姓名或手机号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="状态" name="status">
              <a-select mode="multiple" v-model:value="queryParam.status" placeholder="请选择">
                <a-select-option :value="1">已完成</a-select-option>
                <a-select-option :value="2">待完成</a-select-option>
                <a-select-option :value="3">已取消</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-item label="特别备注" name="remark">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.remark" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="录入系统时间" name="between_time">
              <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <div class="navlist">
              <a-button type="primary" @click="loadData">查询</a-button>
              <a-button type="primary" :icon="h(PlusOutlined)" @click="handleAdd" style="margin-left: 10px">新建邮寄单</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :loading="data.loading" :data-source="dataSource" :pagination="pagination" @change="onChange" size="small">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === '顺丰单号'">
          <p>
            {{ record.out_trade_no }} <a-tag color="green"> {{ record.pay_type_name }} </a-tag>
          </p>
          <p>
            <a @click="queryEmailStatus(record.order_number)">查询运单状态</a>
            <a-divider type="vertical" />
            <a-popconfirm title="取消邮寄?" @confirm="handleDelete(record)">
              <a>取消邮寄</a>
            </a-popconfirm>
          </p>
        </template>
        <template v-if="column.dataIndex === 'send'">
          <p>{{ record.send_consignee_name }} {{ record.send_tel }}</p>
          <p>{{ record.send_address }}</p></template
        >
        <template v-if="column.dataIndex === 'receive'"
          ><p>{{ record.receive_consignee_name }} {{ record.receive_tel }}</p>
          <p>{{ record.receive_address }}</p></template
        >
        <template v-if="column.dataIndex === '寄件时间'">{{ record.created_at }} </template>
        <template v-if="column.dataIndex === '物品名称'">{{ record.goodsname }} </template>
        <template v-if="column.dataIndex === '备注'">{{ record.remark }} </template>
        <template v-if="column.dataIndex === 'action'">{{ record.idcard_name }} </template>
      </template>
    </a-table>
    <a-modal
      title="邮寄状态"
      :footer="null"
      :width="700"
      :open="state.emailStatusOpen"
      @cancel="
        () => {
          state.emailStatusOpen = false
        }
      "
    >
      <a-spin :spinning="state.emailStatusLoading">
        <p>顺丰单号：{{ state.emailStatusInfo.mailNo }}</p>
        <a-timeline class="timeline" v-if="state.emailStatusInfo.routes?.length > 0">
          <a-timeline-item color="green" v-for="(item, index) in state.emailStatusInfo.routes" :key="index">
            <p :style="index == 0 ? 'color: #ff5000' : ''">
              <span v-if="opCodeMap.get(item.opCode)"> 【{{ opCodeMap.get(item.opCode) }}】 </span> {{ item.acceptTime }}
            </p>
            <p>{{ item.remark }}</p>
          </a-timeline-item>
        </a-timeline>
        <a-empty v-else style="height: 300px" description="暂无状态" class="flex-center flex-dc" />
      </a-spin>
    </a-modal>
    <!-- 新增顺丰邮寄 -->
    <create-form ref="createForm" :key="data.createKey" :open="data.visible" @cancel="handleCancel" @ok="handleOk" />
  </a-card>
</template>
<script lang="ts" setup name="yichangAuditList">
import { PlusOutlined, DownOutlined, UpOutlined, CopyOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import CreateForm from './CreateOtherForm.vue'
import EditForm from './CreateOtherForm.vue'
import { useRouter } from 'vue-router'
import { message, Modal, Table } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { unref, ref, reactive, h, onMounted, computed, onActivated, customRef } from 'vue'
import dayjs from 'dayjs'
import ls from '@/utils/Storage'
import { costByPoints } from '@logicflow/core/types/util'
import { useStore } from 'vuex'
const $store = useStore()
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const token = ls.get('ACCESS_TOKEN').replace('bearer ', '')
const user = ls.get('USER_INFO')
const router = useRouter()
const format = 'YYYY-MM-DD HH:mm:ss'
const opCodeMap = new Map([
  ['54', '上门收件'],
  ['30', '装车'],
  ['36', '封车操作'],
  ['31', '卸车'],
  ['44', '派送中'],
  ['50', '顺丰已接收'],
  ['204', '快件交接'],
  ['70', '派送失败'],
  ['80', '已签收'],
  ['648', '快件已退回'],
  ['8000', '遗失']
])
interface data {
  visible: boolean
  loading: boolean
  tableColumnsData: Object //传数据的
  createKey: number //添加的key
}
const data = reactive<data>({
  visible: false,
  loading: false,
  tableColumnsData: {},
  createKey: 1
})
const createForm = ref()
const columns = [
  {
    title: '顺丰单号',
    dataIndex: '顺丰单号'
  },
  {
    title: '寄件人信息',
    dataIndex: 'send'
  },
  {
    title: '收件人信息',
    dataIndex: 'receive'
  },
  {
    title: '物品名称',
    dataIndex: '物品名称'
  },
  {
    title: '备注',
    dataIndex: '备注'
  },

  {
    title: '寄件时间',
    dataIndex: '寄件时间'
  }
]
if (user.id == 1) {
  columns.splice(4, 0, {
    title: '操作人',
    dataIndex: 'action'
  })
}
const state = reactive({
  loading: false,
  typeList: [],
  progressOptions: [],
  platformList: [],
  emailStatusOpen: false,
  emailStatusInfo: {},
  emailStatusLoading: false,
  showModal: false,
  counterfeitInfo: {}
})
const queryEmailStatus = (id) => {
  state.emailStatusOpen = true
  state.emailStatusLoading = true
  state.emailStatusInfo = {}
  let requestParam = {
    url: '/admin/v1/sfLogistics?customer_id=' + id + '&type=checkothermail',
    method: 'get'
  }
  baseService(requestParam)
    .then((res: any) => {
      state.emailStatusLoading = false
      state.emailStatusInfo = res.data.routeResps[0]
      state.emailStatusInfo.routes = state.emailStatusInfo.routes.reverse()
    })
    .catch((res) => {
      message.error(res?.msg ? res.msg : '查询失败，请联系管理员')
      state.emailStatusLoading = false
    })
}
const formLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  }
}
let queryParam = reactive<any>({
  order_status_list: []
})
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '40', '50', '60', '200'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  loadData()
})
// 列表数据
const loadData = () => {
  if (data.loading) {
    return
  }
  data.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/otherLogisticList',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => {
        if (queryParam.nameOrCode) {
          item.fullName = item.full_name.replace(queryParam.nameOrCode, `<span style="color: red">${queryParam.nameOrCode}</span>`)
        } else {
          item.fullName = item.full_name
        }
        return { ...item, isShowOptions: false, isShowCompany: false, desc: null, deleteType: null, customer_id: item.id }
      })
    })
    .catch((res) => {
      data.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('异常审核列表查询失败,请联系管理员')
      }
    })
}

// 时间选择
const rangTime = computed(() => {
  let array: any = []
  if (queryParam.between_time) {
    array = queryParam.between_time.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
// 时间选择
const rangTimeChange = (val, str) => {
  if (val) {
    queryParam.between_time = str.join(',')
  } else {
    queryParam.between_time = null
  }
}

// 表格分页查询
const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  if (filters.status) {
    queryParam.order_status_list = filters.status
  }
  loadData()
}

//右上角的添加和编辑
const handleAdd = () => {
  data.tableColumnsData = {}
  data.visible = true
}

const handleOk = () => {
  data.createKey++
  data.visible = false
  loadData()
}
const handleCancel = () => {
  data.visible = false
}
const handleDelete = (record) => {
  let requestParam = {
    url: '/admin/v1/otherLogistic/' + record.id,
    method: 'delete'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      loadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
</script>
<style lang="less" scoped>
:deep(.ant-image) {
  margin-right: 10px;
  margin-bottom: 10px;
}
.customer-options-popover {
  p {
    padding: 0 16px;
    margin: 0;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    color: #000;
  }

  p:hover {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color-deprecated-bg);
  }
}
</style>
