<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CustomerList',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="平台名称" name="platform_id">
              <a-select mode="multiple" v-model:value="queryParam.platform_id" placeholder="请选择平台">
                <a-select-option v-for="(item, index) in data.platformList" :key="index" :value="item.id">{{ item.platform_abbreviation }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="公司名称" name="nameOrCode">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.nameOrCode" placeholder="请输入公司名称、税号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="续费号码" name="renew_first_contact_mobile">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.renew_first_contact_mobile" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24" v-if="user.username == 'wanduo' || user.username == 'admin'">
            <a-form-item label="代理商姓名" name="seller">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.seller" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24" v-else>
            <a-form-item label="法人代表姓名" name="fr_name">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.fr_name" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>

          <template v-if="data.advanced">
            <a-col :md="6" :sm="24" v-if="user.username == 'wanduo' || user.username == 'admin'">
              <a-form-item label="法人代表姓名" name="fr_name">
                <a-input @keyup.enter="loadData" v-model:value="queryParam.fr_name" placeholder="请输入"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="地址编号" name="address_number">
                <a-input @keyup.enter="loadData" v-model:value="queryParam.address_number" placeholder="请输入"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="入驻类型" name="business_type">
                <a-select mode="multiple" v-model:value="queryParam.business_type" placeholder="请选择">
                  <a-select-option :value="1">设立</a-select-option>
                  <a-select-option :value="2">同区迁入</a-select-option>
                  <a-select-option :value="3">跨区迁入</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </template>
          <a-col :md="6" :sm="24">
            <a-form-item label="主体类型" name="main_type">
              <a-select mode="multiple" v-model:value="queryParam.main_type" placeholder="请选择">
                <a-select-option :value="1">公司</a-select-option>
                <a-select-option :value="2">个体</a-select-option>
                <a-select-option :value="4">合伙企业</a-select-option><a-select-option :value="4">合伙企业</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-item label="主体状态" name="exception_flag">
              <a-select mode="multiple" v-model:value="queryParam.exception_flag" placeholder="请选择" :disabled="data.exception_flag_disabled">
                <a-select-option :value="1">正常状态</a-select-option>
                <a-select-option :value="2">待申报地址异常</a-select-option>
                <a-select-option :value="3">已办理地址异常手续</a-select-option>
                <a-select-option :value="4">确认地址已进入异常名单客户</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :md="6" :sm="24">
            <a-form-item label="录入系统时间" name="between_time">
              <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
            </a-form-item>
          </a-col> -->
          <a-col :md="6" :sm="24">
            <a-form-item label="到期时间" name="between_end">
              <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangEnd" @change="rangEndChange" :format="format"></a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <div class="table-page-search-submitButtons" style="overflow: hidden; margin-bottom: 0">
              <a-button type="primary" @click="loadtaizhang" v-if="data.showtype == 2">查询</a-button>
              <a-button type="primary" @click="loadData" v-else>查询</a-button>

              <a @click="toggleAdvanced" style="margin-left: 8px">
                {{ data.advanced ? '收起' : '展开' }}
                <UpOutlined v-if="data.advanced" />
                <DownOutlined v-else />
              </a>
              <a-popover title="导出参数配置" trigger="click" :overlayStyle="{ width: '60%' }" placement="left" v-model:open="exportDataArgumentChecked.visible">
                <template #content>
                  <a-tabs v-model:activeKey="exportDatatab">
                    <a-tab-pane key="1" tab="根据搜索参数直接导出"
                      ><a-descriptions :column="1">
                        <a-descriptions-item label="导出版本" :contentStyle="{ paddingTop: '10px' }" :labelStyle="{ paddingTop: '10px' }">
                          <a-button @click="selectExportDataArgument(1)">精简版</a-button><a-divider type="vertical" />
                          <a-button @click="selectExportDataArgument(2)">全能版</a-button>
                        </a-descriptions-item>
                        <a-descriptions-item label="导出字段">
                          <a-checkbox-group v-model:value="exportDataArgumentChecked.checkedfieldSimple" name="checkboxgroup" :options="exportDataArgument" />
                        </a-descriptions-item>
                        <a-descriptions-item :contentStyle="{ paddingLeft: '62px' }">
                          <a-button style="margin-left: 8px; display: inline-flex" :icon="h(SvgIcon, { name: 'excle' })" @click="exportClick(1)" :loading="data.exportLoading">导出</a-button>
                        </a-descriptions-item>
                      </a-descriptions></a-tab-pane
                    >
                    <a-tab-pane key="2" tab="根据输入的公司名称导出详细信息（选择此项，搜索条件将失效）"
                      ><a-descriptions :column="1">
                        <a-descriptions-item label="公司名称" :contentStyle="{ paddingTop: '10px' }" :labelStyle="{ paddingTop: '10px' }">
                          <a-textarea v-model:value="exportDataArgumentChecked.companylist" placeholder="请输入公司名称" allow-clear :rows="6" />
                        </a-descriptions-item>
                        <a-descriptions-item label="导出版本" :contentStyle="{ paddingTop: '10px' }" :labelStyle="{ paddingTop: '10px' }">
                          <a-button @click="selectExportDataArgument(1)">精简版</a-button><a-divider type="vertical" />
                          <a-button @click="selectExportDataArgument(2)">全能版</a-button>
                        </a-descriptions-item>
                        <a-descriptions-item label="导出字段">
                          <a-checkbox-group v-model:value="exportDataArgumentChecked.checkedfieldSimple" name="checkboxgroup" :options="exportDataArgument" />
                        </a-descriptions-item>
                        <a-descriptions-item :contentStyle="{ paddingLeft: '62px' }">
                          <a-button style="margin-left: 8px; display: inline-flex" :icon="h(SvgIcon, { name: 'excle' })" @click="exportClick(2)" :loading="data.exportLoading">导出</a-button>
                        </a-descriptions-item>
                      </a-descriptions>
                    </a-tab-pane>
                  </a-tabs>
                </template>
                <a-button style="margin-left: 8px; display: inline-flex" :icon="h(SvgIcon, { name: 'excle' })" :loading="data.exportLoading">导出</a-button>
              </a-popover>
              <a-button
                v-if="user.idcard_name == '丁朋' || user.idcard_name == '管理员' || user.idcard_name == '朱尚' || user.idcard_name == '魏苛'"
                style="margin-left: 8px; display: inline-flex"
                type="primary"
                @click="sendCuikuan"
                :loading="data.cuikuanLoading"
                >发送催款通知</a-button
              >
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="tabs">
      <a-tabs v-model:activeKey="data.tabsExceptionKey" @change="tabsExceptionKeyChange">
        <a-tab-pane :key="1" tab="待续费客户列表"> </a-tab-pane>
        <a-tab-pane :key="2" tab="待申报异常（第一步）"> </a-tab-pane>
        <a-tab-pane :key="3" tab="已申报异常（第二步）"> </a-tab-pane>
        <a-tab-pane :key="4" tab="确认已异常（第三步）"> </a-tab-pane>
      </a-tabs>
    </div>
    <div class="tabs" v-if="data.tabsExceptionKey != 1">
      <a-tabs v-model:activeKey="data.showtype" @change="loadtaizhang()">
        <a-tab-pane :key="1" tab="待办事项—待列入" v-if="data.tabsExceptionKey == 2"> </a-tab-pane>
        <a-tab-pane :key="1" tab="待办事项—已申报异常" v-if="data.tabsExceptionKey == 3"> </a-tab-pane>
        <a-tab-pane :key="1" tab="台账（按公司排序）" v-if="data.tabsExceptionKey == 4"> </a-tab-pane>
        <a-tab-pane :key="2" tab="台账（按时间排序）"> </a-tab-pane>
      </a-tabs>
      <a-button
        type="primary"
        :loading="data.download_sbException_loading"
        @click="downloadsbException"
        v-if="data.tabsExceptionKey == 2 && data.showtype == 1"
        style="position: absolute; top: 7px; left: 300px"
        >导出待申报数据</a-button
      >
    </div>
    <div v-if="data.tabsExceptionKey == 1 || data.showtype == 1">
      <a-table rowClassName="tableRowClass" :columns="columns" :loading="data.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
        <template #bodyCell="{ column, record, index, text }">
          <template v-if="column.dataIndex === 'companyInformation'">
            <div
              class="companyInformation"
              :style="[(2, 3, 4, 5, 6)].includes(record.status) || [2, 3, 4].includes(record.exception_flag) ? { borderLeft: '5px dashed #000', paddingLeft: '5px' } : {}"
            >
              <a-tag color="grey" v-if="record.status === 2">该主体已注销</a-tag>
              <a-tag color="grey" v-if="record.status === 3">该主体已吊销</a-tag>
              <a-tag color="grey" v-if="record.status === 4">该主体已迁走</a-tag>
              <a-tag color="grey" v-if="record.status === 5">该主体已删除</a-tag>
              <a-tag v-else-if="record.status === 6" color="grey"> 主体删除审核中，如需撤销删除，请联系管理员</a-tag>

              <div class="mv-1x">
                <a-popover trigger="click" v-model:open="record.isShowCompany" overlayClassName="customer-options-popover">
                  <template #content>
                    <p @click="handleDoubleNameClick(record.tyc_url)">跳转到天眼查</p>
                    <p @click="handleDoubleNameClick(record.qcc_url)">跳转到企查查</p>
                  </template>
                  <div style="width: 100%"></div>
                </a-popover>
                <span
                  class="mr-1x pointer"
                  :style="record.status === 5 ? { textDecorationLine: 'line-through' } : {}"
                  @click="
                    (e) => {
                      record.isShowCompany = false
                      handleNameClick(e, record.full_name)
                    }
                  "
                  @dblclick="
                    () => {
                      record.isShowCompany = true
                    }
                  "
                  v-html="record.fullName"
                ></span>
                <a-tag class="pointer" color="#f50" @click="onDirectPaymentClick(record)" v-if="record.is_pay == 2">未支付</a-tag>
                <a-tag color="#f50" v-else-if="record.is_pay == 3">待续费</a-tag>
                <a-tag color="cyan" v-else-if="record.is_pay == 1">已支付√</a-tag>
                <a-tag color="orange" v-else>状态未知</a-tag>
                <a-tag color="green" v-if="record.business_type != 1">迁入</a-tag>
                <a-tag color="green" v-if="record.fee_standard == 2">一次性付费</a-tag>
                <a-tag color="green" v-if="record.registration_type == 2">裸地址</a-tag>
                <a-tag color="green" v-if="record.main_type == 3">个人独资</a-tag>
                <a-tag color="green" v-if="record.exception_flag == 1">正常状态</a-tag>
                <a-tag color="black" v-if="record.exception_flag == 2">待申报异常</a-tag>
                <a-tag color="black" v-if="record.exception_flag == 3">已申报异常</a-tag>
                <a-tag color="black" v-if="record.exception_flag == 4">人工确认地址已异常</a-tag>
              </div>
              <div style="margin-bottom: 8px" v-if="record.taglist">
                <a-tag :color="item.color" v-for="(item, index) in record.taglist" :key="index">{{ item.name }}</a-tag>
              </div>
              <div style="font-size: 13px" class="mb-x">
                <span class="pointer" @click="handleCopyClick(record.userPositionList[0].usrename[0])">
                  {{ record.is_filiale == 1 ? '负责人' : record.main_type == 1 ? '法人代表姓名' : '负责人' }}：
                  {{ record.is_filiale == 1 ? record.userPositionList[6].usrename[0] : record.userPositionList[0].usrename[0] }}
                </span>
                <a-popover
                  placement="right"
                  :overlayInnerStyle="{
                    padding: '25px'
                  }"
                >
                  <template #content>
                    <div style="font-size: 16px; font-weight: 550; border-bottom: 1px dotted #ccc; padding-bottom: 8px">主体名称: {{ record.full_name }}</div>
                    <div style="margin: 6px 0">地址：{{ record.full_address_number }}</div>
                    <div style="margin: 6px 0" class="d-flex" v-if="record.history_names?.length > 0">
                      <div>历史申请：</div>
                      <div>
                        <div v-for="(item, index) in record.history_names" :key="index">
                          {{ item }}
                        </div>
                      </div>
                    </div>
                    <div style="margin: 6px 0" v-if="record.social_credit_code?.length > 0">统一社会信用代码：{{ record.social_credit_code }}</div>
                    <div style="margin: 6px 0">
                      <span v-if="record.verify_time" class="mr-2x">核准日期：{{ record.verify_time.substr(0, 10) }} </span>
                      <span v-if="record.founding_time"> 成立日期：{{ record.founding_time.substr(0, 10) }} </span>
                    </div>
                    <div style="margin: 6px 0">
                      <span class="mr-2x"
                        >入驻时间：{{ record.enter_starttime.substr(0, 10) }} <span class="mv-1x">~</span>
                        <span v-html="record.enter_endtime"></span>
                        <span v-if="enterTime(record.enter_endtime)" class="red">（{{ enterTime(record.enter_endtime) }}）</span>
                      </span>
                    </div>

                    <div style="margin: 16px 0">
                      <a-table :columns="shareholderColumns" rowKey="name" :pagination="false" :data-source="record.shareholder_list">
                        <template #bodyCell="res">
                          <template v-if="res.column.dataIndex == 'name'">
                            <div>{{ res.text }}</div>
                            <div>{{ res.record.phone_number }}</div>
                          </template>
                          <template v-if="res.column.dataIndex == 'id_card_number'">
                            <div>{{ res.text }}</div>
                            <div v-if="res.record.type == 1">
                              <a-button v-if="res.record.showidcard" @click="res.record.showidcard = 0" style="display: block; padding: 0" type="link">查看身份证照片</a-button>
                            </div>
                            <div v-if="res.record.type == 2">
                              <a-button v-if="res.record.showidcard" @click="res.record.showidcard = 0" style="display: block; padding: 0" type="link">查看营业执照照片</a-button>
                            </div>
                            <div v-if="!res.record.showidcard && res.record.type == 1">
                              <a-image :width="100" :src="$store.state.user.image_domain + res.record.id_card_back"></a-image>
                              <a-image :width="100" :src="$store.state.user.image_domain + res.record.id_card_front"></a-image>
                            </div>
                            <div v-if="!res.record.showidcard && res.record.type == 2">
                              <a-image :width="100" :src="$store.state.user.image_domain + res.record.business_license_url"></a-image>
                            </div>
                          </template>
                          <template v-if="res.column.dataIndex == 'position_name'">
                            <div v-if="record.main_type == 1" style="max-width: 230px">
                              <div v-if="res.text">
                                <span v-for="(item, index) in res.text.split(',')" :key="index">
                                  <a-tag v-if="item?.length > 0" color="cyan">{{ item }}</a-tag>
                                </span>
                              </div>
                              <div v-else>-</div>
                            </div>
                            <div v-else>
                              <a-tag color="cyan">负责人</a-tag>
                            </div>
                          </template>
                          <template v-else-if="res.column.dataIndex == 'investment_amount'">
                            <div v-if="res.record.is_shareholder == 1">{{ res.text }}万元</div>
                            <div v-else>-</div>
                          </template>
                          <template v-else-if="res.column.dataIndex == 'subscribe_endtime'">
                            <div v-if="record.main_type == 1 && res.record.is_shareholder == 1">
                              {{ res.text?.substr(0, 10) }}
                            </div>
                            <div v-else>-</div>
                          </template>
                        </template>
                      </a-table>
                    </div>
                  </template>
                  <a type="primary" style="margin-left: 10px">更多信息</a>
                </a-popover>
              </div>
              <div class="mb-x" style="font-size: 12px" v-if="record.history_names?.length > 0">
                <span> 历史申请: </span>
                <div v-for="(item, index) in record.history_names" :key="index">
                  {{ item }}
                </div>
              </div>
              <div style="height: 25px; font-size: 13px">
                <span v-html="record.lastcuikuanlog"></span><a @click="showcuikuanlog(record)">查看催款记录</a>
                <a-switch
                  class="ml-1x"
                  v-model:checked="record.cuikuan_flag"
                  :checkedValue="1"
                  :unCheckedValue="2"
                  checked-children="继续催款"
                  un-checked-children="不再催款"
                  style="margin-bottom: 6px"
                  @click="change_cuikuan_flag(record)"
                  size="small"
                />
              </div>
            </div>
          </template>

          <template v-if="column.dataIndex === 'enter_endtime'">
            <div class="d-flex flex-ac" style="justify-content: space-between">
              <a @click="handleCopyClick(record.renew_first_contact_mobile)">续费号码：{{ record.renew_first_contact_mobile }}</a
              ><a-divider type="vertical" />
              <a @click="getallxflist(record.id, record.renew_first_contact_mobile, 2)">查看他所有续费</a>
            </div>
            <div v-if="record.customer_work_order_status">
              <p class="mv-1x">到期时间：<span v-html="record.enter_endtime"></span></p>
            </div>
            <div v-else>-</div>

            <div class="d-flex flex-ac" style="justify-content: space-between">
              <a-popconfirm
                title="确定调整异常状态?"
                placement="right"
                @openChange="
                  (e) => {
                    openDeleteChange(e, record)
                  }
                "
                @confirm="change_anomaly_status(record)"
              >
                <template #description>
                  <a-form style="width: 700px; margin-top: 30px" v-bind="formLayout">
                    <a-form-item label="调整状态为" style="width: 800px" required>
                      <a-radio-group v-model:value="record.exception_flag2" v-if="record.exception_flag == 1">
                        <a-radio :value="2">待申报异常</a-radio>
                        <a-radio :value="3">已交申报异常材料</a-radio>
                        <a-radio :value="4">人工确认地址已异常</a-radio>
                      </a-radio-group>

                      <a-radio-group v-model:value="record.exception_flag2" v-if="record.exception_flag == 2">
                        <a-radio :value="1">正常状态</a-radio>
                        <a-radio :value="3">已交申报异常材料</a-radio>
                        <a-radio :value="4">人工确认地址已异常</a-radio>
                      </a-radio-group>
                      <a-radio-group v-model:value="record.exception_flag2" v-if="record.exception_flag == 3">
                        <a-radio :value="1">正常状态</a-radio>
                        <a-radio :value="2">待申报异常</a-radio>
                        <a-radio :value="4">人工确认地址已异常</a-radio>
                      </a-radio-group>
                      <a-radio-group v-model:value="record.exception_flag2" v-if="record.exception_flag == 4">
                        <a-radio :value="1">正常状态</a-radio>
                        <a-radio :value="2">待申报异常</a-radio>
                        <a-radio :value="3">已交申报异常材料</a-radio>
                      </a-radio-group>
                    </a-form-item>
                    <a-form-item label="调整状态理由" style="width: 800px" v-if="record.exception_flag2 == 1" required>
                      <a-input v-model:value="record.desc" placeholder="请输入理由" style="width: 320px"> </a-input>
                    </a-form-item>
                    <a-form-item label="调整状态理由" style="width: 800px" v-else>
                      <a-input v-model:value="record.desc" placeholder="请输入理由" style="width: 320px"> </a-input>
                    </a-form-item>
                    <a-form-item label="备注"> 从异常状态变为正常状态需要输入理由！ </a-form-item>
                  </a-form>
                </template>
                <a style="font-size: 13px">调整异常状态</a> </a-popconfirm
              ><a-divider type="vertical" v-if="record.exception_flag > 1" />
              <a v-if="record.exception_flag > 1" @click="getExceptionLog(record, 1)">异常日志</a>
              <a-divider type="vertical" />
              <a @click="onViewLogClick(record, 1)" :offset="[5, -5]">日志</a>
              <a-popconfirm
                title="确定删除?"
                placement="right"
                @openChange="
                  (e) => {
                    openDeleteChange(e, record)
                  }
                "
                @confirm="handleDelete(record)"
              >
                <template #description>
                  <a-form style="width: 600px" v-bind="formLayout">
                    <a-form-item label="删除原因" required>
                      <a-radio-group v-model:value="record.deleteType">
                        <a-radio :value="1">废弃</a-radio>
                        <a-radio :value="2">该主体已注销</a-radio>
                        <a-radio :value="3">该主体已迁出</a-radio>
                      </a-radio-group>
                    </a-form-item>

                    <a-form-item label="理由" v-if="record.deleteType == 1" required>
                      <a-input v-model:value="record.desc" placeholder="请输入理由"> </a-input>
                    </a-form-item>
                    <a-form-item label="理由" v-else>
                      <a-input v-model:value="record.desc" placeholder="请输入理由"> </a-input>
                    </a-form-item>
                    <a-form-item label="备注"> 删除后可在主体回收站中撤回删除 </a-form-item>
                  </a-form>
                </template>
                <a>删除</a>
              </a-popconfirm>
            </div>
          </template>
          <template v-if="column.dataIndex === '话术'">
            <div class="tipsa">
              <a-button @click="getallxflist(record, record.renew_first_contact_mobile)">话术1（全面版）</a-button>
            </div>
            <div class="tipsa">
              <a-tooltip placement="top" color="#fff" arrowPointAtCenter="true" overlayClassName="custom-tooltip-width">
                <template #title>
                  <p style="color: #000; padding: 20px" v-html="record.hs1_show"></p>
                </template>
                <a-button @click="handleCopyClick2(record.hs1_copy, record)">话术1（简版）</a-button>
              </a-tooltip>
            </div>
            <div class="tipsa">
              <a-tooltip placement="top" color="#fff" arrowPointAtCenter="true" overlayClassName="custom-tooltip-width">
                <template #title>
                  <p style="color: #000; padding: 20px" v-html="record.hs2_show"></p>
                </template>
                <a-button @click="handleCopyClick2(record.hs2_copy, record)">话术2</a-button>
              </a-tooltip>
            </div>
            <div class="tipsa" v-if="record.hs3_copy">
              <a-tooltip placement="top" color="#fff" arrowPointAtCenter="true" overlayClassName="custom-tooltip-width">
                <template #title>
                  <p style="color: #000; padding: 20px" v-html="record.hs3_show"></p>
                </template>
                <a-button @click="handleCopyClick2(record.hs3_copy, record)">话术3</a-button>
              </a-tooltip>
            </div>
          </template>
          <template v-if="column.dataIndex === 'seller'">
            <p class="mb-x">代理商：{{ record.seller ? record.seller : '-' }}</p>
            <p class="mb-x">
              <a-popover
                placement="left"
                :overlayInnerStyle="{
                  padding: '25px'
                }"
              >
                <template #content>
                  <div style="font-size: 16px; font-weight: 550; border-bottom: 1px dotted #ccc; padding-bottom: 8px">渠道详细情况：</div>
                  <div style="width: 450px; line-height: 26px">
                    <a-row>
                      <a-col :span="10" style="text-align: right">工商预留手机号码：</a-col>
                      <a-col :span="14"
                        >{{ record.registration_mobile }}<a @click="handleCopyClick(record.registration_mobile)" class="ml-1x"><CopyOutlined /></a
                      ></a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">续费联系人手机号码：</a-col>
                      <a-col :span="14"
                        >{{ record.renew_first_contact_mobile }}<a @click="handleCopyClick(record.renew_first_contact_mobile)" class="ml-1x"><CopyOutlined /></a
                      ></a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司名称：</a-col>
                      <a-col :span="14">{{ record.finance_company_name }}</a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司统一社会信用代码：</a-col>
                      <a-col :span="14">{{ record.finance_social_credit_code }}</a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司对接人姓名：</a-col>
                      <a-col :span="14">{{ record.finance_contact_name }}</a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司对接人其他号码：</a-col>
                      <a-col :span="14">{{ record.finance_contact_phone }}</a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司对接人微信昵称：</a-col>
                      <a-col :span="14"
                        ><a-avatar size="small" :src="record.xf_person?.headimgurl" v-if="!!record.finance_contact_wechat_nickname">
                          <template #icon><UserOutlined /></template> </a-avatar
                        >{{ record.finance_contact_wechat_nickname }}</a-col
                      >
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司对接人微信号：</a-col>
                      <a-col :span="14"
                        >{{ record.finance_contact_wechat_account
                        }}<a v-if="!!record.finance_contact_wechat_account" @click="handleCopyClick(record.finance_contact_wechat_account)" class="ml-1x"><CopyOutlined /></a
                      ></a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">备注：</a-col>
                      <a-col :span="14">{{ record.remark }}</a-col>
                    </a-row>
                  </div>
                </template>
                <a-avatar size="small" :src="record.xf_person?.headimgurl">
                  <template #icon><UserOutlined /></template>
                </a-avatar>
              </a-popover>
              {{ record.xf_person?.nickname }}
              <span v-if="record.xf_person">
                <a type="primary" style="margin-left: 10px" @click="onViewLogClick(record, 4)"> 发消息 </a>
              </span>
              <span v-else>该续费联系人未关注公众号，无法快捷发送消息！先关注公众号<a-image :width="20" src="https://hncs.cskaiyela.com/wxewm.jpg" /></span>
            </p>
            <span>{{ record.created_at }}</span
            ><a @click="handleCopyClick(record.renew_first_contact_mobile)" class="ml-1x"><CopyOutlined /></a>
          </template>

          <template v-if="column.dataIndex === 'action'">
            <div></div>
            <div class="mb-x">
              <a-popover
                @openChange="
                  (val) => {
                    optionsVisibleChange(val, record)
                  }
                "
                trigger="click"
                v-model:open="record.isShowOptions"
                overlayClassName="customer-options-popover"
              >
                <template #content>
                  <p @click="onActionClick(record, 'AddTable')">标签管理</p>
                  <p @click="onCopyClick(record)">复制</p>
                  <a-popconfirm
                    title="确定申报地址异常?"
                    placement="left"
                    @openChange="
                      (e) => {
                        openDeleteChange(e, record)
                      }
                    "
                    @confirm="listed_in_anomaly(record)"
                  >
                    <template #description>
                      <a-form style="width: 600px; margin-top: 30px" v-bind="formLayout">
                        <a-form-item label="申报地址异常理由" style="width: 800px" required>
                          <a-input v-model:value="record.anomaly_desc" placeholder="请输入理由" style="width: 320px"> </a-input>
                        </a-form-item>

                        <a-form-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="失联的短信电话截图" style="width: 800px" required labelWrap="true">
                          <Upload
                            :upload_path="record.upload_path"
                            :accept="['png', 'jpg', 'jpeg', 'pdf']"
                            uploadType="picture-card"
                            :limitation="99"
                            :customerName="record.full_name"
                            v-model:value="record.anomaly_url"
                            uploadText="上传失联的短信、电话截图"
                          >
                          </Upload>
                        </a-form-item>
                        <a-form-item label="操作" :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" style="width: 800px">
                          <a-radio-group v-model:value="record.doaction_type">
                            <a-radio :value="1" checked="checked"> 直接列入 </a-radio>
                            <a-radio :value="2"> 仅下载列入异常申报资料 </a-radio>
                          </a-radio-group>
                        </a-form-item>
                        <a-form-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="备注" style="width: 800px">
                          <a-input v-model:value="record.anomaly_remark" placeholder="请输入备注（可填）" style="width: 320px"> </a-input>
                        </a-form-item>
                        <a-form-item style="width: 800px"> 提示：申报地址异常后还需要递交资料才可以正式列入，请知悉！确定后将自动生成列入异常模板，也可前往档案管理里面再次下载！ </a-form-item>
                      </a-form>
                    </template>
                    <p>申报地址异常</p>
                  </a-popconfirm>
                  <a-popconfirm
                    title="确定删除?"
                    placement="left"
                    @openChange="
                      (e) => {
                        openDeleteChange(e, record)
                      }
                    "
                    @confirm="handleDelete(record)"
                  >
                    <template #description>
                      <a-form style="width: 600px" v-bind="formLayout">
                        <a-form-item label="删除原因" required>
                          <a-radio-group v-model:value="record.deleteType">
                            <a-radio :value="1">废弃</a-radio>
                            <a-radio :value="2">该主体已注销</a-radio>
                            <a-radio :value="3">该主体已迁出</a-radio>
                          </a-radio-group>
                        </a-form-item>

                        <a-form-item label="理由" v-if="record.deleteType == 1" required>
                          <a-input v-model:value="record.desc" placeholder="请输入理由"> </a-input>
                        </a-form-item>
                        <a-form-item label="理由" v-else>
                          <a-input v-model:value="record.desc" placeholder="请输入理由"> </a-input>
                        </a-form-item>
                        <a-form-item label="备注"> 删除后可在主体回收站中撤回删除 </a-form-item>
                      </a-form>
                    </template>
                    <p>删除</p>
                  </a-popconfirm>
                  <p v-if="record.is_pay != 2 && record.fee_standard != 2" @click="onActionClick(record, 'PayCenter')">地址续费</p>
                  <!-- <p @click="onActionClick(record, 'EntryAgreement')">入驻协议快照</p> -->
                  <p @click="onActionClick(record, 'SFMail')">顺丰邮寄</p>
                  <p @click="onPushPaymentClick(record)">推送至微信收款</p>
                  <p v-if="!record.first_pay_time" @click="onDirectPaymentClick(record)">支付第一次入驻服务费</p>
                  <p @click="onPlatformClick(record)">平台充值</p>
                  <a-popover trigger="click" overlayClassName="customer-options-popover">
                    <template #content>
                      <p @click="onTaxInquiryClick(record, 1)">税务基础情况查询</p>
                      <p @click="onTaxInquiryClick(record, 2)">一般纳税人查询</p>
                      <p @click="onTaxInquiryClick(record, 3)">税务登记基本信息查询</p>
                    </template>
                    <p>税务查询</p>
                  </a-popover>
                  <p @click="onActionClick(record, 'Refund')">退款</p>
                </template>
                <a
                  @click="
                    () => {
                      record.isShowOptions = true
                    }
                  "
                  >更多操作</a
                > </a-popover
              ><a-divider type="vertical" /> <a @click="onActionClick(record, 'Record')">档案管理</a>
              <a-divider type="vertical" />
              <a @click="handleEdit(record)">编辑</a>
            </div>

            <a-popover trigger="click" overlayClassName="customer-options-popover">
              <template #content>
                <div v-for="(item, index) in record.DownType" :key="index" @click="downLease(item)">
                  <a-popover trigger="click" overlayClassName="customer-options-popover" v-if="item.children?.length > 0">
                    <template #content>
                      <div v-for="(downLeaseItem, downLeaseIndex) in item.children" :key="downLeaseIndex" @click="downLease(downLeaseItem)">
                        <div v-if="downLeaseItem.children?.length > 0">
                          {{ downLeaseItem.name }}
                        </div>
                        <p v-else>
                          {{ downLeaseItem.name }}
                        </p>
                        <div v-if="downLeaseItem.children?.length > 0">
                          <p v-for="(downItem, downIndex) in downLeaseItem.children" :key="downIndex" @click="downLease(downItem)">
                            {{ downItem.name }}
                          </p>
                        </div>
                      </div>
                    </template>
                    <p>{{ item.name }}</p>
                  </a-popover>
                  <p v-else>{{ item.name }}</p>
                </div>
              </template>

              <a v-if="data.tabsExceptionKey == 2">下载申报异常材料</a>
            </a-popover>
          </template>
        </template>
      </a-table>
      <a-modal
        v-model:open="data.cuikuanbox"
        :title="data.cuikuanbox_title"
        @ok="
          () => {
            data.cuikuanbox = 0
          }
        "
        style="width: 50%"
      >
        <a-table
          :pagination="false"
          :dataSource="data.cuikuan_data"
          :columns="[
            {
              title: '序号',
              dataIndex: 'index',
              key: 'index',
              width: 60
            },
            {
              title: '催款时间',
              dataIndex: 'created_at',
              key: 'created_at'
            },
            {
              title: '催款人',
              dataIndex: 'author',
              key: 'author'
            }
          ]"
        >
          <template #bodyCell="{ column, record, index, text }">
            <template v-if="column.dataIndex === 'index'"> {{ index + 1 }} </template>
            <template v-if="column.dataIndex === 'fullname'"> {{ text }} {{ record.phone_number }} </template>
            <template v-if="column.dataIndex === 'enter_endtime'"> <span v-html="record.enter_endtime2"></span></template>
          </template>
        </a-table>
      </a-modal>
      <a-modal
        v-model:open="data.allcustomerlist"
        :title="data.allcustomerlisttitle"
        @ok="
          () => {
            data.allcustomerlist = 0
          }
        "
        style="width: 80%"
      >
        <a-table
          :pagination="false"
          :dataSource="data.allcustomerlist_data"
          :columns="[
            {
              title: '序号',
              dataIndex: 'index',
              key: 'index',
              width: 60
            },
            {
              title: '主体名称',
              dataIndex: 'full_name',
              key: 'full_name'
            },
            {
              title: '姓名电话',
              dataIndex: 'fullname',
              key: 'fullname'
            },
            {
              title: '到期时间',
              dataIndex: 'enter_endtime',
              key: 'enter_endtime'
            },
            {
              title: '注册地址',
              dataIndex: 'full_address',
              key: 'full_address'
            }
          ]"
        >
          <template #bodyCell="{ column, record, index, text }">
            <template v-if="column.dataIndex === 'index'"> {{ index + 1 }} </template>
            <template v-if="column.dataIndex === 'fullname'"> {{ text }} {{ record.phone_number }} </template>
            <template v-if="column.dataIndex === 'enter_endtime'"> <span v-html="record.enter_endtime2"></span></template>
          </template>
        </a-table>
      </a-modal>
      <!-- 新增客户 -->
      <edit-form v-if="data.editVisible" :open="true" :tableColumnsData="data.tableColumnsData" @cancel="handleCancel" @ok="handleOk" />
      <CopyForm v-if="data.copyVisible" :open="true" :tableColumnsData="data.tableColumnsData" @cancel="handleCancel" @ok="handleOk" />
      <Refund v-if="data.refundVisible" :tableColumnsData="data.tableColumnsData" @cancel="handleCancel" @ok="handleOk" />

      <!-- 直接付款 -->
      <direct-payment v-if="data.directPaymentVisible" @cancel="handleCancel" @ok="handleOk" :tableColumnsData="data.tableColumnsData"></direct-payment>
      <PlatformPayment v-if="data.platformVisible" @cancel="handleCancel" @ok="handleOk" :tableColumnsData="data.tableColumnsData"></PlatformPayment>

      <s-f-mail :tableColumnsData="data.tableColumnsData" v-if="data.SFMailVisible" @cancel="handleCancel" @ok="handleOk"></s-f-mail>
      <PayCenter :tableColumnsData="data.tableColumnsData" v-if="data.addressRenewVisible" @cancel="handleCancel" @ok="handleOk"></PayCenter>
      <AddLabel
        :tableColumnsData="data.tableColumnsData"
        v-if="data.addTableVisible"
        @cancel="
          () => {
            data.addTableVisible = false
          }
        "
      ></AddLabel>
      <Record
        :tableColumnsData="data.tableColumnsData"
        v-if="data.recordVisible"
        @cancel="
          () => {
            data.recordVisible = false
          }
        "
      ></Record>
    </div>
    <!-- 查看日志 -->
    <view-log :logTabsKey="data.logTabsKey" v-if="data.visibleLog" :tableColumnsData="data.tableColumnsData" @cancel="viewLogCancel"></view-log>
    <div v-if="data.showtype == 2">
      <a-table
        size="small"
        rowClassName="tableRowClass"
        :loading="data.loading"
        :pagination="pagination2"
        :dataSource="data.taizhangdata"
        @change="onChange2"
        :columns="[
          {
            title: '公司名称',
            dataIndex: 'full_name',
            key: 'full_name'
          },
          {
            title: '地址',
            dataIndex: 'full_address',
            key: 'full_address'
          },
          {
            title: '操作动作',
            dataIndex: 'reason',
            key: 'reason'
          },
          {
            title: '当前状态',
            dataIndex: 'current_status',
            key: 'current_status'
          },

          {
            title: '代理商',
            dataIndex: 'seller',
            key: 'seller'
          }
        ]"
      >
        <template #bodyCell="{ column, record, index, text }">
          <template v-if="column.dataIndex === 'full_name'">
            <div class="mv-1x">
              <a-popover trigger="click" v-model:open="record.isShowCompany" overlayClassName="customer-options-popover">
                <template #content>
                  <p @click="handleDoubleNameClick(record.tyc_url)">跳转到天眼查</p>
                  <p @click="handleDoubleNameClick(record.qcc_url)">跳转到企查查</p>
                </template>
                <div style="width: 100%"></div>
              </a-popover>
              <span
                class="mr-1x pointer"
                :style="record.status === 5 ? { textDecorationLine: 'line-through' } : {}"
                @click="
                  (e) => {
                    record.isShowCompany = false
                    handleNameClick(e, record.full_name)
                  }
                "
                @dblclick="
                  () => {
                    record.isShowCompany = true
                  }
                "
                v-html="record.full_name"
              ></span
              ><a @click="onViewLogClick(record, 1)">日志</a>
            </div></template
          >

          <template v-if="column.dataIndex === 'reason'">
            <a-tag color="black" v-html="record.reason"></a-tag>
            <p>{{ record.idcard_name }} {{ record.created_at }}</p>
          </template>
          <template v-if="column.dataIndex === 'current_status'">
            <a-tag :color="record.exception_flag == 1 ? 'green' : record.exception_flag == 2 ? 'black' : 'red'"> {{ record.exception_flag_name }}</a-tag>
            <p>{{ record.exception_flag_time }}</p>
          </template>
          <template v-if="column.dataIndex === 'seller'">
            <p class="mb-x">代理商：{{ record.seller ? record.seller : '-' }}</p>
            <p class="mb-x">
              <a-popover
                placement="left"
                :overlayInnerStyle="{
                  padding: '25px'
                }"
              >
                <template #content>
                  <div style="font-size: 16px; font-weight: 550; border-bottom: 1px dotted #ccc; padding-bottom: 8px">渠道详细情况：</div>
                  <div style="width: 450px; line-height: 26px">
                    <a-row>
                      <a-col :span="10" style="text-align: right">工商预留手机号码：</a-col>
                      <a-col :span="14"
                        >{{ record.registration_mobile }}<a @click="handleCopyClick(record.registration_mobile)" class="ml-1x"><CopyOutlined /></a
                      ></a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">续费联系人手机号码：</a-col>
                      <a-col :span="14"
                        >{{ record.renew_first_contact_mobile }}<a @click="handleCopyClick(record.renew_first_contact_mobile)" class="ml-1x"><CopyOutlined /></a
                      ></a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司名称：</a-col>
                      <a-col :span="14">{{ record.finance_company_name }}</a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司统一社会信用代码：</a-col>
                      <a-col :span="14">{{ record.finance_social_credit_code }}</a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司对接人姓名：</a-col>
                      <a-col :span="14">{{ record.finance_contact_name }}</a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司对接人其他号码：</a-col>
                      <a-col :span="14">{{ record.finance_contact_phone }}</a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司对接人微信昵称：</a-col>
                      <a-col :span="14"
                        ><a-avatar size="small" :src="record.xf_person?.headimgurl" v-if="!!record.finance_contact_wechat_nickname">
                          <template #icon><UserOutlined /></template> </a-avatar
                        >{{ record.finance_contact_wechat_nickname }}</a-col
                      >
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">财务公司对接人微信号：</a-col>
                      <a-col :span="14"
                        >{{ record.finance_contact_wechat_account
                        }}<a v-if="!!record.finance_contact_wechat_account" @click="handleCopyClick(record.finance_contact_wechat_account)" class="ml-1x"><CopyOutlined /></a
                      ></a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="10" style="text-align: right">备注：</a-col>
                      <a-col :span="14">{{ record.remark }}</a-col>
                    </a-row>
                  </div>
                </template>
                <a-avatar size="small" :src="record.xf_person?.headimgurl">
                  <template #icon><UserOutlined /></template>
                </a-avatar>
              </a-popover>
              {{ record.nickname }}
              <span v-if="record.nickname">
                <a type="primary" style="margin-left: 10px" @click="onViewLogClick(record, 4)"> 发消息 </a>
              </span>
              <span v-else><a-image :width="20" src="https://hncs.cskaiyela.com/wxewm.jpg" /></span>
            </p>
            <span>{{ record.created_at2 }}</span
            ><a @click="handleCopyClick(record.renew_first_contact_mobile)" class="ml-1x"><CopyOutlined /></a>
          </template>
        </template>
      </a-table>
    </div>
    <!-- 发送微信催款的确认弹窗 -->
    <a-modal v-model:open="data.confirmSendWechat" title="确认发送微信催款" @ok="sendCuikuanDo">
      <p>{{ data.confirmSendWechatInfo }}</p>
      <p>请确保发财人客户端在线，并已登录微信。</p>
    </a-modal>
  </a-card>
</template>

<script lang="ts" setup name="CustomerList">
import { UserOutlined } from '@ant-design/icons-vue'
import SvgIcon from '@/components/SvgIcon/index.vue'
import { PlusOutlined, DownOutlined, UpOutlined, CopyOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import CreateForm from './CreateForm.vue'
import CopyForm from './CreateForm.vue'
import EditForm from './CreateForm.vue'
import Refund from './components/Refund.vue'
import Record from './components/Record.vue'
import Upload from '@/components/base/Upload.vue'
import ViewLog from './components/ViewLog.vue'
import { CaretRightOutlined } from '@ant-design/icons-vue'
import PushOrderMaking from './components/PushOrderMaking.vue'
import SignatureStatus from './components/SignatureStatus.vue'
import PayCenter from './components/PayCenter.vue'
import DirectPayment from './components/DirectPayment.vue'
import PlatformPayment from './components/PlatformPayment.vue'
import SFMail from './components/SFMail.vue'
import AddLabel from './components/AddLabel.vue'
import { useRouter } from 'vue-router'
import { message, Modal, Table } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { unref, ref, reactive, h, onMounted, computed, onActivated, customRef } from 'vue'
import dayjs from 'dayjs'
import ls from '@/utils/Storage'
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const token = ls.get('ACCESS_TOKEN').replace('bearer ', '')
const user = ls.get('USER_INFO')
import { useStore } from 'vuex'
const $store = useStore()
const router = useRouter()
const format = 'YYYY-MM-DD HH:mm:ss'
const shareholderColumns: Object[] = [
  {
    title: '姓名、手机号码',
    dataIndex: 'name'
  },
  {
    title: '身份证号码',
    dataIndex: 'id_card_number'
  },
  {
    title: '职位',
    dataIndex: 'position_name'
  },
  {
    title: '出资金额',
    dataIndex: 'investment_amount'
  },
  {
    title: '认缴出资日期',
    dataIndex: 'subscribe_endtime'
  }
]
const taizhang: Object[] = [
  {
    title: '公司名称',
    dataIndex: 'name'
  },
  {
    title: '列入时间',
    dataIndex: 'id_card_number'
  },
  {
    title: '当前时间',
    dataIndex: 'position_name'
  },
  {
    title: '代理商商',
    dataIndex: 'investment_amount'
  }
]
const data = reactive({
  key: '',
  rowSelection: [],
  customerNavig: {},
  exportLoading: false,
  // 入驻协议
  entryAgreementVisible: false,
  // 档案管理
  recordVisible: false,
  // 打标签
  addTableVisible: false,
  // 查看签名状态
  signatureStatusVisible: false,
  // 付款弹窗
  directPaymentVisible: false,
  platformVisible: false,
  // 推送付款
  pushPaymentVisible: false,

  // 顺丰邮寄
  SFMailVisible: false,
  //   地址续费
  addressRenewVisible: false,
  visibleLog: false,
  visibleStatus: false,
  // 操作弹窗
  optionsVisible: false,
  // create model
  visible: false,
  // 高级搜索 展开/关闭
  advanced: true,
  tableColumnsData: {},
  loading: false,
  // 编辑
  editVisible: false,
  copyVisible: false,
  refundVisible: false,
  // 进入日志 还是进入状态
  logTabsKey: 1,
  createKey: 1,
  platformList: [],
  /* 下载列表 */
  downList: {},
  //数据列表
  customerInfo: {},
  // 进度状态
  progressOptions: [],
  tabsKey: 2,
  tabsExceptionKey: 1,
  showtype: 1,
  chat_read_count: 0,
  allcustomerlist: 0,
  allcustomerlisttitle: '',
  allcustomerlist_data: [],
  tips: '',
  cuikuanbox: 0,
  taizhangdata: [],
  cuikuanbox_title: '',
  cuikuan_data: '',
  cuikuanLoading: false,
  confirmSendWechat: false,
  confirmSendWechatInfo: '',
  exception_flag_disabled: false,
  download_sbException_loading: false
})
const filteredInfo = ref()
const createForm = ref()

const columns = computed(() => {
  const filtered = filteredInfo.value || []
  const progressOptions = data.progressOptions
  return [
    {
      title: '公司名称',
      dataIndex: 'companyInformation',
      width: 350
    },

    {
      title: '到期时间',
      dataIndex: 'enter_endtime',
      filters: progressOptions,
      filteredValue: filtered || null,
      width: 280
    },
    {
      title: '话术',
      dataIndex: '话术',
      width: 180
    },
    {
      title: '地址',
      dataIndex: 'full_address_number',
      width: 200
    },
    {
      title: '其他信息',
      dataIndex: 'seller',
      width: 200
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 200
    }
  ]
})
const formLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  }
}
let queryParam = reactive<any>({
  order_status_list: [],
  fee_standard: [1],
  exception_flag: [1, 2]
})
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 20,
  pageSizeOptions: ['10', '20', '40', '50', '60', '100', '200', '500'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
const pagination2 = reactive<PaginationProps>({
  pageSize: 100,
  pageSizeOptions: ['10', '20', '40', '50', '60', '100', '200', '500'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  platformList()
  customerNavig()
  loadData()
})
onActivated(() => {
  queryParam.nameOrCode = router.currentRoute.value.query.customer_name
  if (router.currentRoute?.value?.query?.customer_name) {
    loadData()
  }
})
// 跳转天眼查
const handleDoubleNameClick = (url) => {
  window.open(url)
}
// 时间选择
const rangTime = computed(() => {
  let array: any = []
  if (queryParam.between_time) {
    array = queryParam.between_time.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
// 时间选择
const rangTimeChange = (val, str) => {
  if (val) {
    queryParam.between_time = str.join(',')
  } else {
    queryParam.between_time = null
  }
}
const rangEnd = computed(() => {
  let array: any = []
  if (queryParam.between_end) {
    array = queryParam.between_end.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
// 时间选择
const rangEndChange = (val, str) => {
  if (val) {
    queryParam.between_end = str.join(',')
  } else {
    queryParam.between_end = null
  }
}

// 复制
const handleCopyClick = (val) => {
  if (!val) {
    message.error('手机号不存在')
    return
  }
  var input = document.createElement('textarea')
  //将input的值设置为需要复制的内容
  eval('input.value' + '="' + val + '"')
  //添加input标签
  document.body.appendChild(input)
  //选中input标签
  input.select()
  //执行复制
  document.execCommand('copy')
  //成功提示信息
  message.success('复制成功!')
  //移除input标签
  document.body.removeChild(input)
}
//复制该续费联系人的所有待续费名单
const handleCopyClick2 = (val, record) => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/copyhuashu',
    method: 'post',
    data: {
      customer_id: record.id
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      if (res.code == 200) {
        navigator.clipboard.writeText(val)
        record.lastcuikuanlog = res.data['tips']
        message.success(res.msg ? res.msg : '复制成功!')
      }
    })
    .catch((res) => {
      message.error(res.msg ? res.msg : '复制失败')
    })
}

// 复制名称
const handleNameClick = (e, name) => {
  if (e.detail == 1) {
    var input = document.createElement('input')
    //将input的值设置为需要复制的内容
    input.value = name
    //添加input标签
    document.body.appendChild(input)
    //选中input标签
    input.select()
    //执行复制
    document.execCommand('copy')
    //成功提示信息
    message.success('复制成功，尝试双击有惊喜！')
    //移除input标签
    document.body.removeChild(input)
  }
}
//展现催款日志
const showcuikuanlog = (record) => {
  data.cuikuanbox = 1
  data.cuikuanbox_title = record.full_name + '的催款日志'
  let requestParam = {
    url: '/admin/v1/cuikuanlog',
    method: 'post',
    data: {
      customer_id: record.id
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      if (res.code == 200) {
        data.cuikuan_data = res.data
      }
    })
    .catch((res) => {
      message.error(res.msg ? res.msg : '查询失败')
    })
}
//复制该续费联系人的所有待续费名单
const getallxflist = (record, renew_first_contact_mobile, type = 1) => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/getallxflist',
    method: 'post',
    data: {
      customer_id: record.customer_id,
      renew_first_contact_mobile: renew_first_contact_mobile,
      type: type
    }
  }
  if (type == 2) {
    data.allcustomerlisttitle = renew_first_contact_mobile + '的所有续费名单'
    data.allcustomerlist = 1
  }
  baseService(requestParam)
    .then((res: any) => {
      if (res.code == 200) {
        let clipboardvalue = ''
        // eval('clipboardvalue' + '="' + res.data.msg + '"')
        clipboardvalue = res.data.msg
        navigator.clipboard.writeText(clipboardvalue)
        if (type == 1) {
          record.lastcuikuanlog = res.data[0]['tips']
          message.success(res.msg ? res.msg : '复制成功!')
        } else {
          data.allcustomerlist_data = res.data.data
        }
      }
    })
    .catch((res) => {
      message.error(res.msg ? res.msg : '复制失败！')
    })
}
// 进度状态选择
const tabsChange = (val) => {
  if (val == 1) {
    queryParam.order_status_list = ['blz']
    filteredInfo.value = ['blz']
    loadData()
  } else {
    filteredInfo.value = []
    refreshTabel()
  }
}
// 进度状态选择
const orderStatusChange = (val) => {
  if (!val || val.length === 0) {
    data.tabsKey = 2
  }
}
// 进度状态选择
const tabsExceptionKeyChange = (val) => {
  if (val != 1) {
    queryParam.exception_flag = [val]
    data.exception_flag_disabled = true
    loadData()
  } else {
    data.exception_flag_disabled = false
    queryParam.exception_flag = [1, 2]
    loadData()
  }
}
// 进度状态选择
const orderStatusSelect = (val) => {
  if (val == 'blz') {
    queryParam.order_status_list = ['blz']
    data.tabsKey = 1
  } else {
    data.tabsKey = 2
    queryParam.order_status_list = queryParam.order_status_list.filter((item) => item != 'blz')
  }
  filteredInfo.value = queryParam.order_status_list
}
// 重置刷新
const refreshTabel = () => {
  pagination.current = 1
  filteredInfo.value = []
  data.tabsKey = 2
  let obj = Object.keys(queryParam).reduce((a, key) => ({ ...a, [key]: undefined }), {})
  Object.assign(queryParam, obj)
  loadData()
}
// 统计状态切换
const onStisticsClick = (val) => {
  if (queryParam.order_status == val) {
    queryParam.order_status = undefined
    loadData()
    return
  }
  if (val == 3) {
    if (user.username == 'wanduo' || user.username == 'admin') {
      router.push({ path: '/verification' })
    } else {
      message.warn('您没有权限访问资料审核')
    }
  } else if (val == 4) {
    if (user.username == 'wanduo' || user.username == 'admin') {
      router.push({ path: '/recordManagement/verification' })
    } else {
      message.warn('您没有权限访问资料审核')
    }
  } else if (val == 5) {
    queryParam.unsettled = 1
    queryParam.order_status = undefined
    queryParam.order_status_list = undefined
    loadData()
  } else if (val == 6) {
    router.push({ path: '/customer/pigeonhole' })
  } else if (val == 8) {
    queryParam.downzc = 1
    queryParam.order_status = undefined
    queryParam.order_status_list = undefined
    loadData()
  } else {
    queryParam.order_status = val
    queryParam.order_status_list = undefined
    queryParam.downzc = undefined
    loadData()
  }
}
const formatDuration = (duration: number) => {
  const days: any = Math.floor(duration / (24 * 60 * 60))
  if (days > 0 && days <= 30) {
    return '到期前30天内'
  } else if (days > 30 && days <= 60) {
    return '到期前60天内'
  } else if (days < 0 && days >= -30) {
    return '到期后30天内'
  } else if (days < -30 && days >= -60) {
    return '到期后60天内'
  } else if (days < -60 && days >= -90) {
    return '到期后90天内'
  } else if (days < -90) {
    return '到期90天后客户,即将申报工商地址异常'
  } else {
    return null
  }
}
const enterTime = (endtime) => {
  const start = dayjs(endtime)
  const end = dayjs()
  const duration = start.diff(end, 'second')
  return formatDuration(duration)
}
// 获取台账数据
const loadtaizhang = () => {
  if (data.showtype == 1) {
    loadData()
    return
  } else {
    queryParam.showtype = 2
  }

  //   if (data.loading) {
  //     return
  //   }
  data.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customerTaizhangList',
    method: 'post',
    data: Object.assign({ page: pagination2.current, limit: pagination2.pageSize, type: 'xf' }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      pagination2.total = res.data.total_records
      data.taizhangdata = res.data.list
    })
    .catch((res) => {
      data.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('客户列表查询失败,请联系管理员')
      }
    })
}
// 列表数据
const loadData = () => {
  //   if (data.loading) {
  //     return
  //   }
  data.loading = true
  data.showtype = 1
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customerList',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize, type: 'xf' }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      pagination.total = res.data.total_records
      data.customerInfo = {
        unrecorded_electronic: res.data.unrecorded_electronic,
        modify_data: res.data.modify_data,
        to_be_verified: res.data.to_be_verified
      }
      dataSource.value = res.data.list.map((item) => {
        if (queryParam.nameOrCode) {
          item.fullName = item.full_name.replace(queryParam.nameOrCode, `<span style="color: red">${queryParam.nameOrCode}</span>`)
        } else {
          item.fullName = item.full_name
        }
        return { ...item, isShowOptions: false, isShowCompany: false, desc: null, deleteType: null }
      })
      let downList: any = []
      for (const key in res.data.down_type) {
        downList.push({ type: key, name: res.data.down_type[key] })
      }
      if (data.progressOptions != res.data.order_status_list) {
        data.progressOptions = res.data.order_status_list
      }
      data.downList = downList
    })
    .catch((res) => {
      data.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('客户列表查询失败,请联系管理员')
      }
    })
}
const customerNavig = () => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customerNavig',
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      Object.assign(data.customerNavig, res.data)
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取统计失败')
      }
    })
}
// 获取平台数据
const platformList = () => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/platformList',
    method: 'post',
    data: { limit: 'all' }
  }
  baseService(requestParam)
    .then((res) => {
      data.platformList = res.data.list
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('平台列表查询失败,请联系管理员')
      }
    })
}
// 表格分页查询
const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  if (filters.status) {
    queryParam.order_status_list = filters.status
  }
  loadData()
}
// 表格分页查询
const onChange2 = (val, filters) => {
  pagination2.pageSize = val.pageSize
  pagination2.current = val.current
  if (filters.status) {
    queryParam.order_status_list = filters.status
  }
  loadtaizhang()
}
const onActionClick = (record, type) => {
  record.isShowOptions = false
  data.tableColumnsData = { ...record }
  switch (type) {
    case 'EntryAgreement':
      data.entryAgreementVisible = true
      break
    case 'SignatureStatus':
      data.signatureStatusVisible = true
      break
    case 'Record':
      data.recordVisible = true
      break
    case 'AddTable':
      data.addTableVisible = true
      break
    case 'SFMail':
      data.SFMailVisible = true
      break
    case 'PayCenter':
      data.addressRenewVisible = true
      break
    case 'Refund':
      data.refundVisible = true
      break
  }
}
// 查询日志
const onViewLogClick = (record, val) => {
  record.from = 0
  data.visibleLog = true
  data.tableColumnsData = { ...record }
  data.logTabsKey = val
}

const onDirectPaymentClick = (record) => {
  record.isShowOptions = false
  data.directPaymentVisible = true
  data.tableColumnsData = { ...record }
}
const onPlatformClick = (record) => {
  record.isShowOptions = false
  data.platformVisible = true
  data.tableColumnsData = { ...record }
}
const onTaxInquiryClick = (record, type) => {
  let param = `?${record.social_credit_code ? 'data-creditcode=' + record.social_credit_code + '&' : ''}data-companyname=${record.full_name}&type=1`
  if (type == 1) {
    window.open('http://hunan.chinatax.gov.cn/taxpayerstatesearch/20190413003982' + param)
  } else if (type == 2) {
    window.open('http://hunan.chinatax.gov.cn/taxpayersearch/20190413003981' + param)
  } else if (type == 3) {
    window.open('http://hunan.chinatax.gov.cn/taxreginfosearch/20190413003984' + param)
  }
}
const handleEdit = (record) => {
  data.editVisible = true
  data.tableColumnsData = { ...record }
}
const onPushOrderMakingClick = (record) => {
  data.tableColumnsData = { ...record }
}

const onPushPaymentClick = (record) => {
  data.pushPaymentVisible = true
}

const optionsVisibleChange = (val, record) => {
  if (!val) {
    record.isShowOptions = false
  }
}

const handleAdd = () => {
  data.tableColumnsData = {}
  data.visible = true
}

const openDeleteChange = (e, record) => {
  if (!e) {
    record.deleteType = null
    record.desc = null
  }
}
//申报地址异常处理
const openAnomalyChange = (e, record) => {
  record.doaction_type = 1
  record.anomaly_desc = '失联'
  if (!e) {
    record.anomaly_desc = null
    record.anomaly_url = null
    record.anomaly_remark = null
  }
}
const listed_in_anomaly = (record) => {
  if (!record.anomaly_desc) {
    message.warn('请输入申报地址异常的理由')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  let requestParam = {
    url: '/admin/v1/listed_in_anomaly',
    method: 'post',
    data: { customer_id: record.id, desc: record.anomaly_desc, url: record.anomaly_url, remark: record.anomaly_remark }
  }
  baseService(requestParam)
    .then((res) => {
      record.exception_flag = [2]
      message.success({
        content: res.msg,
        duration: 10
      })
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('列入异常失败，请联系管理员')
      }
    })
}
//改变地址异常主体的异常状态
const change_anomaly_status = (record) => {
  if (record.exception_flag2 == 1 && !record.desc) {
    message.warn('请输入变更为正常状态的理由')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  let requestParam = {
    url: '/admin/v1/change_anomaly_status',
    method: 'post',
    data: { customer_id: record.id, desc: record.desc, exception_flag: record.exception_flag2 }
  }
  baseService(requestParam)
    .then((res) => {
      record.exception_flag = record.exception_flag2
      message.success(res.msg)
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('列入异常失败，请联系管理员')
      }
    })
}
//增加催款日志
const addcuikuanlog = (id) => {
  let requestParam = {
    url: '/admin/v1/addcuikuanlog',
    method: 'post',
    data: { customer_id: id }
  }
  baseService(requestParam)
    .then((res: any) => {
      data.tips = res.data.tips
      message.success(res.msg)
    })
    .catch((res) => {
      message.error(res.msg)
    })
}
const gettips = (id) => {
  let requestParam = {
    url: '/admin/v1/getnewcuikuanlog',
    method: 'post',
    data: { id: id }
  }
  baseService(requestParam)
    .then((res: any) => {
      return res.msg
    })
    .catch((res) => {
      message.error(res.msg)
    })
}
//得到地址异常日志
const getExceptionLog = (record, index) => {
  data.visibleLog = true
  record.from = 1
  data.tableColumnsData = { ...record }
  data.logTabsKey = index
}
const handleDelete = (record) => {
  if (!record.deleteType) {
    message.warn('请选择删除原因')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  if (record.deleteType == 1 && !record.desc) {
    message.warn('请输入删除理由')
    return new Promise((resolve, reject) => {
      reject()
    })
  }
  return new Promise((resolve, reject) => {
    let requestParam = {
      url: '/admin/v1/customer/' + record.id,
      method: 'delete',
      data: {
        type: record.deleteType,
        reason: record.desc
      }
    }
    baseService(requestParam)
      .then((res: any) => {
        message.success(res.msg)
        loadData()
        resolve(true)
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
        reject()
      })
  })
}
const change_cuikuan_flag = (record) => {
  let requestParam = {
    url: '/admin/v1/change_cuikuan_flag',
    method: 'post',
    data: {
      customer_id: record.id
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg ? res.msg : '调整成功')
    })
    .catch((res) => {
      message.error(res.msg ? res.msg : '调整失败')
    })
}
const onCopyClick = (record) => {
  data.copyVisible = true
  record.isShowOptions = false
  data.tableColumnsData = { ...record, isCopy: true }
}

const handleOk = () => {
  data.createKey++
  data.visible = false
  data.refundVisible = false
  data.platformVisible = false
  data.editVisible = false
  data.copyVisible = false
  data.addressRenewVisible = false
  data.SFMailVisible = false
  data.directPaymentVisible = false
  loadData()
}
const handleCancel = () => {
  data.visible = false
  data.editVisible = false
  data.refundVisible = false
  data.copyVisible = false
  data.platformVisible = false
  data.addressRenewVisible = false
  data.SFMailVisible = false
  data.directPaymentVisible = false
}
const viewLogCancel = (isRefresh) => {
  if (isRefresh) {
    loadData()
  }
  data.visibleLog = false
}

const downLease = (downItem) => {
  if (downItem?.children?.length > 0) {
    return
  }
  let url = downItem.url.replace('https://console.cskaiyela.com/hncskaiyela', '')
  const hide = message.loading({
    content: '等待下载中,请稍等',
    duration: 0,
    class: 'loading-message'
  })
  let requestParam = {
    url: url,
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      let a = document.createElement('a')
      let event = new MouseEvent('click')
      a.download = ''
      a.href = downItem.url + `&token=${token}&down=1`
      a.dispatchEvent(event)
      setTimeout(hide, 2000)
    })
    .catch((res) => {
      setTimeout(hide, 500)
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败,请联系管理员')
      }
    })
}

const donwloadClick = (type) => {
  let href = 'https://hncs.cskaiyela.com/storage/public/uploads/模版库/芙蓉区通用开户资料.zip'
  let a = document.createElement('a')
  let event = new MouseEvent('click')
  a.download = ''
  a.href = href
  a.dispatchEvent(event)
}
const exportDataArgument = [
  '主体名称',
  '统一社会信用代码',
  '法人代表姓名',
  '注册地址',
  '合同开始时间',
  '合同结束时间',
  '主体类型',
  '入驻类型',
  '成立时间',
  '核准日期',
  '录入时间',
  '主体状态',
  '异常状态',
  '法人代表电话',
  '工商预留手机号码',
  '紧急联系人电话',
  '其他电话',
  '是否支付',
  '归属代理商',
  '财务会计电话(续费联系人)',
  '财务公司名称',
  '财务公司对接人姓名',
  '财务公司对接人电话',
  '财务公司统一社会信用代码',
  '销售价格',
  '来年续费价格'
]
const exportDataArgumentChecked = reactive({
  visible: false,
  companylist: '',
  checkedfieldSimple: ['主体名称', '统一社会信用代码', '法人代表姓名', '注册地址', '合同开始时间', '合同结束时间', '主体类型', '入驻类型', '成立时间', '核准日期', '录入时间', '主体状态', '异常状态'],
  checkedfieldAll: [
    '主体名称',
    '统一社会信用代码',
    '法人代表姓名',
    '注册地址',
    '合同开始时间',
    '合同结束时间',
    '主体类型',
    '入驻类型',
    '成立时间',
    '核准日期',
    '录入时间',
    '主体状态',
    '异常状态',
    '法人代表电话',
    '工商预留手机号码',
    '紧急联系人电话',
    '其他电话',
    '是否支付',
    '归属代理商',
    '财务会计电话(续费联系人)',
    '财务公司名称',
    '财务公司对接人姓名',
    '财务公司对接人电话',
    '财务公司统一社会信用代码',
    '销售价格',
    '来年续费价格'
  ],
  checkedfieldSimpleBak: [
    '主体名称',
    '统一社会信用代码',
    '法人代表姓名',
    '注册地址',
    '合同开始时间',
    '合同结束时间',
    '主体类型',
    '入驻类型',
    '成立时间',
    '核准日期',
    '录入时间',
    '主体状态',
    '异常状态'
  ]
})
const selectExportDataArgument = (type) => {
  if (type == 1) {
    exportDataArgumentChecked.checkedfieldSimple = exportDataArgumentChecked.checkedfieldSimpleBak
  } else {
    exportDataArgumentChecked.checkedfieldSimple = exportDataArgumentChecked.checkedfieldAll
  }
}

const exportDatatab = ref('1')
const exportClick = (type) => {
  data.exportLoading = true
  exportDataArgumentChecked.visible = false
  queryParam.exportfield = exportDataArgumentChecked.checkedfieldSimple
  queryParam.type = type
  if (type == 2) {
    queryParam.companylist = exportDataArgumentChecked.companylist
  }
  let requestParam = {
    url: '/admin/v1/customerExport',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      responseType: 'blob'
    },
    data: queryParam
  }
  baseService(requestParam)
    .then((res) => {
      data.exportLoading = false
      message.success('下载成功')
    })
    .catch((res) => {
      data.exportLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('下载失败')
      }
    })
}
const customWidthClass = {
  width: '400px' // 设置提示框的宽度
}
const toggleAdvanced = () => {
  data.advanced = !data.advanced
}

//发送催款通知
const sendCuikuan = () => {
  //判断必须带搜索条件
  let requestParam = {
    url: '/admin/v1/checkCuikuanWechat',
    method: 'post',
    data: queryParam
  }
  baseService(requestParam)
    .then((res) => {
      data.confirmSendWechat = true
      data.confirmSendWechatInfo = res.data
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('发送失败')
      }
    })
}
const sendCuikuanDo = () => {
  data.confirmSendWechat = false

  data.cuikuanLoading = true
  let requestParam = {
    url: '/admin/v1/sendCuikuanWechat',
    method: 'post',
    data: queryParam
  }
  baseService(requestParam)
    .then((res) => {
      data.cuikuanLoading = false
      message.success('发送成功,请等发财人客户端发送消息')
    })
    .catch((res) => {
      data.cuikuanLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('发送失败')
      }
    })
}
//导出待申报数据
let downloadInterval
const downloadsbException = () => {
  data.download_sbException_loading = true
  let requestParam = {
    url: '/admin/v1/downsbExcepiton',
    method: 'post',
    data: queryParam
  }
  baseService(requestParam)
    .then((res: any) => {
      if (res.msg == 1) {
        data.download_sbException_loading = false
        message.success(res.data)
      } else if (res.msg == 2) {
        //循环查询
        clearInterval(downloadInterval)
        downloadInterval = setInterval(() => {
          let requestParam = {
            url: `/admin/v1/downsbExcepiton`,
            method: 'POST',
            data: { rid: res.data }
          }
          baseService(requestParam)
            .then((res2: any) => {
              if (res2.msg == 3) {
                //跳转地址下载
                data.download_sbException_loading = false
                let requestParam = {
                  url: '/admin/v1/listZipDown',
                  method: 'post',
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    responseType: 'blob'
                  },
                  data: { list: res2.data, file_name: '待申报地址异常资料', token: token }
                }
                baseService(requestParam)
                  .then((res) => {
                    message.success('下载成功')
                  })
                  .catch((res) => {
                    if (res.msg) {
                      message.error(res.msg)
                    } else {
                      message.error('下载失败')
                    }
                  })
                clearInterval(downloadInterval)
              } else if (res2.msg == 5) {
                data.download_sbException_loading = false
                message.error('下载失败！')
                clearInterval(downloadInterval)
              } else {
                console.log(res2)
              }
            })
            .catch((res) => {})
        }, 5000)
      }
    })
    .catch((res) => {
      data.download_sbException_loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取待申报异常数据失败')
      }
    })
}
</script>
<style lang="less" scoped>
.navlist {
  button {
    margin-right: 10px;
  }
}

.tabs {
  position: relative;
  .tabs-right {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px 0;
    display: flex;
    gap: 32px;
    align-items: center;
  }
}
:deep(.tableRowClass) {
  .ant-table-cell:first-child {
    padding: 0 !important;
  }
}
.table-operator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px !important;
}
.customer-options-popover {
  p {
    padding: 0 16px;
    margin: 0;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    color: #000;
  }

  p:hover {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color-deprecated-bg);
  }
}

.loading-message {
  .ant-message-notice-content {
    border: 1px solid #c8d8cf;
    color: rgb(8, 153, 68);
    background: rgb(233, 252, 241);
  }
}
.custom-tooltip-width {
  :global(.ant-tooltip-inner) {
    width: 400px;
  }
}
.tipsa {
  padding: 3px;
  float: left;
}
</style>
