<template>
  <a-modal
    :bodyStyle="{ maxHeight: '80vh', overflow: 'auto' }"
    :centered="true"
    :title="state.modalTitle"
    :width="800"
    :open="open"
    :confirmLoading="state.confirmLoading"
    :maskClosable="state.maskClosable"
    @ok="onFormSubmit"
    @cancel="cancelClick"
  >
    <template #footer>
      <a-button key="取消" @click="cancelClick">取消</a-button>
      <a-button key="确定提交" type="primary" :loading="formState.loading" @click="onFormSubmit">确定提交</a-button>
    </template>
    <a-spin :spinning="state.loading">
      <a-form ref="formRef" :model="formState" :rules="rules" v-bind="formLayout">
        <a-form-item label="名称" name="name">
          <a-input v-model:value="formState.name" placeholder="请输入名称" readonly="true"></a-input>
        </a-form-item>
        <a-form-item label="编号" name="account_number">
          <a-input v-model:value="formState.account_number" placeholder="请输入编号" readonly="true"></a-input>
        </a-form-item>
        <a-form-item label="类型" name="type">
          <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model:value="formState.type" placeholder="请选择" readonly="true">
            <a-select-option :value="1">做单机器人</a-select-option>
            <a-select-option :value="2">查询机器人</a-select-option>
            <a-select-option :value="3">转换</a-select-option>
            <a-select-option :value="4">注销机器人</a-select-option>
            <a-select-option :value="5">转换机器人</a-select-option>
            <a-select-option :value="6">刻章机器人</a-select-option>
            <a-select-option :value="7">发财人客户端</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="使用者手机号" name="mobile">
          <a-input v-model:value="formState.mobile" placeholder="请输入手机号"></a-input>
        </a-form-item>
        <a-form-item label="备注" name="remark">
          <a-input v-model:value="formState.remark" placeholder="请输入备注"></a-input>
        </a-form-item>
      </a-form>
    </a-spin>
    <!-- 公司添加人员 -->
  </a-modal>
</template>

<script lang="ts" setup>
import baseService from '@/utils/http/axios'
import UploadTmp from '@/components/base/UploadTmp.vue'
import { reactive, onMounted, nextTick, h, ref, onBeforeUnmount, watch, computed } from 'vue'
import { message, Modal } from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'
import dayjs from 'dayjs'
import ls from '@/utils/Storage'
const emit = defineEmits(['ok', 'cancel'])
import { useStore } from 'vuex'
const $store = useStore()
const formRef = ref()
const principalName = ref()
// 防抖器
let timer: any = null
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
// 定时器
let interval
onBeforeUnmount(() => {
  clearInterval(interval)
})
const rules: Record<string, Rule[]> = {}

interface formState {
  loading: boolean
  name: string | undefined //名称
  account_number: string | undefined //编号
  type: number | undefined //类型
  mobile: string | undefined //手机号
  remark: string | undefined //注销备注
}
let formState = reactive<formState>({
  type: 6,
  name: '',
  account_number: '',
  loading: false,
  mobile: '',
  remark: ''
})
const props = defineProps({
  open: {
    type: Boolean
  },
  tableColumnsData: {
    type: Object,
    default: () => null
  }
})

onMounted(() => {
  // 初始化
  if (props.tableColumnsData?.id) {
    state.modalTitle = '编辑机器人信息'
    state.loading = true
    state.isEdit = true
    state.isRequired = true
    formState.type = 6
  } else {
    //添加机器人
    state.isRequired = true
    formState.type = 6
  }
  queryDetail()
})

interface state {
  loading: boolean
  maskClosable: boolean //点击蒙层是否允许关闭
  modalTitle: string
  isEdit: boolean
  confirmLoading: boolean
  isRequired: boolean //business_license_and_copy 判断是否必填
}
const state = reactive<state>({
  loading: false,
  maskClosable: true,
  modalTitle: '新增机器人',
  // 是否为编辑
  isEdit: false,
  confirmLoading: false,
  isRequired: false
})
const queryDetail = () => {
  let requestParam = {
    url: `/admin/v1/robots/${props.tableColumnsData?.id}/edit`,
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      Object.assign(formState, res.data)
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
      state.loading = false
    })
}

// 关闭
const cancelClick = (val) => {
  if (state.isEdit) {
    emit('cancel')
    return
  }
  emit('cancel')
}
// 提交
const onFormSubmit = () => {
  formRef.value
    .validate()
    .then(() => {
      formState.loading = true
      if (props.tableColumnsData?.id) {
        let requeryParam = {
          url: '/admin/v1/robots/' + props.tableColumnsData.id,
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            message.success(res.msg)
            state.confirmLoading = false
            formState.loading = false
            emit('ok')
          })
          .catch((res) => {
            if (res) {
              state.confirmLoading = false
              formState.loading = false
              if (res.msg) {
                message.warning(res.msg)
                return
              }
              message.warning('参数错误,请检查')
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/robots',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            message.success(res.msg)
            state.loading = false
            state.confirmLoading = false
            emit('ok')
          })
          .catch((res) => {
            state.loading = false
            if (res) {
              state.confirmLoading = false
              if (res.msg) {
                message.warning(res.msg)
                return
              }
              message.warning('参数错误,请检查')
            }
          })
      }
    })
    .catch((val) => {
      state.loading = false
      message.warning('请检查必填项')
    })
}
</script>

<style lang="less" scoped>
.category {
  padding: 30px 0;
  display: flex;
  justify-content: space-around;

  :deep(.ant-input-number-handler-wrap) {
    display: none;
  }

  .category-item {
    font-size: 24px;
    cursor: pointer;
    padding: 50px;
    border-radius: 15px;
    width: 100%;
    margin-right: 20px;
    text-align: center;
  }

  .company {
    border: 1px solid #e0f9f2;
    background: #e0f9f2;
    color: #00d097;
  }

  .company.check {
    border: 1px solid #00d097;
  }

  .individual {
    background: #e8f1ff;
    color: #3f8cff;
    border: 1px solid #e8f1ff;
  }

  .individual.check {
    border: 1px solid #3f8cff;
  }

  .proprietorship {
    border: 1px solid #e6fffb;
    background: #e6fffb;
    color: #13c2c2;
  }

  .proprietorship.check {
    border: 1px solid #13c2c2;
  }
}

.platform-label {
  line-height: 32px;
  max-width: 102px;
  display: flex;
  justify-content: end;
}

.platform {
  :deep(.ant-form-item-label) {
    display: none;
  }

  :deep(.ant-form-item .ant-form-item-label > label.ant-form-item-no-colon::after) {
    display: none;
  }
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.add-button {
  position: absolute;
  right: 70px;
  top: 10px;
}

.positionItem {
  display: inline-block;
  margin: 5px 0;
}

// 通过标签样式
.range {
  display: inline-block;
  box-sizing: border-box;
  height: auto;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 4px 7px;
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 20px;
  white-space: inherit;
  list-style: none;
  border-radius: 2px;
}

.pass-through {
  color: #5ca200 !important;
  background: #f6ffe4 !important;
  border: 1px solid #bad092 !important;
}

.no-pass {
  color: #ff6e03 !important;
  background: #fff5e7 !important;
  border: 1px solid #ffc067 !important;
}

:deep(.ant-modal-title) {
  font-size: 20px;
}

.share-ownership {
  min-width: 1100px;
  margin-top: 50px;
  position: relative;
  display: flex;
  justify-content: center;

  .ownership-table {
    margin-bottom: 30px;
    width: 90%;
  }
}
/deep/ .ant-form-item-label {
  display: flex;
  justify-content: flex-end;
  line-height: 16px;
  height: 40px;
  align-items: center;
  label {
    white-space: normal;
    text-align: right;
    padding-right: 10px;
    &:after {
      content: none !important; //解决上面的样式label后面会有空格
    }
  }
}
</style>
