<template>
  <a-modal :title="`${tabsTitle[state.tabsKey]}(${props.tableColumnsData.full_name})`" :width="1300" :open="true" :footer="null" @cancel="cancel">
    <div class="relative">
      <div>
        <a-button class="add-btn" type="primary" @click="addClick">添加{{ tabsTitle[state.tabsKey] }}</a-button>
        <!-- <a-button v-if="state.tabsKey == 4" type="primary" @click="yidu">一键已读</a-button> -->
      </div>

      <a-tabs v-model:activeKey="state.tabsKey">
        <a-tab-pane :key="1" tab="日志">
          <a-table :columns="columns" :loading="state.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
            <template #bodyCell="{ column, record, index, text }">
              <template v-if="column.dataIndex === 'reason'">
                <div v-if="record.type != 4" v-html="record.reason"></div>
                <div v-if="record.type == 6" class="mt-1x">
                  <a class="mr-2x" @click="queryEmailStatus(record.id)">查询运单状态</a>
                  <a-popconfirm title="确定取消?" @confirm="closeEmail(record.id)">
                    <a v-if="record.logistics_order_status != 3">取消邮寄</a>
                  </a-popconfirm>
                </div>
                <div class="mt-2x" v-if="record.urls">
                  <span v-for="(item, index) in record.urls">
                    <a-image v-if="['jpg', 'png', 'jpeg'].includes(item.name.split('.')[1])" :key="index" :width="100" :height="100" :src="$store.state.user.image_domain + item.url"> </a-image>
                    <p v-else>
                      <a :href="$store.state.user.image_domain_a + item.url" download target="_blank">{{ item.name }}</a>
                    </p>
                  </span>
                </div>
                <div v-if="record.remark && record.type != 6" v-html="record.remark"></div>
                <div v-if="record.remark2" v-html="record.remark2"></div>
                <div v-if="record.type == 4 && record.coding">
                  <a @click="showElectronicProof(record)">查看凭证</a>
                </div>
              </template>
              <template v-else-if="column.dataIndex === 'operator_name'">
                {{ text ? text : '-' }}
              </template>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane :key="2" tab="状态">
          <div v-if="state.statusDataList?.length > 0">
            <a-timeline class="timelineStatus">
              <a-timeline-item color="green" v-for="(item, index) in state.statusDataList" :key="index">
                <p>{{ item.after_status_name }} {{ item.created_at }}</p>
                <p v-if="item.is_show == 1" v-html="item.reason"></p>
                <p>操作人：{{ item.operator_name }}</p>
              </a-timeline-item>
            </a-timeline>
          </div>
          <a-empty v-else style="height: 300px" description="暂无状态" class="flex-center flex-dc" />
        </a-tab-pane>
        <a-tab-pane :key="3" tab="工单">
          <a-table :columns="workOrderColumns" :loading="state.loading" :data-source="state.workOrderList" :pagination="false">
            <template #bodyCell="{ column, record, index, text }">
              <template v-if="column.dataIndex === 'title'">
                {{ text }}

                <a-tag color="red" v-if="record.type === 1"> 投诉</a-tag>
                <a-tag color="black" v-if="record.type === 2"> 举报</a-tag>
                <a-tag color="green" v-if="record.type === 3"> 代办事项</a-tag>
              </template>
              <template v-if="column.dataIndex === 'start_time'">
                <div>{{ record.start_time }} ~</div>
                <div>
                  {{ record.end_time }}
                </div>
              </template>
              <template v-else-if="column.dataIndex === 'status'">
                <div>
                  <a-tag color="red" v-if="record.status === 0"> 待处理</a-tag>
                  <a-tag color="green" v-if="record.status === 1"> 已处理</a-tag>
                  <a-tag color="red" v-if="record.status === 2"> 已驳回</a-tag>
                </div>
              </template>
              <template v-else-if="column.dataIndex === 'submit_operator_admin_info'">
                <div>
                  {{ text.nickname }}
                </div>
              </template>
              <template v-else-if="['custodian_admin_info', 'reception_admin_info'].includes(column.dataIndex)">
                <a-tag color="green" class="mr-1x mb-1x" v-for="(item, index) in text"> {{ item.nickname }} {{ item.phone }} </a-tag>
              </template>

              <template v-else-if="column.dataIndex === 'action'">
                <a @click="handleTuisong(record)">推送</a>
                <a-divider type="vertical" />
                <a @click="handleEdit(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="handleDetail(record)">详情</a>
                <a-divider type="vertical" />
                <a-popconfirm title="确定删除?" @confirm="handleDelete(record)">
                  <a style="color: red">删除</a>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane :key="4" tab="微信沟通" class="timelineStatus">
          <a-row>
            <a-col :span="12"
              ><div v-if="state.chatList?.length > 0">
                <a-timeline>
                  <a-timeline-item color="green" v-for="(item, index) in state.chatList" :key="index">
                    <p>{{ item.after_status_name }} {{ item.created_at }}</p>
                    <p>
                      <span v-html="item.msg"></span><span>【<template v-if="item.is_read == 0"> 未读 </template><template v-else>已读</template>】</span>
                    </p>
                    <div v-if="item.attachment">
                      附件：
                      <span v-for="(itemaa, index) in item.attachment">
                        <a-image
                          v-if="itemaa.name.indexOf('jpg') > -1 || itemaa.name.indexOf('png') > -1 || itemaa.name.indexOf('jpeg') > -1"
                          :key="index"
                          :width="120"
                          :height="50"
                          :src="$store.state.user.image_domain + itemaa.url"
                        >
                        </a-image>
                        <p v-else>
                          <a :href="$store.state.user.image_domain + itemaa.url" target="_blank">{{ itemaa.name }}</a>
                        </p>
                      </span>
                    </div>
                    <p>
                      发送人：<a-avatar size="small" :src="item.send_info?.headimgurl">
                        <template #icon><UserOutlined /></template> </a-avatar
                      >{{ item.send_info.nickname }}
                    </p>
                  </a-timeline-item>
                </a-timeline>
              </div>
              <a-empty v-else style="height: 300px" description="暂无微信沟通记录" class="flex-center flex-dc"
            /></a-col>
            <a-col :span="12">
              <a-form class="flex-1" ref="goutonFormRef" v-bind="formLayout" :model="goutonFrom">
                <a-form-item label="接收人">
                  <!-- <template v-for="(tag, index) in goutonFrom.tels" :key="tag">
                <a-tag closable color="green" @close="handleClose(tag)">
                    {{ tag.name }}
                </a-tag>
                </template> -->
                  <a-select v-model:value="goutonFrom.tels" mode="multiple" style="width: 100%" placeholder="Please select" :options="state.tels" @change="handleChange"></a-select>
                </a-form-item>
                <a-form-item label="附件上传">
                  <Upload
                    upload_path="public/uploads/微信沟通"
                    :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
                    v-model:value="goutonFrom.attachment"
                    uploadType="text"
                    :limitation="9"
                    uploadText="上传附件"
                  >
                  </Upload>
                </a-form-item>
                <a-form-item label="发微信消息">
                  <div style="display: flex">
                    <a-input required v-model:value="goutonFrom.msg" type="textarea" placeholder="请输入发布的微信内容"> </a-input>
                    <a-popconfirm title="确认发消息吗?" @confirm="fabugouton">
                      <a-button class="mr-1x" type="primary">发布</a-button>
                    </a-popconfirm>
                  </div>
                </a-form-item>
                <a-form-item label="备注">
                  <p>此处发送消息，将给关注了微信公众的与之主体相关的人员推送一条微信消息。</p>
                </a-form-item>
              </a-form></a-col
            >
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-modal title="添加日志" :bodyStyle="{ padding: '20px', paddingBottom: '0' }" :width="700" :destroyOnClose="true" :open="state.createLogOpen" @ok="createLogOk" @cancel="createLogCancel"
      ><template #footer>
        <a-button key="back" @click="createLogCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="createLogForm.loading" @click="createLogOk" :disabled="createLogForm.finishLoading">确定</a-button>
      </template>
      <a-form :model="createLogForm" v-bind="formLayout" ref="createLogFormRef">
        <a-form-item label="日志类型">
          <a-radio-group v-model:value="createLogForm.type">
            <a-radio :value="0">普通</a-radio>
            <a-radio :value="8">待办事项</a-radio>
            <a-radio :value="2">投诉</a-radio>
            <a-radio :value="3">举报</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="日志类型">
          <a-radio-group v-model:value="createLogForm.is_money_log">
            <a-radio :value="2">普通日志</a-radio>
            <a-radio :value="1">金钱相关</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="日志权限">
          <a-radio-group v-model:value="createLogForm.is_public">
            <a-radio :value="1">公开</a-radio>
            <a-radio :value="2">保密</a-radio>
          </a-radio-group>
          <div>如果是公开，则所有人均可以看到这条日志</div>
        </a-form-item>
        <a-form-item label="附件">
          <Upload
            :upload_path="props.tableColumnsData.upload_path"
            uploadType="text"
            :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'mp3', 'mp4', 'zip', 'rar', '7z']"
            v-model:value="createLogForm.urls"
            :limitation="99"
            uploadText="上传附件"
          >
          </Upload>
        </a-form-item>
        <a-form-item label="日志内容">
          <a-textarea placeholder="请输入日志内容" v-model:value="createLogForm.reason"></a-textarea>
          <a-button v-if="!createLogForm.is_work_order" class="mt-2x" @click="() => (createLogForm.is_work_order = true)" type="primary">附加工单</a-button>
          <a-button v-if="createLogForm.is_work_order" class="mt-2x" @click="() => (createLogForm.is_work_order = false)" type="primary">取消附加工单</a-button>
        </a-form-item>
        <a-form-item label="工单类型" v-if="createLogForm.is_work_order" required>
          <a-select ref="select" v-model:value="createLogForm.wo_type">
            <a-select-option :value="1">经营纠纷</a-select-option>
            <a-select-option :value="2">网络卖课</a-select-option>
            <a-select-option :value="3">疑似诈骗</a-select-option>
            <a-select-option :value="4">疑似银行类犯罪</a-select-option>
            <a-select-option :value="5">其它类型</a-select-option>
          </a-select>
        </a-form-item>
        <div v-if="createLogForm.is_work_order">
          <a-form-item label="工单处理接收人" name="recipient" required>
            <a-select mode="multiple" optionLabelProp="label" show-search v-model:value="createLogForm.recipient" placeholder="请选择工单处理接收人">
              <a-select-option :key="index" :value="item.idcard_name" :label="item.idcard_name" :title="item.idcard_name" v-for="(item, index) in state.userList">
                <div class="d-flex">
                  <span>
                    {{ item.nickname }}
                  </span>
                  <span> {{ item.phone }}</span>
                </div>
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="开始时间" name="start_time" required>
            <a-date-picker :show-time="{ defaultValue: arrayMonent }" v-model:value="createLogForm.start_time" placeholder="请选择开始日期"></a-date-picker>
          </a-form-item>
          <a-form-item label="截止完成时间" name="end_time" required>
            <a-date-picker :show-time="{ defaultValue: arrayMonent }" v-model:value="createLogForm.end_time" placeholder="请选择截止日期"></a-date-picker>
          </a-form-item>
          <a-form-item label="工单内容" name="content" required>
            <a-input placeholder="请输入工单内容" v-model:value="createLogForm.content"></a-input>
          </a-form-item>
          <a-form-item label="工单办结监督责任人" required>
            <a-select mode="multiple" optionLabelProp="label" show-search v-model:value="createLogForm.custodian" placeholder="请选择工单办结监督责任人">
              <a-select-option :key="index" :value="item.idcard_name" :label="item.idcard_name" :title="item.idcard_name" v-for="(item, index) in state.userList">{{
                item.idcard_name
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="机密内容">
            <a-textarea placeholder="请输入机密内容" v-model:value="createLogForm.secret_content" :rows="3"> </a-textarea>
          </a-form-item>
          <a-form-item label="紧急情况">
            <a-radio-group button-style="solid" v-model:value="createLogForm.critical_situation">
              <a-radio-button :value="1">不急</a-radio-button>
              <a-radio-button :value="2">普通</a-radio-button>
              <a-radio-button :value="3">紧急</a-radio-button>
              <a-radio-button :value="4">最高级</a-radio-button>
            </a-radio-group>
          </a-form-item>
        </div>
      </a-form>
    </a-modal>

    <a-modal title="添加状态" :bodyStyle="{ padding: '20px', paddingBottom: '0' }" :width="500" :destroyOnClose="true" :open="state.createStatusOpen" @ok="createStatusOk" @cancel="statusCancel">
      <a-form :model="createStatusForm" ref="createStatusFormRef" v-bind="formLayout">
        <a-form-item label="选择状态" name="status" required>
          <a-select v-model:value="createStatusForm.status" placeholder="请选择状态">
            <a-select-option v-for="(item, index) in state.statusList" :key="index" :value="Object.keys(item)[0]">{{ Object.values(item)[0] }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="说明" name="reason">
          <a-input placeholder="请输入说明" v-model:value="createStatusForm.reason"></a-input>
        </a-form-item>
        <!-- <a-form-item label="是否微信推送">
          <a-radio-group v-model:value="createStatusForm.wx_ts">
            <a-radio :value="1">发送</a-radio>
            <a-radio :value="2">不发送</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="是否短信推送">
          <a-radio-group v-model:value="createStatusForm.is_send_msg">
            <a-radio :value="1">推送</a-radio>
            <a-radio :value="2">不推送</a-radio>
          </a-radio-group>
        </a-form-item> -->
      </a-form>
    </a-modal>
    <a-modal
      title="邮寄状态"
      :footer="null"
      :width="700"
      :open="state.emailStatusOpen"
      @cancel="
        () => {
          state.emailStatusOpen = false
        }
      "
    >
      <a-spin :spinning="state.emailStatusLoading">
        <p>顺丰单号：{{ state.emailStatusInfo.mailNo }}</p>
        <a-timeline class="timeline" v-if="state.emailStatusInfo.routes?.length > 0">
          <a-timeline-item color="green" v-for="(item, index) in state.emailStatusInfo.routes" :key="index">
            <p :style="index == 0 ? 'color: #ff5000' : ''">
              <span v-if="opCodeMap.get(item.opCode)"> 【{{ opCodeMap.get(item.opCode) }}】 </span> {{ item.acceptTime }}
            </p>
            <p>{{ item.remark }}</p>
          </a-timeline-item>
        </a-timeline>
        <a-empty v-else style="height: 300px" description="暂无状态" class="flex-center flex-dc" />
      </a-spin>
    </a-modal>
    <ElectronicProof v-if="state.showModal" :counterfeitInfo="state.counterfeitInfo" @cancel="closeModal"></ElectronicProof>
    <CreateWorkOrder v-if="state.showWork" :counterfeitInfo="props.tableColumnsData" @cancel="closeModal"></CreateWorkOrder>

    <create-work-order v-if="state.visible" :detailData="state.createFormData" @cancel="handleCancel" @ok="handleOk" />
    <detail v-if="state.detailVisible" :tableColumnsData="state.createFormData" @cancel="handleCancel" @ok="handleOk"></detail>
    <Tuisong v-if="state.tuisongVisible" :tuisongData="state.createFormData" @cancel="handleCancel" @ok="handleOk"></Tuisong>
  </a-modal>
</template>

<script lang="ts" setup>
import detail from '../../workOrder/components/detail.vue'
import createWorkOrder from '../../workOrder/CreateWorkOrder.vue'
import Tuisong from '../../workOrder/Tuisong.vue'

import type { PaginationProps } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { message } from 'ant-design-vue'
import ElectronicProof from './ElectronicProof.vue'
import Upload from '@/components/base/Upload.vue'
import { ref, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import CreateWorkOrder from '../../workOrder/CreateWorkOrder.vue'
import dayjs from 'dayjs'
const arrayMonent = dayjs('00:00:00', 'HH:mm:ss')
const format = 'YYYY-MM-DD HH:mm:ss'
const startTime = dayjs()
const endTime = dayjs().add(2, 'day')

const $store = useStore()
const emit = defineEmits(['cancel'])
let dataSource = ref([])
const opCodeMap = new Map([
  ['54', '上门收件'],
  ['30', '装车'],
  ['36', '封车操作'],
  ['31', '卸车'],
  ['44', '派送中'],
  ['50', '顺丰已接收'],
  ['204', '快件交接'],
  ['70', '派送失败'],
  ['80', '已签收'],
  ['648', '快件已退回'],
  ['8000', '遗失']
])
const tabsTitle = {
  3: '工单',
  2: '状态',
  1: '日志',
  4: '微信沟通'
}
const formLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 14 }
  }
}
const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: false
  },
  logTabsKey: {
    type: Number,
    default: 1
  }
})
let param = ref<any>({ name: '' })
let queryParam = param.value
const workOrderColumns = [
  {
    title: '工单标题',
    dataIndex: 'title',
    width: 150
  },
  {
    title: '工单服务对象',
    dataIndex: 'full_name',
    width: 150
  },
  {
    title: '工单状态',
    dataIndex: 'status',
    width: 100
  },
  {
    title: '工单内容',
    dataIndex: 'content',
    width: 150
  },
  {
    title: '工单时间',
    dataIndex: 'start_time',
    width: 150
  },
  {
    title: '处理人',
    dataIndex: 'reception_admin_info',
    width: 100
  },
  {
    title: '监督人',
    dataIndex: 'custodian_admin_info',
    width: 100
  },
  {
    title: '创建人',
    dataIndex: 'submit_operator_admin_info',
    width: 100
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    width: 100
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: 100
  }
]
const handleChange = (value: string[]) => {
  // 添加到goutonFrom里面去
  goutonFrom.tels = value
}

const columns = [
  {
    title: '日志',
    dataIndex: 'reason'
  },
  {
    title: '日志类型',
    dataIndex: 'type_name',
    width: 100
  },
  {
    title: '操作人',
    dataIndex: 'operator_name',
    width: 100
  },

  {
    title: '创建时间',
    dataIndex: 'created_at',
    width: 150
  }
]
const createStatusForm = reactive<any>({
  status: undefined,
  reason: ''
  //   is_send_msg: 2,
  //   wx_ts: 2
})
const createLogForm = reactive<any>({
  type: 0,
  is_public: 1,
  wo_type: '',
  is_money_log: 2,
  content: '',
  custodian: [],
  recipient: [],
  end_time: null,
  start_time: null,
  is_work_order: false,
  loading: false
})
const goutonFrom = reactive<any>({
  customer_id: props.tableColumnsData.id,
  msg: '',
  attachment: [],
  tels: []
})
const createStatusFormRef = ref()
const createLogFormRef = ref()
const goutonFormRef = ref()
const state = reactive<any>({
  //tabs key
  tabsKey: 1,
  loading: false,
  showModal: false,
  showWork: false,
  statusDataList: [],
  chatList: [],
  workOrderList: [],
  createStatusOpen: false,
  createLogOpen: false,
  statusList: [],
  emailStatusOpen: false,
  emailStatusLoading: false,
  isRefresh: false,
  emailStatusInfo: {},
  counterfeitInfo: {},
  userList: [],
  tuisongVisible: false,
  detailVisible: false,
  visible: false,
  tels: []
})
onMounted(() => {
  state.tabsKey = props.logTabsKey
  createLogForm.recipient.push(props.tableColumnsData.seller)
  if (props.tableColumnsData.platform_store_manager_info?.length > 0) {
    props.tableColumnsData.platform_store_manager_info.forEach((element) => {
      if (element.nickname) {
        createLogForm.custodian.push(element.nickname)
      }
    })
  }
  logData()
  statusData()
  getWorkOrderStatus()
  getChatList()
  getUserList()
  getWorkOrderList()
  getCtelInfo()
})
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '40'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})

// const yidu = () => {
//   let requestParam = {
//     url: '/admin/v1/customerChatReadAll',
//     method: 'post',
//     data: {
//       customer_id: props.tableColumnsData.id
//     }
//   }
//   baseService(requestParam)
//     .then((res) => {
//       getChatList()
//     })
//     .catch((res) => {
//       if (res.msg) {
//         message.error(res.msg)
//       } else {
//         message.error('操作失败')
//       }
//     })
// }
const getCtelInfo = () => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/getCtelInfo?customer_id=` + props.tableColumnsData.id,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    state.tels = res.data.tels
    state.tels.forEach((element) => {
      // 判断label里面是否有联系人关键字
      if (element.label.indexOf('联系人') > -1) {
        goutonFrom.tels.push(element.value)
      } else if (element.label.indexOf('代理商') > -1) {
        goutonFrom.tels.push(element.value)
      }
    })
  })
}

const handleClose = (removedTag: string) => {
  const tags = goutonFrom.tels.filter((tag) => tag !== removedTag)
  goutonFrom.tels = tags
}
const handleCancel = () => {
  state.visible = false
  state.tuisongVisible = false
  state.detailVisible = false
}
const handleOk = () => {
  state.visible = false
  state.detailVisible = false
  state.tuisongVisible = false
}

const handleAdd = () => {
  state.createFormData = {}
  state.visible = true
}
const handleEdit = (record) => {
  state.visible = true
  state.createFormData = { ...record }
}
const handleTuisong = (record) => {
  state.tuisongVisible = true
  state.createFormData = { ...record }
}
const handleDetail = (record) => {
  state.detailVisible = true
  state.createFormData = { ...record }
}

const fabugouton = () => {
  console.log(goutonFrom)
  goutonFormRef.value
    .validate()
    .then(() => {
      let requestParam = {
        url: '/admin/v1/addCustomerChat',
        method: 'post',
        data: Object.assign(goutonFrom)
      }
      baseService(requestParam)
        .then((res) => {
          message.success('添加成功')
          goutonFrom.msg = null
          goutonFrom.attachment = null
          getChatList()
        })
        .catch((res) => {
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('添加失败')
          }
        })
    })
    .catch(() => {})
}

const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  queryParam.status = filters.status
  logData()
}
const getUserList = () => {
  let requestParam = {
    url: '/admin/user/publicList',
    method: 'post',
    data: { page: pagination.current, limit: 'all' }
  }
  baseService(requestParam).then((res) => {
    state.userList = res.data.list
  })
}

const getWorkOrderList = () => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/workOrderList',
    method: 'post',
    data: { page: 1, limit: 'all', full_name: props.tableColumnsData.full_name, customer_id: props.tableColumnsData.id }
  }
  baseService(requestParam)
    .then((res) => {
      state.workOrderList = res.data.list
    })
    .catch((res) => {})
}

const getWorkOrderStatus = () => {
  let requestParam = {
    url: '/admin/v1/getWorkOrderStatus?customer_id=' + props.tableColumnsData.id,
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      state.statusList = res.data.status_list
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取状态失败')
      }
    })
}
const addClick = () => {
  if (state.tabsKey == 2) {
    state.createStatusOpen = true
  } else if (state.tabsKey == 1) {
    //添加日志
    state.createLogOpen = true
    createLogForm.loading = false
    createLogForm.reason = ''
    createLogForm.content = ''
    createLogForm.secret_content = ''
    createLogForm.critical_situation = 1 //紧急情况
    createLogForm.start_time = startTime
    createLogForm.end_time = endTime
  } else if (state.tabsKey == 3) {
    state.showWork = true
  }
}
const statusCancel = () => {
  state.createStatusOpen = false
  createStatusForm.status = undefined
  createStatusForm.reason = ''
  createStatusForm.is_send_msg = 2
  createStatusForm.wx_ts = 2
}
const closeModal = () => {
  state.showModal = false
  state.showWork = false
  getWorkOrderList()
}
const createStatusOk = () => {
  createStatusFormRef.value
    .validate()
    .then(() => {
      let requestParam = {
        url: '/admin/v1/addWorkOrderLog',
        method: 'post',
        data: Object.assign({ customer_id: props.tableColumnsData.id }, createStatusForm)
      }
      baseService(requestParam)
        .then((res) => {
          state.isRefresh = true
          message.success('添加成功')
          state.createStatusOpen = false
          createStatusForm.status = undefined
          statusData()
        })
        .catch((res) => {
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('添加失败')
          }
        })
    })
    .catch(() => {})
}
const queryEmailStatus = (id) => {
  state.emailStatusOpen = true
  state.emailStatusLoading = true
  state.emailStatusInfo = {}
  let requestParam = {
    url: '/admin/v1/sfLogistics?customer_id=' + props.tableColumnsData.id + '&work_order_log_id=' + id,
    method: 'get'
  }
  baseService(requestParam)
    .then((res: any) => {
      state.emailStatusLoading = false
      state.emailStatusInfo = res.data.routeResps[0]
      state.emailStatusInfo.routes = state.emailStatusInfo.routes.reverse()
    })
    .catch((res) => {
      message.error(res?.msg ? res.msg : '查询失败，请联系管理员')
      state.emailStatusLoading = false
    })
}
const createLogOk = () => {
  createLogForm.loading = true

  createLogFormRef.value
    .validate()
    .then(() => {
      let requestParam = {
        url: '/admin/v1/customerAddLog',
        method: 'post',
        data: {
          ...createLogForm,
          customer_id: props.tableColumnsData.id
        }
      }
      baseService(requestParam)
        .then((res) => {
          state.isRefresh = true
          message.success('添加成功')
          state.createLogOpen = false
          logData()
        })
        .catch((res) => {
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('添加失败')
          }
          createLogForm.loading = false
        })
    })
    .catch(() => {
      createLogForm.loading = false
    })
}
const createLogCancel = () => {
  state.createLogOpen = false
}
const logData = () => {
  state.loading = true
  // 获取到列表数据
  let requestParam = {
    url: `/admin/v1/getCustomerWorkOrderLog`,
    method: 'post',
    data: Object.assign(
      { page: pagination.current, limit: pagination.pageSize },
      { customer_id: props.tableColumnsData.id, type: 0, from: props.tableColumnsData.from ? props.tableColumnsData.from : 0 }
    )
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => {
        if (item.urls?.length > 0) {
          let list1 = item.urls.filter((item) => ['jpg', 'png', 'jpeg'].includes(item.name.split('.')[1]))
          let list2 = item.urls.filter((item) => !['jpg', 'png', 'jpeg'].includes(item.name.split('.')[1]))
          item.urls = [...list1, ...list2]
        }
        return { ...item }
      })
    })
    .catch((res) => {})
}
const statusData = () => {
  let requestParam = {
    url: '/admin/v1/getCustomerWorkOrderLog',
    method: 'post',
    data: {
      customer_id: props.tableColumnsData.id,
      type: 1,
      limit: 'all',
      page: 1,
      from: props.tableColumnsData.from ? props.tableColumnsData.from : 0
    }
  }
  baseService(requestParam)
    .then((res) => {
      state.statusDataList = res.data.list
    })
    .catch((res) => {})
}
const getChatList = () => {
  let requestParam = {
    url: '/admin/v1/customerChatList',
    method: 'post',
    data: {
      customer_id: props.tableColumnsData.id,
      limit: 'all',
      page: 1
    }
  }
  baseService(requestParam)
    .then((res) => {
      state.chatList = res.data.list
    })
    .catch((res) => {})
}
const cancel = () => {
  emit('cancel', state.isRefresh)
}
const showElectronicProof = (record) => {
  state.counterfeitInfo = {
    info: {
      remark: record.remark,
      remark2: record.remark2
    },
    token: record.coding
  }
  state.showModal = true
}
// 取消邮寄
const closeEmail = (id) => {
  let requestParam = {
    url: '/admin/v1/sfCancels?customer_id=' + props.tableColumnsData.id + '&work_order_log_id=' + id,
    method: 'get'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res?.msg ? res.msg : '操作成功')
      logData()
    })
    .catch((res) => {
      message.error(res?.msg ? res.msg : '取消失败，请联系管理员')
    })
}
const handleDelete = (record) => {
  let requestParam = {
    url: '/admin/v1/workOrder/' + record.id,
    method: 'delete'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      logData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
</script>

<style lang="less" scoped>
.add-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}

.timelineStatus {
  .ant-timeline-item:first-child {
    p:first-child {
      color: #ff5000;
    }
  }
}
:deep(.ant-table-cell) {
  padding: 12px !important;
}
:deep(.ant-image) {
  margin-right: 10px;
  margin-bottom: 10px;
}
.timeline {
  .ant-timeline-item:first-child {
    p:first-child {
      color: #ff5000;
    }
  }
}
</style>
