<template>
  <a-modal
    :bodyStyle="{ maxHeight: '80vh', overflow: 'auto' }"
    :centered="true"
    :title="state.modalTitle"
    :width="1200"
    :open="open"
    :confirmLoading="state.confirmLoading"
    :maskClosable="state.maskClosable"
    @ok="onFormSubmit"
    @cancel="cancelClick"
  >
    <template #footer>
      <a-button key="取消" @click="cancelClick">取消</a-button>
      <a-button key="确定提交" type="primary" :loading="state.loading" @click="onFormSubmit">确定提交</a-button>
    </template>
    <a-spin :spinning="state.loading">
      <a-form ref="formRef" :model="formState" :rules="rules" v-bind="formLayout">
        <!-- 基础信息 -->
        <a-form-item label="付款方式" name="pay_type">
          <a-radio-group v-model:value="formState.pay_type" :options="plainOptions" required />
        </a-form-item>
        <a-form-item label="物品名称"> <a-input style="width: 400px" v-model:value="formState.goodsname" type="textarea"></a-input> </a-form-item>
        <a-form-item label="备注"> <a-input style="width: 400px" v-model:value="formState.remark" type="textarea"></a-input> （此备注是自己看的，顺丰不知道）</a-form-item>
        <a-form-item label="邮寄时间" name="is_timing">
          <a-radio-group v-model:value="formState.is_timing">
            <a-radio :value="1"> 立马邮寄 </a-radio>
            <a-radio :value="2"> 定时邮寄 </a-radio> </a-radio-group
          >&nbsp;&nbsp;&nbsp;&nbsp;<a-button :loading="state.sendLoading" type="primary" @click="getMailling(1)">获取寄件人信息</a-button>&nbsp;
          <a-button :loading="state.receiveLoading" type="primary" @click="getMailling(2)">获取收件人信息</a-button>
        </a-form-item>
        <a-form-item label="上门取货时间" v-if="formState.is_timing == 2" required>
          <a-date-picker v-model:value="formState.timing_time" :format="format" show-time />
        </a-form-item>

        <div class="d-flex">
          <div class="flex-1">
            <a-form-item label="寄件人姓名" name="send_consignee_name" required>
              <a-input placeholder="请输入寄件人姓名" v-model:value="formState.send_consignee_name"></a-input>
            </a-form-item>
            <a-form-item label="寄件人电话" name="send_tel" required>
              <a-input placeholder="请输入寄件人姓名" v-model:value="formState.send_tel"></a-input>
            </a-form-item>
            <a-form-item label="寄件人省市区" name="send_paua">
              <AddressCascader width="100%" v-model:value="formState.send_paua" @change="senderPauaChange"> </AddressCascader>
            </a-form-item>
            <a-form-item label="寄件人详细地址" name="send_address" required>
              <a-input placeholder="请选择寄件人详细地址" v-model:value="formState.send_address"></a-input>
            </a-form-item>
          </div>
          <div class="flex-1">
            <a-form-item label="收件人姓名" name="receive_consignee_name" required>
              <a-input v-model:value="formState.receive_consignee_name" placeholder="请输入收件人姓名"></a-input>
            </a-form-item>
            <a-form-item label="收件人电话" name="receive_tel" required>
              <a-input v-model:value="formState.receive_tel" placeholder="请输入收件人电话"></a-input>
            </a-form-item>
            <a-form-item label="收件人省市区" name="receive_paua">
              <AddressCascader width="100%" v-model:value="formState.receive_paua" @change="recipientChange"> </AddressCascader>
            </a-form-item>
            <a-form-item label="收件人详细地址" name="receive_address" required>
              <a-input placeholder="请选择收件人详细地址" v-model:value="formState.receive_address"></a-input>
            </a-form-item>
          </div>
        </div>
      </a-form>
    </a-spin>
    <!-- 公司添加人员 -->
  </a-modal>
</template>

<script lang="ts" setup>
import baseService from '@/utils/http/axios'
import UploadTmp from '@/components/base/UploadTmp.vue'
import { reactive, onMounted, nextTick, h, ref, onBeforeUnmount, watch, computed } from 'vue'
import { message, Modal } from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'
import dayjs from 'dayjs'
import AddressCascader from '@/components/base/AddressCascader.vue'
import { mobileRequiredValidator, mobileValidator } from '@/utils/util'
import ls from '@/utils/Storage'
const UserInfo = ls.get('USER_INFO')
const emit = defineEmits(['ok', 'cancel'])
const format = 'YYYY-MM-DD HH:mm:ss'
import { useStore } from 'vuex'
const $store = useStore()
const formRef = ref()
// 防抖器
let timer: any = null
const formLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 18 },
    sm: { span: 18 }
  }
}
// 定时器
let interval
onBeforeUnmount(() => {
  clearInterval(interval)
})
//规则
const rules: Record<string, Rule[]> = {
  send_paua: [{ required: true, message: '请选择寄件人地址' }],
  receive_paua: [{ required: true, message: '请选择收件人地址' }],
  pay_type: [{ required: true, message: '请选择付款方式' }],
  is_timing: [{ required: true, message: '请选择邮寄时间' }]
}

interface state {
  loading: boolean
  maskClosable: boolean //点击蒙层是否允许关闭
  modalTitle: string
  isEdit: boolean
  confirmLoading: boolean
  sendLoading: boolean
  receiveLoading: boolean
}
const state = reactive<state>({
  loading: false,
  maskClosable: true,
  modalTitle: '新增顺丰邮寄',
  // 是否为编辑
  isEdit: false,
  confirmLoading: false,
  sendLoading: false,
  receiveLoading: false
})

interface formState {
  pay_type: number
  is_timing: number
  goodsname: string
  remark: string
  timing_time: any
  receive_address: string
  receive_paua: string
  receive_tel: string
  receive_consignee_name: string
  send_address: string
  send_paua: string
  send_tel: string
  send_consignee_name: string
}
let formState = reactive<formState>({
  pay_type: 1,
  is_timing: 1,
  goodsname: '',
  remark: '',
  timing_time: null,
  receive_address: '',
  receive_paua: '',
  receive_tel: '',
  receive_consignee_name: '',
  send_address: '',
  send_paua: '',
  send_tel: '',
  send_consignee_name: ''
})

const plainOptions = [
  { label: '寄付(顺丰速运小程序->查快递->待支付付款)', value: 1 },
  { label: '到付', value: 2 }
]
const props = defineProps({
  open: {
    type: Boolean
  },
  tableColumnsData: {
    type: Object,
    default: () => null
  }
})

onMounted(() => {
  // 初始化
  if (props.tableColumnsData?.id) {
    state.modalTitle = '编辑顺丰邮寄'
    state.loading = true
    state.isEdit = true
    queryDetail()
  } else {
    //添加顺丰邮寄
    formState.pay_type = 1
  }
})

const queryDetail = () => {
  let requestParam = {
    url: `/admin/v1/otherLogistic/${props.tableColumnsData?.id}/edit`,
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      Object.assign(formState, res.data)
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
      state.loading = false
    })
}
const senderPauaChange = (e, val) => {
  let address = `${val[0].name}${val[1].name}${val[2].name}`
  formState.send_address = address
}
const recipientChange = (e, val) => {
  let address = `${val[0].name}${val[1].name}${val[2].name}`
  formState.receive_address = address
}
// 获取邮寄信息
const getMailling = (side) => {
  var tel = ''
  if (side == 1) {
    tel = formState.send_tel
  } else if (side == 2) {
    tel = formState.receive_tel
  }
  if (!tel) {
    message.warn('请填写手机号码再获取邮寄信息')
    return
  }
  if (side == 1) {
    state.sendLoading = true
  } else if (side == 2) {
    state.receiveLoading = true
  }
  //这个接口是用的客户管理的顺丰邮寄相同的接口
  let requeryParam = {
    url: '/admin/v1/mailingAddressList',
    method: 'post',
    data: {
      page: 1,
      limit: 'all',
      customer_tel: tel
    }
  }
  baseService(requeryParam)
    .then((res: any) => {
      if (res.data.list?.length > 0) {
        if (side == 1) {
          state.sendLoading = false
          formState.send_consignee_name = res.data.list[0].name
          formState.send_tel = res.data.list[0].tel
          formState.send_address = res.data.list[0].address
          formState.send_paua = `${res.data.list[0].recipient_province_id},${res.data.list[0].recipient_city_id},${res.data.list[0].recipient_area_id}`
        } else if (side == 2) {
          formState.receive_consignee_name = res.data.list[0].name
          formState.receive_tel = res.data.list[0].tel
          formState.receive_address = res.data.list[0].address
          formState.receive_paua = `${res.data.list[0].recipient_province_id},${res.data.list[0].recipient_city_id},${res.data.list[0].recipient_area_id}`
          state.receiveLoading = false
        }
      }
      message.success(res.msg)
    })
    .catch((res) => {
      if (res) {
        if (side == 1) {
          state.sendLoading = false
        } else if (side == 2) {
          state.receiveLoading = false
        }
        if (res.msg) {
          message.warning(res.msg)
          return
        }
      }
    })
  //   }
}
// 关闭
const cancelClick = (val) => {
  if (state.isEdit) {
    emit('cancel')
    return
  }
  emit('cancel')
}
// 提交
const onFormSubmit = () => {
  formRef.value
    .validate()
    .then(() => {
      state.loading = true
      if (props.tableColumnsData?.id) {
        let requeryParam = {
          url: '/admin/v1/otherLogistic/' + props.tableColumnsData.id,
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            message.success(res.msg)
            state.confirmLoading = false
            state.loading = false
            emit('ok')
          })
          .catch((res) => {
            if (res) {
              state.confirmLoading = false
              state.loading = false
              if (res.msg) {
                message.warning(res.msg)
                return
              }
              message.warning('参数错误,请检查')
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/otherLogistic',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            message.success(res.msg)
            state.loading = false
            state.confirmLoading = false
            emit('ok')
          })
          .catch((res) => {
            state.loading = false
            if (res) {
              state.confirmLoading = false
              if (res.msg) {
                message.warning(res.msg)
                return
              }
              message.warning('参数错误,请检查')
            }
          })
      }
    })
    .catch((val) => {
      state.loading = false
      message.warning('请检查必填项')
    })
}
</script>

<style lang="less" scoped></style>
