<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <div>
    <a-button
      type="primary"
      class="mr-1x"
      @click="
        () => {
          state.selectCompanyVisible = true
        }
      "
      >筛选</a-button
    >
    <a-button
      @click="
        () => {
          state.selectCheckVisible = true
        }
      "
      >查看当前筛选列表({{ selectCheckComputed.length }})</a-button
    >
    <a-modal
      title="选择公司"
      :width="1200"
      :open="state.selectCompanyVisible"
      :footer="false"
      @cancel="
        () => {
          state.selectCompanyVisible = false
        }
      "
    >
      <a-form class="mb-2x">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="主体名称">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.nameOrCode" placeholder="请输入主体名称"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="法人姓名">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.fr_name" placeholder="请输入法人代表姓名"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="续费号码">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.renew_first_contact_mobile" placeholder="请输入续费联系人号码"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="地址编号">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.address_number" placeholder="请输入地址编号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="平台名称">
              <a-select mode="multiple" v-model:value="queryParam.platform_id" placeholder="请选择平台名称">
                <a-select-option v-for="(item, index) in state.platformList" :key="index" :value="item.id">{{ item.platform_abbreviation }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="主体类型">
              <a-select mode="multiple" v-model:value="queryParam.main_type" placeholder="请选择主体类型">
                <a-select-option :value="1">公司</a-select-option>
                <a-select-option :value="2">个体</a-select-option>
                <a-select-option :value="3">个人独资</a-select-option>
                <a-select-option :value="4">合伙企业</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="收费类型">
              <a-select mode="multiple" v-model:value="queryParam.fee_standard" placeholder="请选择收费类型">
                <a-select-option :value="1">按年收费</a-select-option>
                <a-select-option :value="2">一次性</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="付款状态">
              <a-select mode="multiple" v-model:value="queryParam.is_pay" placeholder="请选择付款状态">
                <a-select-option :value="1">已付款</a-select-option>
                <a-select-option :value="2">未付款</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="入驻类型">
              <a-select mode="multiple" v-model:value="queryParam.business_type" placeholder="请选择入驻类型">
                <a-select-option :value="1">设立</a-select-option>
                <a-select-option :value="2">同区迁入</a-select-option>
                <a-select-option :value="3">跨区迁入</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="进度状态">
              <a-select mode="multiple" v-model:value="queryParam.order_status" placeholder="请选择进度状态">
                <a-select-option v-for="(item, index) in progressOptions" :key="index" :value="item.value">{{ item.text }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="代理姓名">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.seller" placeholder="请输入代理商姓名"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="录入系统时间">
              <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24" :offset="18">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="loadData">查询</a-button>
              <a-button style="margin-left: 8px" @click="refreshTabel">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table :columns="columns" :loading="state.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex === 'operate'">
            <a class="mr-1x" @click="addTableClick('multiple')">添加当前页面全部</a>
            <a style="color: red" @click="deleteTableClick('multiple')">删除当前页面全部</a>
          </template>
        </template>
        <template #bodyCell="{ column, record, index, text }">
          <template v-if="column.dataIndex === 'companyInformation'">
            {{ record.entityNameToRegister.full_name }}
          </template>
          <template v-if="column.dataIndex === 'operate'">
            <span class="mr-1x" style="color: green" v-if="state.tableSelectKys.includes(record.id)">添加成功</span>
            <a class="mr-1x" v-else @click="addTableClick('single', record)">添加</a>
            <a style="color: red" v-if="state.tableSelectKys.includes(record.id)" @click="deleteTableClick('single', record)">删除</a>
          </template>
        </template>
      </a-table>
    </a-modal>
    <a-modal
      :open="state.selectCheckVisible"
      :footer="false"
      title="筛选列表"
      @cancel="
        () => {
          state.selectCheckVisible = false
        }
      "
    >
      <a-table :columns="selectCheckColumns" :data-source="selectCheckComputed">
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex === 'operate'">
            <a style="color: red" @click="deleteTableClick('multiple', selectCheckComputed)">删除全部公司</a>
          </template>
        </template>

        <template #bodyCell="{ column, record, index, text }">
          <template v-if="column.dataIndex === 'operate'">
            <a style="color: red" v-if="state.tableSelectKys.includes(record.id)" @click="deleteTableClick('single', record)">删除</a>
          </template>
          <template v-else>
            {{ text }}
          </template>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted, computed, watch } from 'vue'
import { message } from 'ant-design-vue'
import type { PaginationProps } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import dayjs from 'dayjs'
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const props = defineProps({
  selectCheck: {
    type: Array,
    default: []
  },
  isEdit: {
    type: Boolean,
    default: false
  }
})
const columns: Object[] = [
  {
    title: '公司名称',
    dataIndex: 'companyInformation'
  },
  {
    title: '代理商',
    dataIndex: 'seller'
  },
  {
    title: '操作',
    dataIndex: 'operate'
  }
]
const selectCheckColumns: object[] = [
  {
    title: '公司名称',
    dataIndex: 'name'
  },
  {
    title: '操作',
    dataIndex: 'operate'
  }
]
const format = 'YYYY-MM-DD HH:mm:ss'
const progressOptions = [
  { text: '未录单', value: 1 },
  { text: '暂存', value: 2 },
  { text: '材料检查中', value: 3 },
  { text: '待提交签名', value: 4 },
  { text: '待签名', value: 5 },
  { text: '签名完毕待提交', value: 6 },
  { text: '等待审核结果', value: 7 },
  { text: '材料退回修改', value: 8 },
  { text: '未通过审核', value: 9 },
  { text: '审核通过', value: 10 },
  { text: '已拿证未传照片', value: 11 },
  { text: '已上传证照照片', value: 12 },
  { text: '已办刻章手续未拿章', value: 13 },
  { text: '已拿章', value: 14 },
  { text: '已办结', value: 15 }
]
watch(
  () => props.selectCheck,
  (val) => {
    if (val?.length > 0) {
      let array: number[] = []
      val.forEach((item: any) => {
        if (item.edit_code != 'del') {
          array.push(item.id)
        }
      })
      state.tableSelectKys = array
    } else {
      state.tableSelectKys = []
    }
  },
  { deep: true }
)
let queryParam = reactive<any>({})
const emit = defineEmits(['change'])
let dataSource = ref([])
interface state {
  loading: boolean
  platformList: any[]
  selectCompanyVisible: boolean
  selectCheckVisible: boolean
  tableSelectKys: any[]
}
const state = reactive<state>({
  loading: false,
  platformList: [],
  selectCompanyVisible: false,
  selectCheckVisible: false,
  // 进来的时候获取到所有的客户id
  tableSelectKys: []
})
const pagination = reactive<PaginationProps>({
  pageSize: 40,
  pageSizeOptions: ['10', '20', '40', '50', '60'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  loadData()
  platformList()
})
const selectCheckComputed = computed(() => {
  let array: any[] = props.selectCheck.filter((item: any) => {
    return item.edit_code != 'del'
  })
  return array
})
const rangTime = computed(() => {
  let array: any = []
  if (queryParam.between_time) {
    array = queryParam.between_time.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
const rangTimeChange = (val, str) => {
  if (val) {
    queryParam.between_time = str.join(',')
  } else {
    queryParam.between_time = null
  }
}
const platformList = () => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/platformList',
    method: 'post',
    data: { limit: 'all' }
  }
  baseService(requestParam)
    .then((res) => {
      state.platformList = res.data.list
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('平台列表查询失败,请联系管理员')
      }
    })
}
const refreshTabel = () => {
  pagination.current = 1
  let obj = Object.keys(queryParam).reduce((a, key) => ({ ...a, [key]: undefined }), {})
  Object.assign(queryParam, obj)
  loadData()
}
const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  queryParam.status = filters.status
  loadData()
}

const loadData = () => {
  state.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customerList',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => {
        if (queryParam.name) {
          item.fullName = item.full_name.replace(queryParam.name, `<span style="color: red">${queryParam.name}</span>`)
        } else {
          item.fullName = item.full_name
        }
        return { ...item, isShowOptions: false }
      })
    })
    .catch((res) => {
      state.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('客户列表查询失败,请联系管理员')
      }
    })
}
const addTableClick = (length: string, record?: any) => {
  if (length == 'multiple') {
    // 创建一个新数组
    let array: any[] = [...dataSource.value]
    // 循环当前所有的数据
    dataSource.value.forEach((element: any) => {
      // 判断数据在key中是否含有
      if (state.tableSelectKys.includes(element.id)) {
        // 进入则代表含有 直接把array中的数据删除
        array = array.filter((val) => {
          return val.id != element.id
        })
      }
    })
    // 将array转成需要的格式
    array = array.map((item) => {
      return {
        edit_code: props.isEdit ? 'add' : undefined,
        id: item.id,
        name: item.full_name
      }
    })
    // array 现在则是完全的新数据 直接返回
    emit('change', [...array, ...props.selectCheck])
  } else if (length == 'single') {
    let array = [
      {
        id: record.id,
        edit_code: props.isEdit ? 'add' : undefined,
        name: record.full_name
      }
    ]
    emit('change', [...array, ...props.selectCheck])
  }
  message.success('添加成功')
}
const deleteTableClick = (length: string, record?: any) => {
  if (length == 'multiple') {
    let array: any[] = [...props.selectCheck]
    // 如果传入参数 则删除全部
    if (record) {
      if (props.isEdit) {
        array.forEach((item) => {
          item.edit_code = 'del'
        })
      } else {
        array = []
      }
    } else {
      // 创建一个新数组
      // 循环当前所有的数据
      dataSource.value.forEach((element: any) => {
        // 判断数据在key中是否含有
        if (state.tableSelectKys.includes(element.id)) {
          //     // 进入则代表含有 直接把array中的状态改变
          if (props.isEdit) {
            array.forEach((item) => {
              if (item.id == element.id) {
                item.edit_code = 'del'
              }
            })
          } else {
            array = array.filter((val) => {
              return val.id != element.id
            })
          }
        }
      })
    }
    // array 现在则是完全的新数据 直接返回
    emit('change', [...array])
  } else if (length == 'single') {
    // 删除id相同的
    let array: any[] = [...props.selectCheck]
    if (props.isEdit) {
      array.forEach((item: any) => {
        if (item.id == record.id) {
          item.edit_code = 'del'
        }
      })
    } else {
      array = array.filter((val: any) => {
        return val.id != record.id
      })
    }
    emit('change', [...array])
  }
}
</script>

<style lang="less" scoped></style>
