<template>
  <a-modal :title="`平台充值(${props.tableColumnsData.full_name})`" :width="1000" :open="true" @cancel="cancel">
    <template #footer>
      <a-button @click="cancel">取消</a-button>
      <a-popconfirm title="是否确定？" @confirm="onFormSubmit">
        <a-button type="primary" :loading="state.loading2">确定</a-button>
      </a-popconfirm>
    </template>
    <a-skeleton :loading="state.loading" active :paragraph="{ rows: 10 }">
      <a-form ref="ruleForm" :model="formData" v-bind="formLayout">
        <a-form-item label="平台公司名称" class="mt-3x"> {{ state.platformInfo.name }} </a-form-item>
        <a-form-item label="平台地址">
          {{ state.platformInfo.company_detailed_address }}
        </a-form-item>
        <a-form-item label="平台价格">
          <span class="red">
            <a-popover trigger="click">
              <template #content>
                <div style="width: auto" v-html="state.platformInfo.platform_price_list"></div>
              </template>
              <a>查看平台当前产品价格（价格经常变动，敬请留意！）</a>
            </a-popover></span
          >
        </a-form-item>

        <a-form-item label="待充值账号">
          <span class="red"> {{ $store.state.user.userInfo?.username }} </span>
        </a-form-item>
        <a-form-item label="待充值账号身份证姓名">
          <span class="red"> {{ $store.state.user.userInfo?.idcard_name }} </span>
        </a-form-item>
        <a-form-item label="手机号码">
          <span class="red"> {{ $store.state.user.userInfo?.phone }} </span>
        </a-form-item>
        <a-form-item label="充值金额￥" name="money" required>
          <a-input v-model:value="formData.money" placeholder="请输入充值金额" addonAfter="元"></a-input>
        </a-form-item>
        <a-form-item label="附件" name="attachment">
          <Upload
            :upload_path="props.tableColumnsData.upload_path"
            :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
            uploadType="text"
            v-model:value="formData.attachment"
            :limitation="99"
            uploadText="上传附件"
          >
          </Upload>
        </a-form-item>
        <a-form-item label="备注">
          <a-input v-model:value="formData.submit_reason" placeholder="请输入备注"></a-input>
        </a-form-item>
      </a-form>
    </a-skeleton>
  </a-modal>
</template>

<script lang="ts" setup>
import { message, Modal } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { reactive, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import Upload from '@/components/base/Upload.vue'
const $store = useStore()
const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  }
}
const ruleForm = ref()
const emit = defineEmits(['cancel', 'ok'])
const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: () => {}
  }
})

const state = reactive<any>({
  loading: false,
  loading2: false,
  platformInfo: {}
})
const formData = reactive<any>({
  money: null,
  attachment: [],
  submit_reason: ''
})
onMounted(() => {
  queryDetail()
})
const showplatformprice = () => {}
const queryDetail = () => {
  let requeryParam = {
    url: '/admin/v1/manualRecharge/create?platform_id=' + props.tableColumnsData.platform_id,
    method: 'get'
  }
  baseService(requeryParam)
    .then((res) => {
      state.platformInfo = res.data.platform
    })
    .catch((err) => {
      if (err.msg) {
        message.error(err.msg)
      } else {
        message.error('获取失败')
      }
    })
}
// 提交
const onFormSubmit = () => {
  state.loading2 = true
  ruleForm.value
    .validate()
    .then((res) => {
      let requeryParam = {
        url: '/admin/v1/manualRecharge',
        method: 'post',
        data: { ...formData, platform_id: props.tableColumnsData.platform_id }
      }
      baseService(requeryParam)
        .then((res: any) => {
          state.loading2 = false
          message.success(res.msg)
          emit('ok')
        })
        .catch((res) => {
          state.loading2 = false
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('操作失败')
          }
        })
    })
    .catch((res) => {
      state.loading2 = false
      message.warn('请检查必填项')
    })
}
const cancel = () => {
  emit('cancel')
}
</script>

<style lang="less" scoped>
:deep(.ant-modal-title) {
  margin-bottom: 25px !important;
}
.label-form-item {
  display: flex;
  align-items: center;

  .delete-icon {
    color: red;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.select-color {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .label-color-picker {
    border: 1px solid #dcdfe6;
    padding: 4px;
    border-radius: 4px;
  }
}
</style>
