<template>
  <div>
    <a-upload
      :before-upload="beforeUpload"
      :accept="state.acceptStr"
      :multiple="multiple"
      v-if="uploadType === 'text'"
      :file-list="state.fileListData"
      @change="fileChange"
      :headers="headers"
      :data="uploadData"
      :action="action"
      :disabled="disabled"
    >
      <a-button :disabled="disabled ? disabled : limitation === 99 ? false : state.fileListData.length >= limitation"> <UploadOutlined /> {{ uploadText }} </a-button>
      <div style="color: #999; margin-top: 4px">上传文件大小不超过{{ props.size }}MB</div>
    </a-upload>
    <a-upload
      :headers="headers"
      class="upload-class"
      :multiple="multiple"
      v-if="uploadType === 'picture-card'"
      :data="uploadData"
      list-type="picture-card"
      :file-list="state.fileListData"
      :accept="state.acceptStr"
      @change="handleListChange"
      :before-upload="beforeUpload"
      :action="action"
      name="file"
      :disabled="disabled"
    >
      <div v-if="(limitation === 99 ? true : state.fileListData.length < limitation) && !disabled">
        <PlusOutlined />
        <div class="ant-upload-text">{{ uploadText }}</div>
      </div>
      <template #itemRender="{ file, actions }">
        <a-image
          v-if="file.url"
          :preview="{
            visible: false
          }"
          :width="100"
          :height="100"
          :src="$store.state.user.image_domain + file.url"
        >
          <template #previewMask>
            <div>
              <EyeOutlined @click="setVisible(true, $store.state.user.image_domain + file.url)" class="mr-1x" style="font-size: 16px" />
              <DeleteOutlined v-if="!disabled" @click="actions.remove" style="font-size: 16px" />
            </div>
          </template>
        </a-image>
        <div v-else style="width: 100px; height: 100px; display: flex; justify-content: center; align-items: center">
          <LoadingOutlined />
        </div>
      </template>
    </a-upload>
    <a-image
      :width="200"
      :style="{ display: 'none' }"
      :preview="{
        visible: state.previewVisible,
        onVisibleChange: setVisible
      }"
      :src="state.previewImage"
    />
  </div>
</template>

<script lang="ts" setup>
import { PlusOutlined, UploadOutlined, EyeOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { reactive, watch, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import ls from '@/utils/Storage'
import { useStore } from 'vuex'
const $store = useStore()
const emit = defineEmits(['update:value', 'uploading', 'change'])
const props = defineProps({
  /**
   * @component Upload
   * @description 上传组件
   * @props
   *   - value: {Array} 上传的文件列表，默认为空数组
   *   - uploadType: {String} 上传类型，默认为文本类型
   *   - limitation: {Number} 上传文件数量限制，默认为1
   *   - uploadText: {String} 上传按钮文本
   *   - multiple: {Boolean} 是否支持多文件上传，默认为true
   *   - disabled: {Boolean} 是否禁用上传功能，默认为false
   *   - accept: {Array} 允许上传的文件类型，默认为['jpeg', 'png', 'jpg']
   *   - imgLimitSize：{Number} 图片压缩大小，默认为1，大于1M就压缩
   */
  value: {
    type: [Array, String],
    default: () => []
  },
  uploadType: {
    type: String,
    default: 'text'
  },
  limitation: {
    type: Number,
    default: 1
  },
  uploadText: {
    type: String
  },
  multiple: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  accept: {
    type: Array,
    default: () => []
  },
  customerName: {
    type: String || undefined,
    default: undefined
  },
  upload_path: {
    type: String
  },
  size: {
    type: Number,
    default: 10
  },
  full_name_whole_check: {
    type: Number,
    default: 0
  },
  full_name_whole_check_data: {
    type: Object,
    default: null
  },
  imgLimitSize: {
    type: Number,
    default: 1
  }
})
interface state {
  previewImage: string
  previewVisible: boolean
  fileListData: any[]
  acceptStr: string
}
const state = reactive<state>({
  previewImage: '',
  previewVisible: false,
  fileListData: [],
  acceptStr: ''
})
let action = '/hncskaiyela/admin/v1/upload'
const headers = {
  Authorization: ls.get('ACCESS_TOKEN')
}
watch(
  () => props.accept,
  (val) => {
    if (val && val.length > 0) {
      state.acceptStr = val.map((item) => `.${item}`).join(',')
    }
  },
  { immediate: true, deep: true }
)
const watchValue = (val) => {
  if (typeof val == 'string') {
    state.fileListData = [{ url: val }]
  } else {
    if (props.uploadType === 'text') {
      const list: any = val.map((item: any, index) => ({
        furl: item.url,
        url: $store.state.user.image_domain + item.url,
        uid: index,
        name: props.uploadType === 'text' ? item.name : undefined
      }))
      state.fileListData = [...list]
    } else {
      const list: any = val.map((item: any, index) => ({
        url: item,
        uid: index,
        name: props.uploadType === 'text' ? item.name : undefined
      }))
      state.fileListData = [...list]
    }
  }
}
watch(
  () => props.value,
  (val: any[] | string) => {
    if (!val || val.length == 0) {
      state.fileListData = []
      return
    }
    // 判断传入和传出是否相等
    if (val.length != state.fileListData.length) {
      watchValue(val)
    } else {
      let isEqual = true
      state.fileListData.forEach((item, index) => {
        if (props.uploadType === 'text') {
          if (item.furl != val[index]) {
            isEqual = false
          }
        } else {
          if (item.url != val[index]) {
            isEqual = false
          }
        }
      })
      if (!isEqual) {
        watchValue(val)
      }
    }
  },
  { deep: true, immediate: true }
)

onMounted(() => {})
const uploadData = (val) => {
  let data: any = {}
  if (typeof props.upload_path == 'string') {
    data.upload_path = btoa(encodeURIComponent(props.upload_path))
  }
  if (props.customerName) {
    data.customer_name = props.customerName
  }
  data.imgLimitSize = props.imgLimitSize
  return data
}
const setVisible = (value, url): void => {
  if (value) {
    state.previewImage = url
    state.previewVisible = true
  } else {
    state.previewVisible = false
  }
}
const fileChange = ({ file, fileList }) => {
  if (!file.status) {
    let i = 0
    fileList.forEach((res, index) => {
      if (file.name == res.name) {
        i = index
      }
    })
    fileList.splice(i, 1)
    state.fileListData = fileList
    return
  }
  state.fileListData = fileList
  if (file.status === 'uploading') {
    emit('uploading', true)
  } else if (file.status === 'removed') {
    formChange()
  } else if (file.status === 'done') {
    fileList.forEach((item: any, index: number) => {
      if (item.uid === file.uid) {
        state.fileListData[index] = {
          furl: file.response.data.url,
          url: $store.state.user.image_domain + file.response.data.url,
          status: file.status,
          uid: file.uid,
          name: file.name
        }
      }
    })
    // 如果fileList中有一项存在 等待
    if (fileList.some((item) => item.status == 'uploading')) {
      return
    } else {
      emit('uploading', false)
      formChange()
    }
  }
}
const handleListChange = ({ file, fileList, error }) => {
  if (!file.status) {
    return
  }
  state.fileListData = fileList
  if (file.status === 'uploading') {
    return
  }
  if (file.status === 'removed') {
    formChange()
  }
  if (file.status === 'done') {
    fileList.forEach((item, index) => {
      if (item.uid === file.uid) {
        state.fileListData[index] = {
          url: file.response.data.url,
          status: file.status,
          uid: file.uid
        }
      }
    })
    // 如果fileList中有一项存在 等待
    if (fileList.some((item) => item.status == 'uploading')) {
      return
    } else {
      formChange()
    }
  }
  if (error) {
    message.error('图片上传失败')
    return
  }
}
const beforeUpload = (file) => {
  if (props.full_name_whole_check) {
    const value = props.full_name_whole_check_data
    if (!value.filiale_full_name || !value.organizational_form || !value.name_word_mark) {
      message.warn('请填写完整的分公司主体名称,才能上传总公司营业执照')
      return false
    }
  }
  const fileType = file.name.split('.').slice(-1)[0]
  const isJpgOrPng = props.accept.includes(fileType)
  if (!isJpgOrPng) {
    message.error(`仅支持文件格式：${state.acceptStr}格式附件!`)
  }
  const isLt1M = file.size / 1024 / 1024 < props.size
  if (!isLt1M) {
    message.error(`文件必须小于${props.size}MB`)
  }
  return isJpgOrPng && isLt1M
}
const formChange = () => {
  let list: any = []
  if (props.uploadType === 'text') {
    list = state.fileListData.map((item) => {
      if (item.furl) {
        return {
          url: item.furl,
          name: item.name
        }
      } else {
        return item
      }
    })
  } else {
    list = state.fileListData.map((item) => item.url)
    if (props.limitation == 1) {
      emit('update:value', list[0])
      return
    }
  }
  emit('update:value', list)
}
</script>

<style lang="less" scoped>
.upload-class {
  min-height: 112px;
}
</style>
