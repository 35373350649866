<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ImageCropper',
})
</script>
<template>
  <a-modal
    title="图片裁剪"
    :width="900"
    :open="true"
    :keyboard="false"
    :maskClosable="false"
    :confirmLoading="state.loading"
    @ok="uploadImg"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-row>
        <a-col :xs="24" :md="12" :style="{ height: '350px' }">
          <vue-cropper fillColor="#fff" ref="cropperRef" :img="imageUrl" :autoCrop="true" :autoCropWidth="windowsSize[0]" :autoCropHeight="windowsSize[1]" @realTime="realTime" />
        </a-col>
        <a-col :xs="24" :md="12" style="height: 350px; display: flex; justify-content: center; align-items: center">
          <div
            :style="{
              width: state.previews.div.width,
              height: state.previews.div.height,
              overflow: 'hidden'
            }"
          >
            <img :src="state.previews.url" :style="state.previews.img" />
          </div>
        </a-col>
      </a-row>
      <br />
      <a-row>
        <a-col :lg="{ span: 1, offset: 2 }" :md="2">
          <a-button :icon="h(PlusOutlined)" size="small" @click="changeScale(1)"></a-button>
        </a-col>
        <a-col :lg="{ span: 1, offset: 1 }" :md="2">
          <a-button :icon="h(MinusOutlined)" size="small" @click="changeScale(-1)"></a-button>
        </a-col>
        <a-col :lg="{ span: 1, offset: 1 }" :md="2">
          <a-button :icon="h(UndoOutlined)" size="small" @click="rotateLeft()"></a-button>
        </a-col>
        <a-col :lg="{ span: 1, offset: 1 }" :md="2">
          <a-button :icon="h(RedoOutlined)" size="small" @click="rotateRight()"></a-button>
        </a-col>
        <a-col v-if="isId"> 是否需要识别身份证 <a-switch v-model:checked="state.isRecognize"></a-switch> </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script setup name="ImageCropper" lang="ts">
import { VueCropper } from 'vue-cropper'
import 'vue-cropper/dist/index.css'
import cloneDeep from 'lodash.clonedeep'
import baseService from '@/utils/http/axios'
import { PlusOutlined, MinusOutlined, UndoOutlined, RedoOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { reactive, ref, onMounted, h } from 'vue'
const props = defineProps({
  imageUrl: {
    type: String,
    default: ''
  },
  imageName: {
    type: String,
    default: ''
  },
  isId: {
    type: Boolean,
    default: true
  },
  isTiqianClose: {
    //是否提前关闭，在父页面post
    type: Boolean,
    default: false
  },
  windowsSize: {
    type: Array,
    default: [426, 269]
  },
  uploadPathObj: {
    type: Object
  },
  upload_path: {
    type: String
  },
  customerName: {
    type: String
  }
})

const state = reactive<any>({
  isRecognize: true,
  loading: false,
  previews: {
    div: {
      width: '130px',
      height: '180px'
    }
  }
})
onMounted(() => {
  state.isRecognize = cloneDeep(props.isId)
})
const emit = defineEmits(['change', 'cancel'])
const realTime = (data) => {
  state.previews = data
}
const cropperRef = ref()
// 向左旋转
const rotateLeft = () => {
  cropperRef.value.rotateLeft()
}
// 向右旋转
const rotateRight = () => {
  cropperRef.value.rotateRight()
}
// 图片缩放
const changeScale = (num) => {
  num = num || 1
  cropperRef.value.changeScale(num)
}
// 上传图片
const uploadImg = () => {
  cropperRef.value.getCropBlob((data) => {
    state.loading = true
    let formData: any = new FormData()
    // Blob转File
    formData.append('file', new File([data], props.imageName, { type: 'image/jpeg' }))
    formData.append('customer_name', props.customerName)
    if (props.uploadPathObj) {
      let upload_path = btoa(
        encodeURIComponent(
          `public/uploads/${props.uploadPathObj.platformName}/${props.uploadPathObj.platformCompanyName}/客户资料/${props.uploadPathObj.mainTypeName}/${props.uploadPathObj.date}/${props.uploadPathObj.companyName}/`
        )
      )
      formData.append('upload_path', upload_path)
    }
    if (props.upload_path) {
      let upload_path = btoa(encodeURIComponent(props.upload_path))
      formData.append('upload_path', upload_path)
    }
    //如果需要提前关闭本窗口,将需要提交的内容发过去
    if (props.isTiqianClose) {
      state.loading = false
      emit('change', formData, state.isRecognize)
    } else {
      if (state.isRecognize) {
        let requestParam = {
          url: '/admin/v1/ocr',
          method: 'post',
          data: formData
        }
        baseService(requestParam)
          .then((res) => {
            state.loading = false
            emit('change', res, state.isRecognize)
          })
          .catch((res) => {
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
            state.loading = false
          })
      } else {
        let requestParam = {
          url: '/admin/v1/upload',
          method: 'post',
          data: formData
        }
        baseService(requestParam)
          .then((res) => {
            state.loading = false
            if (props.isId) {
              emit('change', res.data, state.isRecognize)
            } else {
              emit('change', res.data)
            }
          })
          .catch((res) => {
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
            state.loading = false
          })
      }
    }
  })
}
</script>
